// import IconError from '../assets/images/png/icon_error.png';

export const error400 = {
  // errorIcon: { IconError },
  errorTitle: '400: Pardon?',
  errorMessage: 'We didn\'t understand your request. It was just bad overall. Try again.'
};

export const error403 = {
  // errorIcon: { IconError },
  errorTitle: '403: Stay out',
  errorMessage: 'You don\'t have permission to view that page.'
};

export const error404 = {
  // errorIcon: { IconError },
  errorTitle: '404: Page not found',
  errorMessage: 'That page went for a walk and never returned. Turn back now while you still can.'
};

export const error500 = {
  // errorIcon: { IconError },
  errorTitle: '500: Something went wrong',
  errorMessage: 'We\'re working hard to fix the problem, so try again later.'
};

export const error502 = {
  // errorIcon: { IconError },
  errorTitle: 'Error 502',
  errorMessage: 'Sorry there is a server issue. Please come back later.'
};

export const error503 = {
  // errorIcon: { IconError },
  errorTitle: '503: We\'re being overrun',
  errorMessage: 'It seems we\'re too popular for our own good. Try checking in later.'
};
