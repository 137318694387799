import { CancelToken } from 'axios';
import cookie from 'js-cookie';

import config from '../../constants/serverConfig';
import api from '../../utils/api';
import isEmpty from '../../utils/isEmpty';

// import { simpleCrypto } from '../../../utils/cryptoHelper';
// import { removeAllStorage } from '../../utils/clearStorage';
// import { USER_PROFILE } from '../../../constants/storage';

import { ROUTE_CHANGE_PASS_SUCCESS } from '../../constants/routes';


import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';
import { 
  GET_EMPLOYEE_LIST, 
  GET_MORE_EMPLOYEE_LIST, 
  EDIT_EMPLOYEE, 
  PATCH_EMPLOYEE, 
  UPLOAD_EMPLOYEE_LIST, 
  ADD_EMPLOYEE, 
  DELETE_EMPLOYEE, 
  UPDATE_EMPLOYEE, 
  GET_TEAM_LIST, 
  GET_HR_GA,
  CHANGE_PASSWORD,
  CANCEL_FETCH_EMPLOYEE_LIST
} from './index';

let source = CancelToken.source();

export const actionFetchEmployeeList = (rows = 20, page = 1, search = '') => async dispatch => {
  try {

    dispatch({ type: GET_EMPLOYEE_LIST + PENDING });
    source = CancelToken.source();
    console.log('config', config)
    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/employees?rows=${rows}&page=${page}&search=${encodeURIComponent(search)}`,
      cancelToken: source.token
    });
    console.log(data.message);
    dispatch({ type: GET_EMPLOYEE_LIST + SUCCESS, payload: { 
      data: data.data.employeeDetails, 
      totalCount: data.data._metadata.totalCount,
      totalPage: data.data._metadata.totalPage
    } });

  } catch (e) {
    console.log(e)
    dispatch({ type: GET_EMPLOYEE_LIST + ERROR, e });
  }
};

export const actionFetchMoreEmployeeList = (rows = 20, page, search = '') => async dispatch => {
  try {

    dispatch({ type: GET_MORE_EMPLOYEE_LIST + PENDING });
    source = CancelToken.source();
    console.log('config', config)
    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/employees?rows=${rows}&page=${page}&search=${encodeURIComponent(search)}`,
      cancelToken: source.token
    });
    console.log(data.data.employeeDetails);
    dispatch({ type: GET_MORE_EMPLOYEE_LIST + SUCCESS, payload: { data: data.data.employeeDetails } });

  } catch (e) {
    dispatch({ type: GET_MORE_EMPLOYEE_LIST + ERROR, e });
  }
};

export const actionUpdateEmployee = (form, knoxId) => async (dispatch, getState) => {
  
  try {
    dispatch({ type: UPDATE_EMPLOYEE + PENDING });
    await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/update-account`,
      cancelToken: source.token,
      data: {
        "team": form.team,
        "firstName": form.firstName,
        "lastName": form.lastName,
        "knoxId": knoxId,
        "newKnoxId": form.knoxId,
        "is_active": form.status.toString(),
        "type" : form.type,
      }
    });

    dispatch({
      type: UPDATE_EMPLOYEE + SUCCESS,
      payload: { data : form }
    });

    return true;

  } catch (e) {
    dispatch({ type: UPDATE_EMPLOYEE + ERROR, payload: e.response.data.message });

    return e;
  }
};

export const actionUploadEmployeeList = EmployeeList => async dispatch => {

  try {
    
    dispatch({ type: UPLOAD_EMPLOYEE_LIST + PENDING });
    const { data } = await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/employees/upload`,
      cancelToken: source.token,
      data: `${EmployeeList}`
    });
    if (data.code === 20000) {
      dispatch({ type: UPLOAD_EMPLOYEE_LIST + SUCCESS, payload: data.message });
      dispatch(actionFetchEmployeeList());
    } else if (data.code === 20001) {
      dispatch({
        type: UPLOAD_EMPLOYEE_LIST + ERROR,
        payload: data.data
      });

      return false;
    }
    return true;
  } catch (e) {
    let employeeErrors = [];

    if(!isEmpty(e.response.data) && !isEmpty(e.response.data.data)){
      employeeErrors = e.response.data.data;
    }

    dispatch({ type: UPLOAD_EMPLOYEE_LIST + ERROR, payload: employeeErrors });
    return false;
  }
}

export const actionAddEmployee = form => async dispatch => {
  try {
    console.log(form)
    dispatch({ type: ADD_EMPLOYEE + PENDING });
    const { data } = await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/create-account`,
      cancelToken: source.token,
      // data: {}
      data: {
        "firstName": form.firstName,
        "lastName": form.lastName,
        "knoxId": form.knoxId,
        "employeeId": form.employeeId,
        "type": form.type,
        "team": form.team
      }
      
    });
    
    dispatch({ type: ADD_EMPLOYEE + SUCCESS, payload: { data : {
      employeeId: form.employeeId,
      firstName: form.firstName,
      Id: data.data.Id,
      knoxId: form.knoxId,
      lastName: form.lastName,
      createdTimeStamp: Math.floor(new window.Date().getTime() / 1000),
      isActive: true,
      modifiedTimeStamp: Math.floor(new window.Date().getTime() / 1000),
      type: form.type,
      teamName: form.team
      // message: data.message,
      
    }}});
    
  } catch (e) {
    dispatch({ type: ADD_EMPLOYEE + ERROR , payload: e.response.data.message});
    throw new Error(e.response.data.message);
  }

}

export const actionDeleteEmployee = id => async dispatch => {
  try {
    dispatch({ type: DELETE_EMPLOYEE + PENDING });
    source = CancelToken.source();

    const { data } = await api({
      method: 'DELETE',
      url: `${config.SERVER_BASE_URL}/employees`,
      data: {
        ids : [id]
      },
      cancelToken: source.token
    });

    dispatch({ type: DELETE_EMPLOYEE + SUCCESS, payload: { data: id } });
    
  }catch (e) {
    dispatch({ type: DELETE_EMPLOYEE + ERROR, e });
  }
}

export const actionFetchTeamList = () => async dispatch => {
  try{
    dispatch({ type: GET_TEAM_LIST + PENDING });
    source = CancelToken.source();
    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/teams`,
      cancelToken: source.token
    });

    dispatch({ type: GET_TEAM_LIST + SUCCESS, payload: data });

  }catch (e) {
    dispatch({ type: GET_TEAM_LIST + ERROR, e });
  }
}


export const actionFetchHRGA = ({ type }) => async dispatch => {
  try {
    dispatch({ type: GET_HR_GA + PENDING });
    source = CancelToken.source();

    const { data: { data } } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/employees/hr-ga?type=${type}`,
      cancelToken: source.token
    })

    dispatch({ type: GET_HR_GA + SUCCESS, payload: data})

  } catch (e) {
    dispatch({ type: GET_HR_GA + ERROR })
  }
}


export const actionChangePassword = form => async dispatch => {
  try {
    console.log('Inside actionChangePassword function')
    dispatch({ type: CHANGE_PASSWORD + PENDING })

    const { data } = await api({
      method: 'PUT',
      url: `${config.SERVER_BASE_URL}/change-password`,
      data: {
        "knox_id": form.knox_id,
        "old_password": form.old_password.trim(),
        "new_password": form.new_password.trim(),
      }
    });
    dispatch({ type: CHANGE_PASSWORD + SUCCESS, payload: data });
    dispatch({ type: ROUTE_CHANGE_PASS_SUCCESS })

  } catch (e) {
    dispatch({ type: CHANGE_PASSWORD + ERROR, payload: e.response.data.message  })
  }
}

export const actionCancelFetchEmployeeList = () => dispatch => {
  source.cancel();
  source = CancelToken.source();

  dispatch({
    type: CANCEL_FETCH_EMPLOYEE_LIST
  });
};