import {
  OPEN_DIALOG, CLOSE_DIALOG,
  RESET_DIALOG_PROPS,
  SET_REGISTER_EMAIL_CHECK
} from './index';

export const actionOpenDialog = props => dispatch => dispatch({
  type: OPEN_DIALOG,
  payload: { props }
});

export const actionCloseDialog = () => dispatch => {
  dispatch({
    type: CLOSE_DIALOG
  });

  window.setTimeout(() => {
    dispatch({ type: RESET_DIALOG_PROPS });
  }, 150);
};

export const actionHandleRegisterEmailCheckbox = value => dispatch => {
  dispatch({
    type: SET_REGISTER_EMAIL_CHECK,
    payload: { value }
  });
};
