// import cookie from 'js-cookie';

import { decodeStorage } from '../../../utils/cryptoHelper';
import { USER_PROFILE } from '../../../constants/storage';
import { PENDING, SUCCESS, ERROR } from '../../../constants/statusTypes';
import { TOGGLE_USER_DRAWER, UPDATE_USER } from './index';
import { LOGIN } from '../../appEntry'

// reducer
const initialState = {
  isDrawerVisible: false,
  profile: decodeStorage(USER_PROFILE),
  isUpdateProfilePending: false,
  isUpdateProfileError: false,
};

const user = (state = initialState, action = {}) => {
  switch (action.type) {
  case LOGIN + SUCCESS:
    return { ...state, profile: action.payload };
  case TOGGLE_USER_DRAWER:
    return { ...state, isDrawerVisible: action.payload.props };
  case UPDATE_USER + PENDING:
    return {
      ...state,
      isUpdateProfilePending: true,
      isUpdateProfileError: false,
    }
  case UPDATE_USER + ERROR:
    return {
      ...state,
      isUpdateProfilePending: false,
      isUpdateProfileError: true,
    }
  case UPDATE_USER + SUCCESS:
    return {
      ...state,
      isUpdateProfilePending: false,
      isUpdateProfileError: false,
      profile: {
        ...state.profile,
        ...action.payload.data
      }
    }
  default:
    return state;
  }
};

export default user;
