export const ROUTE_LOGIN = '@route/LOGIN';
export const ROUTE_SIGNUP = '@route/SIGNUP';
export const ROUTE_FORGOT_PASSWORD = '@route/FORGOT_PASSWORD';
export const ROUTE_RESET_PASSWORD = '@route/RESET_PASSWORD';
export const ROUTE_CHANGE_PASS_SUCCESS = '@route/CHANGE_PASS_SUCCESS';
export const ROUTE_HOME = '@route/HOME';
export const ROUTE_EVENTS = '@route/EVENTS';
export const ROUTE_ADD_EVENTS = '@route/ADD_EVENTS';
export const ROUTE_UPDATE_EVENTS = '@route/UPDATE_EVENTS';
export const ROUTE_PROFILE = '@route/PROFILE';
export const ROUTE_USERS = '@route/USERS';
export const ROUTE_DOCUMENTS = '@route/DOCUMENTS';
export const ROUTE_SUGGESTIONS = '@route/SUGGESTIONS';
export const ROUTE_BCP = '@route/BCP';
export const ROUTE_LEADERBOARD = '@route/LEADERBOARD';
export const ROUTE_REQUEST = '@route/REQUEST';
export const ROUTE_FEEDBACKS = '@route/FEEDBACKS';
export const ROUTE_CONFIRM_EMAIL = '@route/CONFIRM_EMAIL'
export const ROUTE_CHANGE_PASSWORD = '@route/CHANGE_PASSWORD'
export const ROUTE_VOE = '@route/VOE'


export const ROUTE_ERROR_400 = '@route/ERROR_400';
export const ROUTE_ERROR_403 = '@route/ERROR_403';
export const ROUTE_ERROR_404 = '@route/ERROR_404';
export const ROUTE_ERROR_500 = '@route/ERROR_500';
export const ROUTE_ERROR_502 = '@route/ERROR_502';
export const ROUTE_ERROR_503 = '@route/ERROR_503';
