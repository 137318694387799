import { CancelToken } from 'axios';
import moment from 'moment';
import config from '../../constants/serverConfig';
import api from '../../utils/api';
import { saveAs } from 'file-saver'; 
import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

import {
  FETCH_DOCUMENT_REQUESTS,
  UPDATE_DOCUMENT_STATUS,
  SET_REQUEST_ASSIGNEE,
  FETCH_CANCELLED_REQUESTS,
  SET_FILTER_START_DATE,
  SET_FILTER_END_DATE,
  RESET_FILTERS,
  SET_FILTER_REF_NUM,
  EXPORT_DOCUMENT_REQUEST,
  CANCEL_FETCH_REQUEST
} from './index';
import isEmpty from '../../utils/isEmpty';
import { initialState } from './requests';

let source = CancelToken.source();


export const actionFetchDocumentRequests = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_DOCUMENT_REQUESTS + PENDING
    })
    
    let { appRequest: { filters: { startDate, endDate, refNum } }} = getState();

    const previousMonth = moment(startDate).format('MM');
    const previousYear = moment(startDate).format('YYYY');
    const currentMonth = moment(endDate).format('MM');
    const currentYear = moment(endDate).format('YYYY');

    source = CancelToken.source();
    refNum = encodeURIComponent(refNum);
    
    const params = `?previousMonth=${previousMonth}&previousYear=${previousYear}&currentMonth=${currentMonth}&currentYear=${currentYear}&referenceNumber=${refNum}`;
    
    const { data: { data } } = await api ({
      method: 'GET'    ,
      url: `${config.SERVER_BASE_URL}/documents${params}`,
      cancelToken: source.token
    })
    
    dispatch({
      type: FETCH_DOCUMENT_REQUESTS + SUCCESS,
      payload: {
        data: data !== null ? data : []
      }
    })
  } catch (e) {
    console.log('e:', e)
    dispatch({
      type: FETCH_DOCUMENT_REQUESTS + ERROR
    })
  }

}


export const actionUpdateStatus = ({ id, status, reason }) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_DOCUMENT_STATUS + PENDING
    })

    api ({
      method: 'PUT'    ,
      url: `${config.SERVER_BASE_URL}/documents/update-status/${id}`,
      data: {
        status: status,
        reason
      }
    })
    
    dispatch({
      type: UPDATE_DOCUMENT_STATUS + SUCCESS,
      payload: {
        id,
        status,
        reason
      }
    })

  } catch (e) {
    dispatch({
      type: UPDATE_DOCUMENT_STATUS + ERROR
    })
  }

}

export const actionAssignRequest = ({ method, docId, userId, userName }) => async dispatch => {
  try {
    dispatch({ type: SET_REQUEST_ASSIGNEE + PENDING });
    
    await api({
      method: 'PUT',
      url: `${config.SERVER_BASE_URL}/documents/assign/${docId}?assignMethod=${method}${method === 2 ? `&assignTo=${userId}` : ''}`,
    })

    dispatch({ type: SET_REQUEST_ASSIGNEE + SUCCESS, payload: { docId, userName, userId } });

  } catch (e) {
    dispatch({ type: SET_REQUEST_ASSIGNEE + ERROR})
  }
}

export const actionFetchCancelledRequests = (page = 1 ) => async (dispatch, getState) => {
  try {
    dispatch({ type: FETCH_CANCELLED_REQUESTS + PENDING });
    const state = getState();
    const { appRequest } = state;
    const { filters: { startDate, endDate }} = appRequest;
    const { pagination: { rows }} = appRequest;

    const fromMonth = moment(startDate).format('MM');
    const fromYear = moment(startDate).format('YYYY');
    const toMonth = moment(endDate).format('MM');
    const toYear = moment(endDate).format('YYYY');

    let { data: { data } } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/documents/cancelled?fromMonth=${fromMonth}&fromYear=${fromYear}&toMonth=${toMonth}&toYear=${toYear}&page=${page}&rows=${rows}`
    })

    let { documents = [] } = data;
    if (documents === null) {
      return dispatch ({
        type: FETCH_CANCELLED_REQUESTS + SUCCESS, 
        payload: {
          pagination: {...initialState.pagination },
          data: []
        }      
      });
    };
    
    if (page !== 1) {
      documents = [...appRequest.cancelledRequests, ...documents]
    }

    const pagination = { ...data._metadata, page: page };
    
    return dispatch({ 
      type: FETCH_CANCELLED_REQUESTS + SUCCESS, 
      payload: {
        pagination,
        data: documents
        
      }
    });

  } catch (e) {
    dispatch({ type: FETCH_CANCELLED_REQUESTS + ERROR })
  }
}

export const actionSetFilterStartDate = date => ({
  type: SET_FILTER_START_DATE,
  payload: date
})

export const actionSetFilterEndDate = date => ({
  type: SET_FILTER_END_DATE,
  payload: date
})

export const actionResetFilters = () => ({
  type: RESET_FILTERS
})

export const actionSetFilterRefNum = keyword => ({
  type: SET_FILTER_REF_NUM,
  payload: keyword
})

export const actionExportDocumentRequest = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EXPORT_DOCUMENT_REQUEST + PENDING });
   
    const { appRequest: { filters: { startDate, endDate, refNum } } } = getState();

    const previousMonth = moment(startDate).format('MM');
    const previousYear = moment(startDate).format('YYYY');
    const currentMonth = moment(endDate).format('MM');
    const currentYear = moment(endDate).format('YYYY');

    const { data: { data }} = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/documents/export?previousMonth=${previousMonth}&previousYear=${previousYear}&currentMonth=${currentMonth}&currentYear=${currentYear}&referenceNumber=${refNum}`,
    });

    const csvFileDocReq = data;

    if (!isEmpty(csvFileDocReq)) {
      saveAs(csvFileDocReq, csvFileDocReq);
    } else {
      dispatch({ type: EXPORT_DOCUMENT_REQUEST + ERROR });
    }

  } catch (e) {
    dispatch({ type: EXPORT_DOCUMENT_REQUEST + ERROR, e });
  }
};

export const actionCancelDocumentRequests = () => dispatch => {
  source.cancel();
  source = CancelToken.source();

  dispatch({
    type: CANCEL_FETCH_REQUEST
  });
};
