import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import Link from 'redux-first-router-link'
import CardForm from '../components/CardForm';
import * as Yup from 'yup';

import { Formik, Form, Field } from 'formik';

import Input from '../components/Input';
import Text from '../components/Text';
import FillButton from '../components/FillButton';
import FlatButton from '../components/FlatButton';
import SvgIcon from '../components/SvgIcon';

import emailIcon from '../../assets/icons/email.svg';
import emailLogo from '../../assets/icons/emailLogo.svg';

// actions
import { actionForgotPassword } from '../../reduxModules/appEntry'

const Card = styled(CardForm)({
  height: 504,
  width: 484,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: 26,
  boxShadow: 'None',

})

const ForgotPasswordContentsHolder = styled('div')({
  padding: '60px 62px'
})

const HeaderContainer = styled('div')({
  padding: '0 62px 41px 62px',
  textAlign: 'center'
});

const HeaderTitle = styled(Text)(({ theme }) => ({
  position: 'relative',
  fontSize: 24,
  lineHeight: '29px',
  color: theme.appEntryTitleTextColor,
  margin: '0 0 14px 0',
  display: 'flex',
  justifyContent: 'center'
}));

const HeaderDescription = styled('p')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '17px',
  color: theme.appEntryTitleTextColor,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',

}));


const SubmitButton = styled(FillButton)({
  width: '100%',
  borderRadius: 20,
  fontSize: 16,
});

const BackToSignIn = styled(FlatButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '14',
  lineHeight: '17px',
  width: '100%',
  paddingTop: 38,
  color: '##19B9A9'
}));

const MessageContainer = styled('div')({
  textAlign: 'center',
  paddingBottom: 24
});

const SuccessText = styled(Text)(({ theme }) => ({
  display: 'flex',
  fontSize: '14px',
  lineHeight: '17px',
  color: '#222222',
  alignItems: 'center',
  textAlign: 'center'
}));

const ErrorText = styled(Text)(({ theme }) => ({
  color: theme.errorTextColor,
  fontSize: '14px',
  lineHeight: '17px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center'
}));

const FieldsContainer = styled('div')({
  '>*': {
    marginBottom: 24,
  }
});


const ForgotPassword = ({
  isForgotPasswordSuccess,
  isForgotPasswordError,
  forgotPassword,
  actionForgotPassword }) => {

  const schema = Yup.object().shape({
    knox_id: Yup.string().required('Knox ID is Required'),
  });

  return (
    <Fragment>
      <Card>
        <ForgotPasswordContentsHolder>
          <HeaderContainer>
            <SvgIcon size={140} symbol={emailLogo} />
          </HeaderContainer>
          <HeaderTitle bold>Having trouble signing in?</HeaderTitle>

          {isForgotPasswordError || isForgotPasswordSuccess
            ?
            null: 
            <HeaderDescription>
              {
                `Please enter your Knox ID and we'll send the password recovery instructions.`
              }
            </HeaderDescription>
          }

          {
            isForgotPasswordSuccess
              ? <MessageContainer>
                {/* <SuccessText>{forgotPassword.message}</SuccessText> */}

                <SuccessText>  Password recovery instructions
has been successfully sent to your e-mail.</SuccessText>
              </MessageContainer> : null
          }

          {
            isForgotPasswordError
              ? <MessageContainer>
                <ErrorText>We couldn’t find an account with the entered Knox ID, please try again.</ErrorText>
              </MessageContainer> : null
          }

          {isForgotPasswordSuccess ?
            null : 
            <Formik
              initialValues={{
                knox_id: ''
              }}
              validationSchema={schema}
              onSubmit={e => {
                actionForgotPassword(e)
              }}
            >
              <Form>
                <FieldsContainer>
                  <Field
                    name="knox_id"
                    placeholder="Knox ID"
                    component={Input}
                    hasIcon
                    icon={emailIcon}
                    height={40}
                    styleType={2}
                  />


                  <SubmitButton type="submit">SEND RECOVERY E-MAIL</SubmitButton>
                </FieldsContainer>
              </Form>
            </Formik>
          }
        </ForgotPasswordContentsHolder>
      </Card>
      <BackToSignIn bold hasUnderline><Link to='/login'>Back to sign-in</Link></BackToSignIn>
    </Fragment>
  )
};


export default connect(state => ({
  isForgotPasswordSuccess: state.appEntry.isForgotPasswordSuccess,
  isForgotPasswordError: state.appEntry.isForgotPasswordError,
  forgotPassword: state.appEntry.forgotPassword
}), { actionForgotPassword })(ForgotPassword);