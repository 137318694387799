import update from 'immutability-helper';
import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  RESET_DIALOG_PROPS,
  SET_REGISTER_EMAIL_CHECK
} from './index';

const initialState = {
  isOpen: false,
  props: {},
  isRegisterEmailChecked: true
};


const dialog = (state = initialState, action = {}) => {
  switch (action.type) {
  case OPEN_DIALOG:
    return update(state, {
      isOpen: { $set: true },
      props: { $set: action.payload.props }
    });
  case CLOSE_DIALOG:
    return update(state, {
      isOpen: { $set: false }
    });
  case RESET_DIALOG_PROPS:
    return update(state, {
      props: { $set: {} }
    });
  case SET_REGISTER_EMAIL_CHECK:
    return update(state, {
      isRegisterEmailChecked: { $set: !action.payload.value }
    });
  default:
    return state;
  }
};

export default dialog;
