import { SHOW_TOAST, HIDE_TOAST, RESET_TOAST } from './index';

const TOAST_DURATION = 3000;
let timeoutToast = null;

export const actionShowToast = props => dispatch => {
  dispatch({ type: SHOW_TOAST, payload: { props } });
  if (timeoutToast) clearTimeout(timeoutToast);
  timeoutToast = setTimeout(() => dispatch(actionHideToast()), TOAST_DURATION);
};

export const actionHideToast = () => dispatch => {
  dispatch({
    type: HIDE_TOAST
  });

  window.setTimeout(() => {
    dispatch({ type: RESET_TOAST });
  }, 150);
};