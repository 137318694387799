import selection from './selection';

export const ENTER_SELECT_MODE = '[selection] ENTER_SELECT_MODE';
export const EXIT_SELECT_MODE = '[selection] EXIT_SELECT_MODE';
export const ADD_SELECTED_ITEM = '[selection] ADD_SELECTED_ITEM';
export const SELECT_ALL_ITEM = '[selection] SELECT_ALL_ITEM';
export const REMOVE_SELECTED_ITEM = '[selection] REMOVE_SELECTED_ITEM';
export const ADD_BATCH_ITEMS = '[selection] ADD_BATCH_ITEMS';
export const REMOVE_BATCH_ITEMS = '[selection] REMOVE_BATCH_ITEMS';

export {
  actionEnterSelectMode,
  actionExitSelectMode,
  actionAddSelectedItem,
  actionRemoveSelectedItem,
  actionSelectAll
} from './selectionActions';

export default selection;
