import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  size: PropTypes.number,
  symbol: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      viewBox: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired
};

const defaultProps = {
  size: 24
};

const SvgIcon = ({ size, symbol, ...other }) => (
  <svg {...other} width={size} height={size} viewBox={symbol.viewBox}>
    <use xlinkHref={`#${symbol.id}`} />
  </svg>
);

SvgIcon.propTypes = propTypes;
SvgIcon.defaultProps = defaultProps;

export default SvgIcon;
