// import Reducers
import document from './document';

// export Constants
export const GET_DOCUMENT_LIST = '[DOCUMENT] GET_DOCUMENT_LIST';
export const GET_MORE_DOCUMENT_LIST = '[DOCUMENT] GET_MORE_DOCUMENT_LIST';
export const UPLOAD_DOCUMENT = '[DOCUMENT] UPLOAD_DOCUMENT';
export const DELETE_DOCUMENT = '[DOCUMENT] DELETE_DOCUMENT';
export const EDIT_DOCUMENT = '[DOCUMENT] EDIT_DOCUMENT';
export const CANCEL_FETCH_DOCUMENTS = '[DOCUMENT] CANCEL_FETCH_DOCUMENTS';
// export Actions
export {
  actionFetchDocumentList,
  actionFetchMoreDocumentList,
  actionUploadDocument,
  actionDeleteDocument,
  actionEditDocument,
  actionCancelFetchDocuments

} from './documentActions';

// export Reducers
export default document;