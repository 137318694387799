import { LOGIN, SIGN_UP, FORGOT_PASSWORD, RESET_PASSWORD } from './index';
import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

const initialState = {
  isLoginPending: false,
  isLoginError: false,
  errorLoginMessage: "",
  isSigningUpPending: false,
  isSigningUpError: false,
  isSigningUpErrorMsg: false,
  signUpEmail: null,
  forgotPassword: null,
  isForgotPasswordPending: false,
  isForgotPasswordError: false,
  isForgotPasswordSuccess: false,
  resetPassword: null,
  isResetPasswordPending: false,
  isResetPasswordError: false,
  isResetPasswordSuccess: false,
  resetPasswordErrorMessage: ''
}

const Entry = (state = initialState, action = {}) => {
  switch(action.type) {
  case LOGIN + PENDING:
    return {
      ...state,  
      isLoginPending: true,
      isLoginError: false,
    }
  case LOGIN + ERROR:
    return {
      ...state,

      isLoginPending: false,
      isLoginError: true,
      errorLoginMessage: action.payload
    }
  case LOGIN + SUCCESS:
    return {
      ...state,
      isLoginPending: false,
      isLoginError: false,
    }
  case SIGN_UP + PENDING:
    return {...state,  isSigningUpPending: true, isSigningUpError: false }
  case SIGN_UP + SUCCESS:
    return {...state, isSigningUpPending: false, isSigningUpError: false, signUpEmail: action.payload.email }
  case SIGN_UP + ERROR:
    return {...state, isSigningUpPending: false, isSigningUpError: true, isSigningUpErrorMsg: action.payload.message }
  case FORGOT_PASSWORD + PENDING:
    return {
      ...state,
      isForgotPasswordPending: true,
      isForgotPasswordError: false,
      isForgotPasswordSuccess: false
    }
  case FORGOT_PASSWORD + ERROR:
    return {
      ...state,
      isForgotPasswordPending: false,
      isForgotPasswordError: true,
      isForgotPasswordSuccess: false
    }
  case FORGOT_PASSWORD + SUCCESS:
    return {
      ...state,
      isForgotPasswordPending: false,
      isForgotPasswordError: false,
      isForgotPasswordSuccess: true,
      forgotPassword: action.payload
    }
  case RESET_PASSWORD + PENDING:
    return {
      ...state,
      isResetPasswordPending: true,
      isResetPasswordError: false,
      isResetPasswordSuccess: false
    }
  case RESET_PASSWORD + ERROR:
    return {
      ...state,
      isResetPasswordPending: false,
      isResetPasswordError: true,
      isResetPasswordSuccess: false,
      resetPasswordErrorMessage: action.payload
    }
  case RESET_PASSWORD + SUCCESS:
    return {
      ...state,
      isResetPasswordPending: false,
      isResetPasswordError: false,
      isResetPasswordSuccess: true,
      resetPassword: action.payload
    }
  default:
    return state;
  }
}

export default Entry;