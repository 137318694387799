import update from 'immutability-helper';

import {
  ENTER_SELECT_MODE,
  EXIT_SELECT_MODE,
  ADD_SELECTED_ITEM,
  REMOVE_SELECTED_ITEM,
  SELECT_ALL_ITEM,
  ADD_BATCH_ITEMS,
  REMOVE_BATCH_ITEMS
} from './index';

const initialState = {
  selectedIds: [],
  isFromMenu: false
};

const selection = (state = initialState, action = {}) => {
  switch (action.type) {
  case ENTER_SELECT_MODE:
    return update(state, { isFromMenu: { $set: action.payload.isFromMenu } });
  case EXIT_SELECT_MODE:
    return update(state, {
      isFromMenu: { $set: initialState.isFromMenu },
      selectedIds: { $set: initialState.selectedIds }
    });
  case ADD_SELECTED_ITEM:
    return update(state, {
      selectedIds: { $push: [action.payload.key] },
      isFromMenu: { $set: initialState.isFromMenu }
    });
  case REMOVE_SELECTED_ITEM:
    return update(state, {
      selectedIds: { $splice: [[action.payload.i, 1]] }
    });
  case SELECT_ALL_ITEM:
    return update(state, {
      selectedIds: { $set: action.payload.items }
    });
  case ADD_BATCH_ITEMS:
    return update(state, {
      selectedIds: { $push: action.payload.items },
      isFromMenu: { $set: initialState.isFromMenu }
    });
  case REMOVE_BATCH_ITEMS:
    return update(state, {
      selectedIds: { $set: action.payload.items },
      isFromMenu: { $set: initialState.isFromMenu }
    });
  default:
    return state;
  }
};

export default selection;
