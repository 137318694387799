import update from 'immutability-helper';
import {
  SET_ACTIVE_EVENT,
  RESET_ACTIVE_EVENT,
  SET_ACTIVE_EVENT_SESSION,
  RESET_ACTIVE_EVENT_SESSION,
  GET_EVENTS_LIST,
  GET_MORE_EVENTS_LIST,
  GET_EVENT_DETAILS,
  OPEN_NEW_EVENT_MODAL,
  OPEN_UPDATE_EVENT_MODAL,
  CLOSE_NEW_EVENT_MODAL,
  POST_EVENT,
  PUT_EVENT,
  DELETE_EVENT, 
  FETCH_ATTENDEES,
  FETCH_MORE_ATTENDEES,
  SEARCH_TEAM_EMPLOYEE,
  DELETE_SEARCH_EMPLOYEE,
  DELETE_SEARCH_TEAM,
  SET_EVENT_GRID_VIEW,
  EXPORT_ATTENDEE_LIST,
  EXPORT_ATTENDED_LIST,
  FETCH_DEFAULT_BANNER,
  FETCH_ATTENDED_LIST,
  FETCH_MORE_ATTENDED_LIST,
  IS_FEATURE,
  FETCH_COMMITMENTS_BY_TEAM_PER_SESSION,
  EXPORT_EVENT_CSV,
  VERIFY_EVENT,
  RESET_ATTENDEE_LIST
} from './index';

import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

const initialState = {
  eventsList: [],
  totalEvents: 0,
  activeEvent: null,
  eventDetails: null,
  defaultBanners: [],

  fetchEventDetailsPending: false,
  fetchEventDetailsSuccess: false,
  fetchEventDetailsError: false,
  fetchEventListPending: false,
  fetchEventListError: false,
  fetchMoreEventListPending: false,
  fetchMoreEventListError: false,

  isCreateEventOpen: false,
  postEventPending: false,
  postEventError: false,

  isUpdateEventOpen: false,
  putEventPending: false,
  putEventError: false,
  eventActiveSession: '',


  isFetchAttendeeListPending: false,
  isFetchAttendeeListSuccess: false,
  isFetchAttendeeListError: false,

  isFetchAttendedListPending: false,
  isFetchAttendedListSuccess: false,
  isFetchAttendedListError: false,
  attendanceList: [],

  isFetchMoreAttendeeListPending: false,
  isFetchMoreAttendeeListSuccess: false,
  isFetchMoreAttendeeListError: false,

  isFetchMoreAttendedListPending: false,
  isFetchMoreAttendedListSuccess: false,
  isFetchMoreAttendedListError: false,

  

  isFeaturePending: false,
  isFeatureSuccess: false,
  isFeatureError: false,

  isFetchingEventCommitmentsByTeamPendingPerSession: false,
  isFetchingEventCommitmentsByTeamSuccessPerSession: false,
  isFetchingEventCommitmentsByTeamErrorPerSession: false,


  employees: [],
  sessions: [],
  commitmentsByTeamPerSession: [],
  totalCount: 0,
  totalPages: 0,
  isGrid: true,
  
  employeesSearch: null,
  teamsSearch: null,

  isSearchTeamEmployeePending: false,
  isSearchTeamEmployeeError: false,

  isExportAttendeeListPending: false,
  isExportAttendeeListError: false,
  isExportAttendeeListSuccess: false,
  csvFile: null,


  isExportAttendedListPending: false,
  isExportAttendedListError: false,
  isExportAttendedListSuccess: false,
  csvFileAttended: null,

  eventCSV: null,
  isFetchEventCSVPending: false,
  isFetchEventCSVSuccess: false,
  isFetchEventCSVError: false,
  isVerifyEventPending: false,
  isVerifyEventError: false
};

const events = (state = initialState, action = {}) => {
  switch (action.type) {
  case SET_ACTIVE_EVENT:
    return {
      ...state,
      activeEvent: action.payload
    }
  case RESET_ACTIVE_EVENT:
    return {
      ...state,
      activeEvent: initialState.activeEvent,
      eventDetails: initialState.eventDetails
    }
  case SET_ACTIVE_EVENT_SESSION:
    return {
      ...state,
      eventActiveSession: action.payload
    }
  case RESET_ACTIVE_EVENT_SESSION:
    return {
      ...state,
      eventActiveSession: initialState.eventActiveSession
    }
  case GET_EVENTS_LIST + PENDING:
    return {
      ...state,
      fetchEventListPending: true,
      fetchEventListError: false
    }
  case GET_EVENTS_LIST + SUCCESS:
    return {
      ...state,
      eventsList: action.payload.data,
      totalEvents: action.payload.total,
      fetchEventListPending: false,
      fetchEventListError: false
    }
  case GET_EVENTS_LIST + ERROR:
    return {
      ...state,
      fetchEventListPending: false,
      fetchEventListError: true
    }
  case GET_MORE_EVENTS_LIST + PENDING:
    return {
      ...state,
      fetchMoreEventListPending: true,
      fetchMoreEventListError: false
    }
  case GET_MORE_EVENTS_LIST + SUCCESS:
    return update(
      state,
      {
        eventsList: { $push: action.payload.data },
        fetchMoreEventListPending: { $set: false },
        fetchMoreEventListError: { $set: false }
      }
    );
  case GET_MORE_EVENTS_LIST + ERROR:
    return {
      ...state,
      fetchMoreEventListPending: false,
      fetchMoreEventListError: true
    }
  case GET_EVENT_DETAILS + PENDING:
    return {
      ...state,
      fetchEventDetailsPending: true,
      fetchEventDetailsError: false
    }
  case GET_EVENT_DETAILS + SUCCESS:
    return update(
      state,
      {
        eventDetails:{ $set: action.payload.data },
        fetchEventDetailsPending: { $set: false },
        fetchEventDetailsError: { $set: false },
        fetchEventDetailsSuccess: { $set: false }
      }
    );
  case GET_EVENT_DETAILS + ERROR:
    return {
      ...state,
      fetchEventDetailsPending: false,
      fetchEventDetailsError: true
    }
  case OPEN_NEW_EVENT_MODAL:
    return {
      ...state,
      isCreateEventOpen: true
    }
  case OPEN_UPDATE_EVENT_MODAL:
    return {
      ...state,
      isUpdateEventOpen: true
    }
  case CLOSE_NEW_EVENT_MODAL:
    return {
      ...state,
      isCreateEventOpen: false,
      isUpdateEventOpen: false
    }
  case POST_EVENT + PENDING:
    return {
      ...state,
      postEventPending: true,
      postEventError: false
    }
  case POST_EVENT + SUCCESS:
    return {
      ...state,
      isCreateEventOpen: false,
      postEventPending: false,
      postEventError: false
    }
  case POST_EVENT + ERROR:
    return {
      ...state,
      postEventPending: false,
      postEventError: true
    }
  case PUT_EVENT + PENDING:
    return {
      ...state,
      putEventPending: true,
      putEventError: false
    }
  case PUT_EVENT + SUCCESS:
    return {
      ...state,
      eventDetails: [],
      isUpdateEventOpen: false,
      putEventPending: false,
      putEventError: false
    }
  case PUT_EVENT + ERROR:
    return {
      ...state,
      putEventPending: false,
      putEventError: true
    }

  case DELETE_EVENT + SUCCESS: {
    let newState = state.eventsList;
    const i = newState.findIndex(event => event.id === action.payload.data);

    if (i >= 0) {
      newState = [
        ...newState.slice(0, i),
        ...newState.slice(i + 1)
      ];
    }

    return update(
      state, {
        eventsList: {
          $set: newState
        }
      }
    );
  }
    

  case FETCH_ATTENDEES + PENDING:
    return {
      ...state,
      isFetchAttendeeListPending: true,
      isFetchAttendeeListSuccess: false,
      isFetchAttendeeListError: false,
    }

  case FETCH_ATTENDEES + ERROR:
    return {
      ...state,
      isFetchAttendeeListPending: false,
      isFetchAttendeeListSuccess: false,
      isFetchAttendeeListError: true,
    }

  case FETCH_ATTENDEES + SUCCESS:
    return{
      ...state,
      isFetchAttendeeListPending: false,
      isFetchAttendeeListSuccess: true,
      isFetchAttendeeListError: false, 
      employees: action.payload.data,
      totalCount: action.payload.totalCount,
      totalPages: action.payload.totalPages
    }

  case FETCH_ATTENDED_LIST + PENDING:
    return {
      ...state,
      isFetchAttendedListPending: true,
      isFetchAttendedListSuccess: false,
      isFetchAttendedListError: false,
    }

  case FETCH_ATTENDED_LIST + ERROR:
    return {
      ...state,
      isFetchAttendedListPending: false,
      isFetchAttendedListSuccess: false,
      isFetchAttendedListError: true,
    }

  case FETCH_ATTENDED_LIST + SUCCESS:
    return {
      ...state,
      isFetchAttendedListPending: false,
      isFetchAttendedListSuccess: true,
      isFetchAttendedListError: false,
      attendanceList: action.payload.data,
      totalCount: action.payload.totalCount,
      totalPages: action.payload.totalPages
    }

  case FETCH_MORE_ATTENDED_LIST + PENDING:
    return {
      ...state,
      isFetchMoreAttendedListPending: true,
      isFetchMoreAttendedListSuccess: false,
      isFetchMoreAttendedListError: false,
    }

  case FETCH_MORE_ATTENDED_LIST + ERROR:
    return {
      ...state,
      isFetchMoreAttendedListPending: false,
      isFetchMoreAttendedListSuccess: false,
      isFetchMoreAttendedListError: true,
    }

  case FETCH_MORE_ATTENDED_LIST + SUCCESS:
    return {
      ...state,
      isFetchMoreAttendedListPending: false,
      isFetchMoreAttendedListSuccess: true,
      isFetchMoreAttendedListError: false,
      attendanceList: action.payload.data,
      totalCount: action.payload.totalCount,
      totalPages: action.payload.totalPages
    }

  case FETCH_MORE_ATTENDEES + PENDING:
    return {
      ...state,
      isFetchMoreAttendeeListPending: true,
      isFetchMoreAttendeeListSuccess: false,
      isFetchMoreAttendeeListError: false,
    }

  case FETCH_MORE_ATTENDEES + ERROR:
    return {
      ...state,
      isFetchMoreAttendeeListPending: false,
      isFetchMoreAttendeeListSuccess: false,
      isFetchMoreAttendeeListError: true,
    }

  case FETCH_MORE_ATTENDEES + SUCCESS:
    return update (
      state,
      {
        employees: { $push: action.payload.data },
        isFetchMoreAttendeeListPending: { $set: false },
        isFetchMoreAttendeeListError: { $set: false },
      }
    );

  case FETCH_COMMITMENTS_BY_TEAM_PER_SESSION + PENDING:
    return {
      ...state,
      isFetchingEventCommitmentsByTeamPendingPerSession: true,
      isFetchingEventCommitmentsByTeamSuccessPerSession: false,
      isFetchingEventCommitmentsByTeamErrorPerSession: false,
    }

  case FETCH_COMMITMENTS_BY_TEAM_PER_SESSION + ERROR:
    return {
      ...state,
      isFetchingEventCommitmentsByTeamPendingPerSession: false,
      isFetchingEventCommitmentsByTeamSuccessPerSession: false,
      isFetchingEventCommitmentsByTeamErrorPerSession: true,
    }

  case FETCH_COMMITMENTS_BY_TEAM_PER_SESSION + SUCCESS:
    return update(
      state,
      {
        commitmentsByTeamPerSession: { $set: action.payload.data },
        isFetchingEventCommitmentsByTeamSuccessPerSession: { $set: false },
        isFetchingEventCommitmentsByTeamErrorPerSession: { $set: false },
      }
    );

  case SEARCH_TEAM_EMPLOYEE + PENDING:
    return update(
      state, {
        isSearchTeamEmployeePending: { $set : true },
        isSearchTeamEmployeeError: { $set : false }
      }
    )
  case SEARCH_TEAM_EMPLOYEE + SUCCESS:
    return update(
      state, {
        employeesSearch: { $set : action.payload.data.employees },
        teamsSearch: { $set : action.payload.data.teams },
        isSearchTeamEmployeePending: { $set : false },
        isSearchTeamEmployeeError: { $set : false }
      }
    )
  case SEARCH_TEAM_EMPLOYEE + ERROR:
    return update (
      state,
      {
        isSearchTeamEmployeePending: { $set : false },
        isSearchTeamEmployeeError: { $set : true }
      }
    )
  case DELETE_SEARCH_EMPLOYEE: {
    let newState = state.employeesSearch;
    const i = newState.findIndex(employee => employee.id === action.payload.id);
    
    if (i >= 0) {
      newState = [
        ...newState.slice(0, i),
        ...newState.slice(i + 1)
      ];
    }

    return update(
      state, {
        employeesSearch: {
          $set: newState
        }
      }
    );
  }
  
  case DELETE_SEARCH_TEAM: {
    let newState = state.teamsSearch;
    const i = newState.findIndex(team => team.id === action.payload.id);
    
    if (i >= 0) {
      newState = [
        ...newState.slice(0, i),
        ...newState.slice(i + 1)
      ];
    }

    return update(
      state, {
        teamsSearch: {
          $set: newState
        }
      }
    );
  }

  case SET_EVENT_GRID_VIEW:
    return {
      ...state,
      isGrid: action.payload.data
    }

  case EXPORT_ATTENDEE_LIST + SUCCESS:
    return {
      ...state,
      isExportAttendeeListPending: false,
      isExportAttendeeListError: false,
      isExportAttendeeListSuccess: true,
      csvFile: action.payload.data
    }
    

  case EXPORT_ATTENDEE_LIST + PENDING:
    return {
      ...state,
      isExportAttendeeListPending: true,
      isExportAttendeeListError: false,
      isExportAttendeeListSuccess: false
    }

  case EXPORT_ATTENDEE_LIST + ERROR:
    return {
      ...state,
      isExportAttendedListPending: false,
      isExportAttendedListError: true,
      isExportAttendedListSuccess: false
    }

  case EXPORT_ATTENDED_LIST + SUCCESS:
    return {
      ...state,
      isExportAttendedListPending: false,
      isExportAttendedListError: false,
      isExportAttendedListSuccess: true,
      csvFileAttended: action.payload.data
    }


  case EXPORT_ATTENDED_LIST + PENDING:
    return {
      ...state,
      isExportAttendedListPending: true,
      isExportAttendedListError: false,
      isExportAttendedListSuccess: false
    }

  case EXPORT_ATTENDED_LIST + ERROR:
    return {
      ...state,
      isExportAttendedListPending: false,
      isExportAttendedListError: true,
      isExportAttendedListSuccess: false
    }




  case FETCH_DEFAULT_BANNER + SUCCESS:
    return {
      ...state,
      defaultBanners: action.payload.data
    }

  case IS_FEATURE + SUCCESS:
    const i = state.eventsList.findIndex(event => event.id === action.payload.data.eventId);
    return update (
      state,
      {
        eventsList : {
          [i] : {
            IsFeature : { $set : !action.payload.data.isFeature}
          }
        },
        isFeaturePending: { $set : false },
        isFeatureError: { $set : false}
      }
    )

  case IS_FEATURE + PENDING:
    return {
      ...state,
      isFeaturePending: true,
      isFeatureError: false,
      isFeatureSuccess: false
    }

  case IS_FEATURE + ERROR:
    return {
      ...state,
      isFeaturePending: false,
      isFeatureError: true,
      isFeatureSuccess: false,
      data: action.payload
    }

  case EXPORT_EVENT_CSV + PENDING:
    return {
      ...state,
      isFetchEventCSVPending: true,
      isFetchEventCSVError: false
    }
  case EXPORT_EVENT_CSV + SUCCESS:
    return update(
      state,
      {
        eventCSV: { $push: action.payload.data },
        isFetchEventCSVPending: { $set: false },
        isFetchEventCSVError: { $set: false }
      }
    );
  case EXPORT_EVENT_CSV + ERROR:
    return {
      ...state,
      isFetchEventCSVPending: false,
      isFetchEventCSVError: true
    }
    
  case VERIFY_EVENT + PENDING:
    return {
      ...state,
      isVerifyEventPending: true,
      isVerifyEventError: false
    }
  case VERIFY_EVENT + SUCCESS:
    if (state.eventDetails) {
      return {
        ...state,
        eventDetails : {
          ...state.eventDetails,
          pointsValidated: true
        },
        isVerifyEventPending: false,
        isVerifyEventError: false
      }
    }
    return {
      ...state,
      isVerifyEventPending: false,
      isVerifyEventError: false
    }
  case VERIFY_EVENT + ERROR:
    return {
      ...state,
      isVerifyEventPending: false,
      isVerifyEventError: true
    }
  case RESET_ATTENDEE_LIST:
    return {
      ...state,
      employees: initialState.employees,
      attendanceList: initialState.attendanceList
    }
  default:
    return state;
  }
};



export default events;
