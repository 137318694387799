import React from 'react'
import styled from '@emotion/styled';
import { connect } from 'react-redux';

import CardForm from '../components/CardForm';

const Card = styled(CardForm)({
  height: 346,
  width: 628,
})

const Title = styled('div')(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 24,
  lineHeight: '28px',
  textAlign: 'center',
  textTransform: 'uppercase',
  color: theme.formTitleColor,
  margin: '101px 181px 0px 181px'
}))

const SubTitle = styled('div')(({ theme }) => ({
  fontWeight: 300,
  fontSize: 14,
  lineHeight: '31px',
  textAlign: 'center',
  color: theme.formTitleColor,
  margin: '17px 174px 0px 174px'
}))

const EmailLink = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: 12,
  lineHeight: '14px',
  textAlign: 'center',
  color: theme.emailLinkColor,
  margin: '17px 239px 0px 240px'
}))

const Footer = styled('div')(({ theme }) => ({
  width: 628,
  height: 54,
  marginTop: 53,
  backgroundColor: theme.confirmBGColor,
  padding: '19px 153px 21px 154px',
  fontWeight: 300,
  fontSize: 12,
  lineHeight: '14px',
  textAlign: 'center',
  color: theme.formTitleColor
}))

const ResendLink = styled('span')(({ theme }) => ({
  fontWeight: 300,
  fontSize: 12,
  lineHeight: '14px',
  textAlign: 'center',
  color: theme.emailLinkColor,
  textDecoration: 'underline',
  cursor: 'pointer'  
}))

const ConfirmEmail = ({ signUpEmail }) => (
  <Card>
    <Title> Just one more step... </Title>
    <SubTitle>
        Please verify your email address. We've sent a confirmation emailt to:
    </SubTitle>
    <EmailLink>
      {signUpEmail}
    </EmailLink>
    <Footer>
        If you still don't see it, you can <ResendLink>resend the email confirmation</ResendLink>
    </Footer>
  </Card>
)

export default connect(state => ({
  signUpEmail: state.appEntry.signUpEmail
}), {})(ConfirmEmail);