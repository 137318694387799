import cookie from 'js-cookie';
import { redirect, NOT_FOUND } from 'redux-first-router';
import {
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_SIGNUP,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_CONFIRM_EMAIL,
  ROUTE_RESET_PASSWORD,
  ROUTE_CHANGE_PASS_SUCCESS,
  ROUTE_REQUEST
} from '../constants/routes';
import {
  JWT_TOKEN,
  ACTIVE_ACTION
} from '../constants/storage';

import { session } from '../constants/session';
// import { useLogoutForm } from '../helpers/useForms';
import { removeAllStorage } from './clearStorage';
import isEmpty from './isEmpty';

export const checkSSO = async (dispatch, getState, action) => {
  let allowed = false;
  if (cookie.get(JWT_TOKEN)) {
    allowed = true;
  }

  if (!allowed) {
    if (action.type !== NOT_FOUND
      && action.type !== ROUTE_LOGIN
      && action.type !== ROUTE_SIGNUP
      && action.type !== ROUTE_FORGOT_PASSWORD
      && action.type !== ROUTE_RESET_PASSWORD
      && action.type !== ROUTE_CHANGE_PASS_SUCCESS
      && action.type !== ROUTE_CONFIRM_EMAIL
    ) {
      dispatch(redirect({ type: ROUTE_LOGIN }));
    }
  } else {
    const currentState = getState();
    if (!isEmpty(currentState.user) && !isEmpty(currentState.user.profile) && currentState.user.profile.isGA){
      if (action.type !== ROUTE_REQUEST){
        dispatch(redirect({
          type: ROUTE_REQUEST
        }));
      }
    }
    if (
      action.type === ROUTE_LOGIN ||
      action.type === ROUTE_SIGNUP ||
      action.type === ROUTE_FORGOT_PASSWORD ||
      action.type === ROUTE_RESET_PASSWORD ||
      action.type === ROUTE_CHANGE_PASS_SUCCESS ||
      action.type === ROUTE_CONFIRM_EMAIL 
    ) {
      dispatch(redirect({
        type: ROUTE_HOME
      }));
    }
  }
};

/**
 * Session Checker
 *
 * @param funtion action
 * @see checkTimeout check if user has remaining session
 */
export const checkTimeout = () => {
  const date = parseInt(cookie.get(ACTIVE_ACTION), 0) || 0;
  const now = new Date();

  const iNextUpdate = date - now.getTime();
  // console.log('iNextUpdate: ', iNextUpdate / 1000);
  if (iNextUpdate <= 0) {
    // todo create utils for the common logout
    // useLogoutForm(); // create the logout form
    removeAllStorage();
    console.log('call formTimeOut submit');
    document.getElementById('formSignOut').submit(); // triggered the formSignOut submit to call the user signoutAPI
    console.log('LOGOUT! remaining timeout: ', iNextUpdate);

    clearTimeout('TimeoutAction');
    clearInterval(session.intervalId); // stops the interval
    return false;
  }
  console.log('You still have', iNextUpdate / 1000, 'seconds left');
  global.Timeout.updateDuration('TimeoutAction', iNextUpdate);
};

/**
 * Update Session
 *
 * @see updateDuration update the remaining session time when user is still active
 */
export const updateDuration = () => {
  // updateDuration
  const iNextUpdate = getNextUpdate();
  cookie.set(ACTIVE_ACTION, iNextUpdate);
  global.Timeout.updateDuration('TimeoutAction', iNextUpdate);
  console.log('======================> Updating duration', session.duration);
};

/**
 * @return int
 */
export const getNextUpdate = () => new Date().getTime() + session.duration;

/**
 * Utils for Session Timeout
 *
 * @see Timeout logic for session timeout
 */
export const Timeout = () => {
  const oTimeout = {};
  const oFunctions = {};

  const fnDestroy = (sTimeoutName, bDelete) => {
    clearTimeout(oTimeout[sTimeoutName]);
    delete (oTimeout[sTimeoutName]);
    if (bDelete) {
      delete (oFunctions[sTimeoutName]);
    }
    return sTimeoutName;
  };

  return {
    /**
     * Destroys timeout
     *
     * @param string sTimeoutName
     * @param functon fFunction
     * @param bool bDelete
     * @see fnDestroy
     */
    destroy: fnDestroy,
    /**
     * Initializes timeout
     *
     * @param string sTimeoutName
     * @param function fnFunction
     * @param integer iDuration
     * @param boolean bUpdate
     */
    initialize: (sTimeoutName, fnFunction, iDuration, bUpdate = false) => {
      if (typeof oTimeout[sTimeoutName] !== 'undefined') {
        if (bUpdate === false) {
          console.error('Timeout already existing. You should use Timeout.update instead');
          return false;
        }

        // otherwise, update and overwrite
        fnDestroy(sTimeoutName);
      }

      // next, add it in
      oTimeout[sTimeoutName] = setTimeout(fnFunction, iDuration);
      oFunctions[sTimeoutName] = fnFunction;
    },
    /**
     * Updates timeout duration
     *
     * @param string sTimeoutName
     * @param integer iDuration
     */
    updateDuration: (sTimeoutName, iDuration) => {
      // check if timeout is function
      if (typeof oTimeout[sTimeoutName] === 'undefined') {
        console.error(`${sTimeoutName} is not a function. Please initialize first before updating`);
        return false;
      }

      fnDestroy(sTimeoutName, false);

      // add it in
      oTimeout[sTimeoutName] = setTimeout(oFunctions[sTimeoutName], iDuration);
    }
  };
};
