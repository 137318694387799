import moment from 'moment';

import combineThunks from './utils/combineThunks';

import {
  ROUTE_LOGIN,
  ROUTE_SIGNUP,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_CHANGE_PASS_SUCCESS,
  ROUTE_HOME,
  ROUTE_EVENTS,
  ROUTE_ADD_EVENTS,
  ROUTE_UPDATE_EVENTS,
  ROUTE_PROFILE,
  ROUTE_USERS,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_DOCUMENTS,
  ROUTE_SUGGESTIONS,
  ROUTE_BCP,
  ROUTE_LEADERBOARD,
  ROUTE_REQUEST,
  ROUTE_CONFIRM_EMAIL,
  ROUTE_FEEDBACKS,
  ROUTE_VOE,
  ROUTE_ERROR_400,
  ROUTE_ERROR_403,
  ROUTE_ERROR_404,
  ROUTE_ERROR_500,
  ROUTE_ERROR_502,
  ROUTE_ERROR_503
} from './constants/routes';

import {
  actionFetchAnnouncementList,
  actionFetchCalendarEventsList,
  actionFetchBannersFeed
} from './reduxModules/appHome';
import { actionFetchEmployeeList, actionFetchTeamList } from './reduxModules/appUsers';
import { actionCheckifValid } from './reduxModules/appEntry';
import {
  actionFetchEvents,
  actionResetActiveEvent,
  actionResetActiveEventSession,
  actionCloseNewEventModal,
  actionFetchAttendees,
  actionFetchEventDetails,
  actionGetDefaultBanner
} from './reduxModules/appEvents';
import { actionFetchDocumentList } from './reduxModules/appDocuments';
import { actionFetchDocumentRequests, actionResetFilters, actionFetchCancelledRequests } from './reduxModules/appRequest';
import { actionFetchSuggestionsList, actionResetFilters as actionResetFiltersSuggestions } from './reduxModules/appSuggestions';
import { actionGetBcpList, actionResetActiveAlert } from './reduxModules/appBCP';

const date = moment(new Date());

const routesMap = {
  [ROUTE_HOME]: {
    path: '/',
    thunk: combineThunks(
      actionFetchAnnouncementList(),
      actionFetchCalendarEventsList(date.format('M'), date.format('YYYY')),
      actionResetActiveEvent(),
      actionResetActiveEventSession(),
      actionFetchBannersFeed()
    )
  },
  [ROUTE_EVENTS]: {
    path: '/events',
    thunk: combineThunks(
      actionFetchEvents(),
      actionResetActiveEvent(),
      actionResetActiveEventSession(),
      actionCloseNewEventModal()
    )
  },
  [ROUTE_ADD_EVENTS]: {
    path: '/events/add',
    thunk: combineThunks(
      actionFetchTeamList(),
      actionGetDefaultBanner(),
      actionResetActiveEvent(),
      actionResetActiveEventSession()
    )
  },
  [ROUTE_UPDATE_EVENTS]: {
    path: '/events/update/:id',
    thunk: combineThunks(
      actionFetchTeamList(),
      actionGetDefaultBanner(),
      actionFetchEventDetails(),
      actionResetActiveEvent(),
      actionResetActiveEventSession(),
    )
  },
  [ROUTE_USERS]: {
    path: '/users',
    thunk: combineThunks(
      actionFetchEmployeeList(),
      actionFetchTeamList(),
      actionResetActiveEvent(),
      actionResetActiveEventSession(),
      actionResetActiveEvent(),
      actionResetActiveEventSession()
    )
  },
  [ROUTE_DOCUMENTS]: {
    path: '/documents',
    thunk: combineThunks(
      actionResetActiveEvent(),
      actionResetActiveEventSession(),
      actionResetActiveEvent(),
      actionResetActiveEvent(),
      actionResetActiveEventSession()
    )
  },
  [ROUTE_SUGGESTIONS]: {
    path: '/suggestions',
    thunk: combineThunks(
      actionFetchSuggestionsList(),
      actionResetFiltersSuggestions(),
      actionResetActiveEvent(),
      actionResetActiveEventSession()
    )
  },
  [ROUTE_LEADERBOARD]: {
    path: '/leaderboard'
  },
  [ROUTE_VOE]:{
    path: '/voe'
  },
  [ROUTE_REQUEST]: {
    path: '/request',
    thunk: combineThunks(
      actionResetFilters(),
      actionFetchDocumentRequests(),
      actionFetchCancelledRequests(),
      actionResetActiveEvent(),
      actionResetActiveEventSession()
    )
  },
  [ROUTE_BCP]: {
    path: '/bcp',
    thunk: combineThunks(
      actionGetBcpList(),
      actionResetActiveAlert()
    )
  },
  [ROUTE_PROFILE]: {
    path: '/profile'
  },
  [ROUTE_CHANGE_PASSWORD]: {
    path: '/changepassword'

  },
  [ROUTE_LOGIN]: '/login',
  // [ROUTE_SIGNUP]: '/signup',
  [ROUTE_CONFIRM_EMAIL]: '/signup/confirm',
  [ROUTE_FORGOT_PASSWORD]: '/forgotpassword',
  [ROUTE_RESET_PASSWORD]: {
    path: '/resetpassword',
    thunk: combineThunks(
      actionCheckifValid
    )
  },
  [ROUTE_CHANGE_PASS_SUCCESS]: '/changepasssuccess',
  [ROUTE_ERROR_400]: '/error400',
  [ROUTE_ERROR_403]: '/error403',
  [ROUTE_ERROR_404]: '/error404',
  [ROUTE_ERROR_500]: '/error500',
  [ROUTE_ERROR_502]: '/error502',
  [ROUTE_ERROR_503]: '/error503'
};

export default routesMap;
