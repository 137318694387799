
import { CancelToken } from 'axios';
import config from '../../constants/serverConfig';
import api from '../../utils/api';

import isEmpty from '../../utils/isEmpty';
import { saveAs } from 'file-saver'; 

import {
  FETCH_LEADERBOARD,
  FETCH_MORE_LEADERBOARD,
  RESET_LEADERBOARD_BREAKDOWN,
  FETCH_LEADERBOARD_BREAKDOWN,
  CANCEL_FETCH_LEADERBOARD,
  CLAIM_LEADERBOARD,
  FETCH_EMPLOYEE_TOTAL_POINTS,
  UPLOAD_REWARDS_BANNER
} from './index';
import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

let source = CancelToken.source();

const date = new Date();

export const actionFetchLeaderboard = (
  page = 1,
  rows = 20,
  startMonth = date.getMonth(),
  startYear = date.getFullYear(),
  endMonth = date.getMonth(),
  endYear = date.getFullYear(),
  search = ''
) => async dispatch => {
  try {
    dispatch({ type: FETCH_LEADERBOARD + PENDING });

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/leaderboard?page=${page}&rows=${rows}&fromMonth=${startMonth}&fromYear=${startYear}&toMonth=${endMonth}&toYear=${endYear}&search=${search}`,
      cancelToken: source.token
    })

    const { _metaData, leaderBoard} = data.data;

    dispatch({
      type: FETCH_LEADERBOARD + SUCCESS,
      payload: {
        leaderboardList: leaderBoard,
        metaData: _metaData
      }
    });

  } catch(e){
    dispatch({ type: FETCH_LEADERBOARD + ERROR });
    console.log('ERROR FETCH LEADERBOARD', e.message)
  }
}

export const actionFetchMoreLeaderboard = (
  page = 1,
  rows = 20,
  startMonth = date.getMonth(),
  startYear = date.getFullYear(),
  endMonth = date.getMonth(),
  endYear = date.getFullYear(),
  search = '',
  isFirst = false,
) => async dispatch => {
  try {
    dispatch({ type: FETCH_MORE_LEADERBOARD + PENDING });

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/leaderboard?page=${page}&rows=${rows}&fromMonth=${startMonth}&fromYear=${startYear}&toMonth=${endMonth}&toYear=${endYear}&search=${search}`,
      cancelToken: source.token
    })

    const { leaderBoard} = data.data;

    dispatch({
      type: FETCH_MORE_LEADERBOARD + SUCCESS,
      payload: {
        leaderboardList: leaderBoard,
        isFirst
      }
    });

  } catch(e){
    dispatch({ type: FETCH_MORE_LEADERBOARD + ERROR });
  }
}

export const actionFetchPointBreakdown = employeeId => async dispatch => {

  try {
    dispatch({ type: FETCH_LEADERBOARD_BREAKDOWN + PENDING });
    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/events/employee-points/${employeeId}`,
      cancelToken: source.token
    });

    dispatch({
      type: FETCH_LEADERBOARD_BREAKDOWN + SUCCESS,
      payload: {
        list: data.data
      }
    });

  } catch (e) {
    dispatch({ type: FETCH_LEADERBOARD_BREAKDOWN + ERROR, e });
  }

}

export const actionResetPointBreakdown = () => ({
  type : RESET_LEADERBOARD_BREAKDOWN
});

export const actionCancelFetchLeaderboard = () => dispatch => {
  source.cancel();
  source = CancelToken.source();

  dispatch({
    type: CANCEL_FETCH_LEADERBOARD
  });
};
export const actionClaimLeaderboard = (knoxId, points = 0) => async dispatch => {

  try {
    dispatch({ type: CLAIM_LEADERBOARD + PENDING });

    const { data } = await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/employees/redeem-points/${knoxId}`,
      cancelToken: source.token,
      data: {
        "redeemPoints": points
      }
    });

    dispatch({
      type: CLAIM_LEADERBOARD + SUCCESS,
      payload: data
    });

  } catch (e) {
    dispatch({ type: CLAIM_LEADERBOARD + ERROR });
  }
}

export const actionFetchEmployeTotalPoints = id => async dispatch => {
  try {
    dispatch({ type: FETCH_EMPLOYEE_TOTAL_POINTS + PENDING });

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/employees/total-points/${id}`,
      cancelToken: source.token
    });

    dispatch({
      type: FETCH_EMPLOYEE_TOTAL_POINTS + SUCCESS,
      payload: data
    });

  } catch (e) {
    dispatch({ type: FETCH_EMPLOYEE_TOTAL_POINTS + ERROR });
  }
}

export const actionUploadRewardsBanner = (banner) => async dispatch => {

  try {
    dispatch({ type: UPLOAD_REWARDS_BANNER + PENDING });

    let formData = new FormData();
    formData.append('image', banner);
    formData.append('type', banner.type);
 
    const { data } = await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/points/image/upload`,
      cancelToken: source.token,
      headers: {
        'Content-type': 'multipart/form-data'
      },
      data: formData
    });

    dispatch({
      type: UPLOAD_REWARDS_BANNER + SUCCESS,
      payload: data
    });

  } catch (e) {
    dispatch({ type: UPLOAD_REWARDS_BANNER + ERROR });
  }
}

export const actionExportCSV = (fromMonth, fromYear, toMonth, toYear ) => async dispatch => {

  const { data } = await api({
    method: 'GET',
    url: `${config.SERVER_BASE_URL}/leaderboard/export?fromMonth=${fromMonth}&fromYear=${fromYear}&toMonth=${toMonth}&toYear=${toYear}`,
    cancelToken: source.token
  });
  const csvFile = data.data;

  if (!isEmpty(csvFile)) {
    saveAs(csvFile, csvFile);
  }
}