export const HR_POLICIES = "HR POLICIES";
export const PROCESS = "PROCESS";
export const GUIDELINES = "GUIDELINES";
export const FORMS = "FORMS";
export const EMPLOYEE_SALES = "EMPLOYEE SALES";

export const filter_options = [
  {
    _id: 0,
    name: "All",
    value: "",
  },
  {
    _id: 1,
    name: "Policies",
    value: "hp",
  },
  {
    _id: 2,
    name: "Processes",
    value: "p",
  },
  {
    _id: 3,
    name: "Forms",
    value: "f",
  },
  {
    _id: 4,
    name: "Employee Sales",
    value: "es",
  },
  {
    _id: 5,
    name: "Message from the President",
    value: "mp",
  },
  {
    _id: 6,
    name: "General Information",
    value: "g",
  },
];

export const voe_status_filter_options = [
  {
    _id: 0,
    name: "All Statuses",
    value: "all_statuses",
  },
  {
    _id: 1,
    name: "Read",
    value: "read",
  },
  {
    _id: 2,
    name: "Unread",
    value: "unread",
  },
];

export const upload_options = [
  {
    _id: 0,
    name: "Policies",
    value: "hp",
  },
  {
    _id: 1,
    name: "Processes",
    value: "p",
  },
  {
    _id: 2,
    name: "Forms",
    value: "f",
  },
  {
    _id: 3,
    name: "Employee Sales",
    value: "es",
  },
  {
    _id: 4,
    name: "Message from the President",
    value: "mp",
  },
  {
    _id: 5,
    name: "General Information",
    value: "g",
  },
];

export const edit_options = {
  hp: "POLICIES",
  p: "PROCESSES",
  f: "FORMS",
  es: "EMPLOYEE SALES",
  mp: "MESSAGE FROM THE PRESIDENT",
  g: "GENERAL FORM",
};
export const stringToCode = {
  POLICIES: "hp",
  PROCESSES: "p",
  FORMS: "f",
  "EMPLOYEE SALES": "es",
  "MESSAGE FROM THE PRESIDENT": "mp",
  "GENERAL FORM": "g",
};
