
// import Reducers
import leaderboard from './leaderboard';

// export Constants
export const FETCH_LEADERBOARD = '[LEADERBOARD] FETCH_LEADERBOARD';
export const FETCH_MORE_LEADERBOARD = '[LEADERBOARD] FETCH_MORE_LEADERBOARD';
export const FETCH_LEADERBOARD_BREAKDOWN = '[LEADERBOARD] FETCH_LEADERBOARD_BREAKDOWN';
export const RESET_LEADERBOARD_BREAKDOWN = '[LEADERBOARD] RESET_LEADERBOARD_BREAKDOWN';
export const CANCEL_FETCH_LEADERBOARD = '[LEADERBOARD] CANCEL_FETCH_LEADERBOARD';
export const CLAIM_LEADERBOARD = '[LEADERBOARD] CLAIM_LEADERBOARD';
export const FETCH_EMPLOYEE_TOTAL_POINTS = '[LEADERBOARD] FETCH_EMPLOYEE_TOTAL_POINTS';
export const UPLOAD_REWARDS_BANNER = '[LEADERBOARD] UPLOAD_REWARDS_BANNER';

// export Actions
export {
  actionFetchLeaderboard,
  actionFetchMoreLeaderboard,
  actionFetchPointBreakdown,
  actionResetPointBreakdown,
  actionCancelFetchLeaderboard,
  actionClaimLeaderboard,
  actionFetchEmployeTotalPoints,
  actionUploadRewardsBanner,
  actionExportCSV,
} from './leaderboardActions';

// export Reducers
export default leaderboard;