import update from 'immutability-helper';

import {
  GET_EMPLOYEE_LIST,
  GET_MORE_EMPLOYEE_LIST,
  PATCH_EMPLOYEE,
  UPLOAD_EMPLOYEE_LIST,
  DELETE_EMPLOYEE,
  ADD_EMPLOYEE,
  GET_TEAM_LIST,
  GET_HR_GA,
  UPDATE_EMPLOYEE,
  CHANGE_PASSWORD
} from './index';

import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

const initialState = {
  employeeList: [],
  teamList: [],
  assigneeList: [],
  employeesWithErrorUpload: [],
  errorMessage: undefined,
  totalEmployeeCount: 0,
  isFetchEmployeeListPending: false,
  isFetchEmployeeListError: false,
  isFetchMoreEmployeeListPending: false,
  isFetchMoreEmployeeListError: false,
  isFetchingHRGAPending: false,
  isUpdateEmployeePending: false,
  isUpdateEmployeeError: false,
  isUploadEmployeeListPending: false,
  isUploadEmployeeListError: false,
  isUploadEmployeeListSuccess: false,
  isDeleteEmployeePending: false,
  isDeleteEmployeeError: false,
  isDeleteEmployeeSuccess: false,
  isAddEmployeePending: false,
  isAddEmployeeError: false,
  isGetTeamListPending: false,
  isGetTeamListSuccess: false,
  isGetTeamListError: false,
  uploadResult: '',


  isChangePasswordPending: false,
  isChangePasswordError: false,
  isChangePasswordSuccess: false,
  changePassword: null,
  changePasswordErrorMessage: ''
};

const users = (state = initialState, action = {}) => {
  switch (action.type) {
  case GET_EMPLOYEE_LIST + PENDING:
    return {
      ...state,
      isFetchEmployeeListPending: true,
      isFetchEmployeesError: false
    };
  case GET_EMPLOYEE_LIST + SUCCESS:
    return {
      ...state,
      isFetchEmployeeListPending: false,
      isFetchEmployeesError: false,
      employeeList: action.payload.data, 
      totalEmployeeCount: action.payload.totalCount
    };
  case GET_EMPLOYEE_LIST + ERROR:
    return {
      ...state,
      isFetchEmployeesError: true,
      isFetchEmployeeListPending: false
    };
  case GET_MORE_EMPLOYEE_LIST + PENDING:
    return {
      ...state,
      isFetchMoreEmployeeListPending: true,
      isFetchMoreEmployeeListError: false
    };
  case GET_MORE_EMPLOYEE_LIST + SUCCESS:
    return update(
      state,
      {
        employeeList: { $push: action.payload.data },
        isFetchMoreEmployeeListPending: { $set: false },
        isFetchMoreEmployeeListError: { $set: false }
      }
    );
  case GET_MORE_EMPLOYEE_LIST + ERROR:
    return {
      ...state,
      isFetchMoreEmployeeListPending: false,
      isFetchMoreEmployeeListError: true
    };
  case PATCH_EMPLOYEE + PENDING:
    return {
      ...state,
      isUpdateEmployeePending: true
    };
  case PATCH_EMPLOYEE + SUCCESS:
    
    const employeeList = state.employeeList;
    employeeList[employeeList.map(e => e.id).indexOf(action.payload.data.id)] = action.payload.data;

    return {
      ...state,
      isUpdateEmployeePending: false,
      employeeList
    };
  case PATCH_EMPLOYEE + ERROR:
    return {
      ...state,
      isUpdateEmployeeError: true
    };
  case UPLOAD_EMPLOYEE_LIST + PENDING:
    return {
      ...state,
      isUploadEmployeeListPending: true,
      isUploadEmployeeListError: false,
      isUploadEmployeeListSuccess: false
    }
  case UPLOAD_EMPLOYEE_LIST + ERROR:
    return {
      ...state,
      isUploadEmployeeListPending: false,
      isUploadEmployeeListError: true,
      isUploadEmployeeListSuccess: false,
      employeesWithErrorUpload: action.payload
    }
  case UPLOAD_EMPLOYEE_LIST + SUCCESS:
    return {
      ...state,
      isUploadEmployeeListPending: false,
      isUploadEmployeeListError: false,
      isUploadEmployeeListSuccess: true,
      uploadResult: action.payload
    }
  case DELETE_EMPLOYEE + PENDING:
    return {
      ...state,
      isDeleteEmployeePending: true,
      isDeleteEmployeeError: false,
      isDeleteEmployeeSuccess: false
    }
  case DELETE_EMPLOYEE + ERROR:
    return {
      ...state,
      isDeleteEmployeePending: false,
      isDeleteEmployeeError: true,
      isDeleteEmployeeSuccess: false,
      DeleteResult: action.payload
    }
  case DELETE_EMPLOYEE + SUCCESS:
    return {
      ...state,
      isDeleteEmployeePending: false,
      isDeleteEmployeeError: false,
      isDeleteEmployeeSuccess: true,
      DeleteResult: action.payload
    }
  case ADD_EMPLOYEE + PENDING:
    return {
      ...state,
      isAddEmployeePending: true,
      isAddEmployeeError: false
    }
  case ADD_EMPLOYEE + SUCCESS: {
    console.log(action.payload.data)
    return update(
      state,
      {
        employeeList : { $unshift : [action.payload.data] },
        isAddEmployeePending: { $set : false },
        isAddEmployeeError: { $set : false }
      }
    )
  } 
  case ADD_EMPLOYEE + ERROR:
    return {
      ...state,
      isAddEmployeePending: false,
      isAddEmployeeError: true
    }
  case UPDATE_EMPLOYEE + PENDING:
    return {
      ...state,
      isUpdateEmployeePending: true,
      isUpdateEmployeeError: false,
      errorMessage: undefined
    }
  case UPDATE_EMPLOYEE + SUCCESS: {
    const i = state.employeeList.findIndex(employee => employee.employeeId === action.payload.data.employeeId);
    console.log(action.payload.data)
    return update(
      state,
      {
        employeeList : {
          [i] : {
            isActive : { $set : action.payload.data.status },
            type : { $set : action.payload.data.type },
            teamName : { $set : action.payload.data.team },
            firstName : { $set : action.payload.data.firstName },
            lastName : { $set : action.payload.data.lastName },
            knoxId : { $set : action.payload.data.knoxId } 
          }
        },
        isUpdateEmployeePending: { $set : false },
        isUpdateEmployeeError: { $set : false },
        errorMessage: { $set: undefined }
      }
    )
  } 
  case UPDATE_EMPLOYEE + ERROR:
    return {
      ...state,
      isUpdateEmployeePending: false,
      isUpdateEmployeeError: true,
      errorMessage: action.payload
    }
  case GET_TEAM_LIST + PENDING: return {
    ...state,
    isGetTeamListPending: true,
    isGetTeamListSuccess: false,
    isGetTeamListError: false,
  }
  case GET_TEAM_LIST + SUCCESS: return {
    ...state,
    isGetTeamListPending: false,
    isGetTeamListSuccess: true,
    isGetTeamListError: false,
    teamList: action.payload.data.teams
  }
  case GET_TEAM_LIST + ERROR: return {
    ...state,
    isGetTeamListPending: false,
    isGetTeamListSuccess: false,
    isGetTeamListError: true,
  }
  case GET_HR_GA + PENDING: 
    return {
      ...state,
      isFetchingHRGAPending: true
    }
  case GET_HR_GA + SUCCESS: 
    return {
      ...state,
      assigneeList: action.payload,
      isFetchingHRGAPending: false
    }
  case GET_HR_GA + ERROR:
    return {
      ...state,
      isFetchingHRGAPending: false
    }

  case CHANGE_PASSWORD + PENDING:
    return {
      ...state,
      isChangePasswordPending: true,
      isChangePasswordError: false,
      isChangePasswordSuccess: false
    }
  case CHANGE_PASSWORD + ERROR:
    return {
      ...state,
      isChangePasswordPending: false,
      isChangePasswordError: true,
      isChangePasswordSuccess: false,
      changePasswordErrorMessage: action.payload
    }
  case CHANGE_PASSWORD + SUCCESS:
    return {
      ...state,
      isChangePasswordPending: false,
      isChangePasswordError: false,
      isChangePasswordSuccess: true,
      changePassword: action.payload
    }

  default:
    return state;
  }
};

export default users;
