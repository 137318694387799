import styled from '@emotion/styled';
import button from '../../styles/common/button';

const FillButton = styled('button')(
  button,
  ({
    theme,
    inverted,
    round,
    bold,
    padding = '14px 54px',
    isActive = true,
    borderRadius = 5,
    fontSize = 11,
    lineHeight = '13px',
    isOrange = false,
    disabled = false
  }) => ({
    display: 'flex',
    flexShrink: 0,
    fontSize,
    fontWeight: bold ? 'bold' : 'normal',
    lineHeight,
    padding,
    minWidth: 'auto',
    width: round ? '55px' : 'auto',
    height: round ? '55px' : 41,
    borderRadius: round ? '50%' : borderRadius,
    alignItems: 'center',
    backgroundColor: inverted ? theme.fillBtnBgInvertedColor : isActive ? (isOrange ? theme.fillBtnOrange : theme.fillBtnBgColor) : theme.fillBtnInactiveBgColor,
    color: inverted ? theme.fillBtnTextColorInverted : theme.fillBtnTextColor,
    fill: inverted ? theme.fillBtnTextColorInverted : theme.fillBtnTextColor,
    border: inverted ? `1px solid ${theme.fillBtnTextColorInverted}` : 0,
    '&:hover': {
      backgroundColor: inverted ? theme.fillBtnBgInvertedColor : isActive ? (isOrange ? theme.fillBtnOrangeHover : theme.fillBtnHoverBgColor) : theme.fillBtnHoverInactiveBgColor,
      color: inverted ? theme.fillBtnHoverBgColor :  theme.fillBtnTextColor,
      fill: inverted ? theme.fillBtnHoverBgColor :  theme.fillBtnTextColor,
      border: inverted ? `1px solid ${theme.fillBtnHoverBgColor}` : 0
    },

    '&:active': {
      backgroundColor: inverted ? theme.fillBtnBgInvertedColor : isActive ? (isOrange ? theme.fillBtnOrangeActive : theme.fillBtnActiveBgColor): theme.fillBtnActiveInactiveBgColor,
      color: inverted ? theme.fillBtnActiveBgColor : theme.fillBtnTextColor,
      fill: inverted ? theme.fillBtnActiveBgColor : theme.fillBtnTextColor,
      border: inverted ? `1px solid ${theme.fillBtnActiveBgColor}` : 0
    },

    '&:disabled': {
      backgroundColor: inverted ? theme.fillBtnBgInvertedColor : theme.fillBtnDisabledBgColor,
      color: inverted ? theme.fillBtnDisabledBgColor : theme.fillBtnTextColor,
      fill: inverted ? theme.fillBtnDisabledBgColor : theme.fillBtnTextColor,
      border: inverted ? `1px solid ${theme.fillBtnDisabledBgColor}` : 0,
      pointerEvents: 'none'
    },

    '*':{
      cursor: 'pointer !important'
    }
  })
);


export default FillButton;
