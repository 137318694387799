import { CancelToken } from 'axios';
// import cookie from 'js-cookie';

import config from '../../../constants/serverConfig';
import api from '../../../utils/api';
import { simpleCrypto } from '../../../utils/cryptoHelper';
import { removeAllStorage } from '../../../utils/clearStorage';
import { USER_PROFILE } from '../../../constants/storage';

import {
  TOGGLE_USER_DRAWER,
  UPDATE_USER,
  LOGOUT_USER
} from './index';


import { PENDING, SUCCESS, ERROR } from '../../../constants/statusTypes';

let source = CancelToken.source();

// actions
export const actionToggleUserDrawer = props => ({
  type: TOGGLE_USER_DRAWER,
  payload: { props }
});

export const actionUpdateUser = form => async (dispatch, getState) => {
  try {
    
    dispatch({ type: UPDATE_USER + PENDING });

    await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/update-account`,
      cancelToken: source.token,
      data: {
        "team": form.team,
        "first_name": form.firstName,
        "last_name": form.lastName,
        "knox_id": form.knoxId,
        "is_active": form.isActive
      }
    });

    dispatch({
      type: UPDATE_USER + SUCCESS,
      payload: { data: form }
    });

    const newProfile = {
      ...getState().user.profile,
      ...form
    }
    const encToken = simpleCrypto.encrypt(newProfile); // Encrypt token
    localStorage.setItem(USER_PROFILE, encToken);

  } catch (e) {
    dispatch({ type: UPDATE_USER + ERROR });
  }
};

export const actionLogout = () => async dispatch => {
  try {
    
    dispatch({ type: LOGOUT_USER + PENDING });
    

    api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/logout`,
      cancelToken: source.token
    });
    removeAllStorage();

    dispatch({ type: LOGOUT_USER + SUCCESS });

    window.location = '#/login'


  } catch (e) {
    dispatch({ type: LOGOUT_USER + ERROR });
  }
};