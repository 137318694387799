import modal from './modal';

export const OPEN_MODAL = '[modal] OPEN_MODAL';
export const CLOSE_MODAL = '[modal] CLOSE_MODAL';
export const RESET_MODAL = '[modal] RESET_MODAL';
export const TOGGLE_SHOW_REQUEST_TOP_BORDER = '[modal] TOGGLE_SHOW_REQUEST_TOP_BORDER';
export const TOGGLE_SHOW_REQUEST_BOTTOM_BORDER = '[modal] TOGGLE_SHOW_REQUEST_BOTTOM_BORDER';
export const RESET_SHOW_REQUEST_TOP_BORDER = '[modal] RESET_SHOW_REQUEST_TOP_BORDER';
export const RESET_SHOW_REQUEST_BOTTOM_BORDER = '[modal] RESET_SHOW_REQUEST_BOTTOM_BORDER';

export {
  actionOpenModal,
  actionCloseModal,
  actionResetModal,
  actionToggleReqTopBorder,
  actionResetReqTopBorder,
  actionToggleReqBottomBorder,
  actionResetReqBottomBorder
} from './modalActions';

export default modal;
