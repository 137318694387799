import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const propTypes = {
  width:  PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number
  ]),
  borderRadius: PropTypes.number
}

const defaultProps = {
  width: 516,
  borderRadius: 15
};

const CardForm = styled('div')(({ theme, width, borderRadius }) => ({
  position: 'relative',
  backgroundColor: theme.formBgColor,
  overflow: 'hidden',
  width,
  height: 'auto',
  boxShadow: '-2px 2px 4px rgba(176, 176, 176, 0.5)',
  borderRadius
}))

CardForm.propTypes = propTypes;
CardForm.defaultProps = defaultProps;


export default CardForm;