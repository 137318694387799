import moment from 'moment';
import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

import {
  FETCH_DOCUMENT_REQUESTS,
  UPDATE_DOCUMENT_STATUS,
  SET_REQUEST_ASSIGNEE,
  FETCH_CANCELLED_REQUESTS,
  SET_FILTER_START_DATE,
  SET_FILTER_END_DATE,
  RESET_FILTERS,
  SET_FILTER_REF_NUM,
  EXPORT_DOCUMENT_REQUEST,
} from './index';

import { toTimestamp } from '../../utils/datesConverter';

const now = moment();

export const initialState = {
  isFetchingRequests: false,
  isUpdatingDocStatus: false,
  isFetchingCancelledRequests: false,
  isAssigningDocRequest: false,
  isAssigningDocRequestError: false,
  isFetchingCancelledRequestError: false,

  isExportDocumentRequestPending: false,
  isExportDocumentRequestError: false,
  isExportDocumentRequestSuccess: false,
  csvFileDocReq: null,

  docRequests: [],
  cancelledRequests: [],
  pagination: {
    rows: 10,
    page: 1,
    totalCount: 0,
    totalPages: 0
  },
  filters: {
    startDate: new Date(now.startOf('month').format('YYYY/MM/DD')),
    endDate: new Date(now.endOf('month').format('YYYY/MM/DD')),
    refNum: ""
  }
  
}


const Requests = (state = initialState, action) => {
  switch(action.type) {
  case FETCH_DOCUMENT_REQUESTS + PENDING: 
    return {
      ...state,
      isFetchingRequests: true
    }
  case FETCH_DOCUMENT_REQUESTS + SUCCESS:
    return {
      ...state,
      docRequests: [...action.payload.data],
      isFetchingRequests: false
    }
  case FETCH_DOCUMENT_REQUESTS + ERROR:
    return {
      ...state,
      isFetchingRequests: false,
      docRequests: initialState.docRequests
    }
  case UPDATE_DOCUMENT_STATUS + PENDING:
    return {
      ...state,
      isUpdatingDocStatus: true
    }
  case UPDATE_DOCUMENT_STATUS + SUCCESS: 
    let request = state.docRequests.filter(ev => ev.id === action.payload.id)[0];
    request.status = action.payload.status;
    
    const logs = [{
      statusTo: action.payload.status,
      reason: action.payload.reason,
      moveTime: toTimestamp(new Date()) // temporary only, timestamp should come from the api response
    }]

    request = {...request, logs }
    
    let requests =  state.docRequests.filter(ev => ev.id !== action.payload.id);
    requests = [
      ...requests,
      request
    ];

    return {
      ...state,
      isUpdatingDocStatus: false,
      docRequests: requests
    }
  case UPDATE_DOCUMENT_STATUS + ERROR:
    return {
      ...state,
      isUpdatingDocStatus: false
    }
  case SET_REQUEST_ASSIGNEE + PENDING:
    return {
      ...state,
      isAssigningDocRequest: true,
      isAssigningDocRequestError: false
    }
  case SET_REQUEST_ASSIGNEE + SUCCESS:
    const index = state.docRequests.findIndex(ev => ev.id === action.payload.docId)
    let docReqs = [...state.docRequests]
    docReqs[index].assignee.assignee.name = action.payload.userName
    docReqs[index].assignee.assignee.id = action.payload.userId    
    
    return {
      ...state,
      docRequests: docReqs,
      isAssigningDocRequest: false,
      isAssigningDocRequestError: false
    }
  case SET_REQUEST_ASSIGNEE + ERROR:
    return {
      ...state,
      isAssigningDocRequest: false,
      isAssigningDocRequestError: true
    }
  case SET_FILTER_START_DATE: 
    return {
      ...state,
      filters: {...state.filters, startDate: action.payload }
    }
  case SET_FILTER_END_DATE:
    return {
      ...state,
      filters: {...state.filters, endDate: action.payload }
    }
  case RESET_FILTERS:
    return {
      ...state,
      filters: initialState.filters,
      pagination: initialState.pagination
    }
  case FETCH_CANCELLED_REQUESTS + PENDING:
    return {
      ...state,
      isFetchingCancelledRequests: true
    }
  case FETCH_CANCELLED_REQUESTS + SUCCESS:
    return {
      ...state,
      isFetchingCancelledRequests: false,
      cancelledRequests: action.payload.data,
      pagination: action.payload.pagination
    }
  case FETCH_CANCELLED_REQUESTS + ERROR:
    return {
      ...state,
      isFetchingCancelledRequests: false,
      isFetchingCancelledRequestError: true
    }
  case SET_FILTER_REF_NUM: 
    return {
      ...state,
      filters: {...state.filters, refNum: action.payload }
    }

  case EXPORT_DOCUMENT_REQUEST + SUCCESS:
    return {
      ...state,
      isExportDocumentRequestPending: false,
      isExportAttendeeListError: false,
      isExportDocumentRequestSuccess: true,
      csvFileDocReq: action.payload.data
    }


  case EXPORT_DOCUMENT_REQUEST + PENDING:
    return {
      ...state,
      isExportDocumentRequestPending: true,
      isExportDocumentRequestError: false,
      isExportDocumentRequestSuccess: false
    }

  case EXPORT_DOCUMENT_REQUEST + ERROR:
    return {
      ...state,
      isExportDocumentRequestPending: false,
      isExportDocumentRequestError: true,
      isExportDocumentRequestSuccess: false
    }

  default:
    return state;
  }
}

export default Requests;