import moment from 'moment';
import update from 'immutability-helper';
import {
  GET_SUGGESTIONS_LIST,
  GET_MORE_SUGGESTIONS_LIST,
  REPLY_SUGGESTION,
  SET_FILTER_START_DATE,
  SET_FILTER_END_DATE,
  RESET_FILTERS,
  SET_FILTER_SEARCH
} from './index'

import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

const now = moment();

const initialState = {
  list: [],
  totalList: 0,

  isFetchListPending: false,
  isFetchListError: false,
  isReplySuggestionPending: false,
  isReplySuggestionError: false,
  isReplySuggestionSuccess: false,
  isFetchMoreListPending: false,
  isFetchMoreListError: false,

  filters: {
    startDate: new Date(now.startOf('month').format('YYYY/MM/DD')),
    endDate: new Date(now.endOf('month').format('YYYY/MM/DD')),
    search: ""
  }
}

const suggestion = (state = initialState, action = {}) => {
  switch (action.type) {
  case GET_SUGGESTIONS_LIST + PENDING:
    return {
      ...state,
      isFetchListPending: true,
      isFetchListError: false,
    };
  case GET_SUGGESTIONS_LIST + SUCCESS:
    return {
      ...state,
      isFetchListPending: false,
      isFetchListError: false,
      list: action.payload.data,
      totalList: action.payload.total
    };
  case GET_SUGGESTIONS_LIST + ERROR:
    return {
      ...state,
      isFetchListPending: false,
      isFetchListError: true
    };
  case GET_MORE_SUGGESTIONS_LIST + PENDING:
    return {
      ...state,
      isFetchMoreListPending: true,
      isFetchMoreListError: false,
    };
  case GET_MORE_SUGGESTIONS_LIST + SUCCESS:
    return update(state,{
      isFetchMoreListPending: { $set: false },
      isFetchMoreListError: { $set: false },
      list: { $push : action.payload.data }
    });
  case GET_MORE_SUGGESTIONS_LIST + ERROR:
    return {
      ...state,
      isFetchMoreListPending: false,
      isFetchMoreListError: true
    };
  case REPLY_SUGGESTION + PENDING:
    return {
      ...state,
      isReplySuggestionPending: true,
      isReplySuggestionError: false,
      isReplySuggestionSuccess: false
    };
  case REPLY_SUGGESTION + SUCCESS:
    return {
      ...state,
      isReplySuggestionPending: false,
      isReplySuggestionError: false,
      isReplySuggestionSuccess: true
    };
  case REPLY_SUGGESTION + ERROR:
    return {
      ...state,
      isReplySuggestionPending: false,
      isReplySuggestionError: true,
      isReplySuggestionSuccess: false
    };
  case SET_FILTER_START_DATE:
    return {
      ...state,
      filters: {
        ...state.filters,
        startDate: action.payload
      }
    }
  case SET_FILTER_END_DATE:
    return {
      ...state,
      filters: {
        ...state.filters,
        endDate: action.payload
      }
    }
  case RESET_FILTERS:
    return {
      ...state,
      filters: initialState.filters
    }
  case SET_FILTER_SEARCH: 
    return {
      ...state,
      filters: {...state.filters, search: action.payload }
    }
  default:
    return state;
  }
}

export default suggestion