// import Reducers
import users from './users';

// export Constants
export const GET_EMPLOYEE_LIST = '[USERS] GET_EMPLOYEE_LIST';
export const GET_MORE_EMPLOYEE_LIST = '[USERS] GET_MORE_EMPLOYEE_LIST';
export const PATCH_EMPLOYEE = '[USERS] PATCH_EMPLOYEE';
export const UPLOAD_EMPLOYEE_LIST = '[USERS] UPLOAD_EMPLOYEE_LIST';
export const ADD_EMPLOYEE = '[USERS] ADD_EMPLOYEE';
export const DELETE_EMPLOYEE = '[USERS] DELETE_EMPLOYEE';
export const UPDATE_EMPLOYEE = '[USERS] UPDATE_EMPLOYEE';
export const GET_TEAM_LIST = '[USERS] GET_TEAM_LIST';
export const GET_HR_GA = '[USERS] GET_HR_GA';
export const CHANGE_PASSWORD = '[USERS] CHANGE PASSWORD'; 
export const CANCEL_FETCH_EMPLOYEE_LIST = '[USERS] CANCEL_FETCH_EMPLOYEE_LIST';

// export Actions
export {
  actionFetchEmployeeList,
  actionFetchMoreEmployeeList,
  actionUpdateEmployee,
  actionUploadEmployeeList,
  actionDeleteEmployee,
  actionAddEmployee,
  actionFetchTeamList,
  actionFetchHRGA,
  actionChangePassword,
  actionCancelFetchEmployeeList
} from './usersActions';

// export Reducers
export default users;
