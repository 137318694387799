import { rgba, darken } from 'polished';

import fullPageLoading from '../assets/images/gif/white_48.gif';
import cardLoading from '../assets/images/gif/white_48.gif';

// constants / reusable
const PRIMARY_COLOR = '#19B9A9';
const SECONDARY_COLOR = '#4D72D1';
const BLACK = '#000000';
const WHITE = '#FFFFFF';
const RED = '#EE2B2A';
const TRANSPARENT = 'transparent';

const PRIMARY_COLOR_20 = rgba(PRIMARY_COLOR, 0.2);
const PRIMARY_COLOR_80 = rgba(PRIMARY_COLOR, 0.8);
const PRIMARY_COLOR_ACTIVE = darken(0.2, PRIMARY_COLOR);
const PRIMARY_COLOR_DISABLED = rgba(PRIMARY_COLOR, 0.4);

const DARK_GRAY = '#4C4C4C';

// body
const DEFAULT_BACKGROUND_COLOR = '#EEEEEE';
const TITLE_TEXT_COLOR = '#222222';

// Main Header
const HEADER_BG_COLOR = '#FFF';
const HEADER_SHADOW_COLOR = rgba(BLACK, 0.10);
const HEADER_COLOR_ACTIVE = PRIMARY_COLOR;
const HEADER_TEXT_COLOR = '#888888'
const HEADER_TEXT_COLOR_DISABLED = '#d3d3d3'

// Page Headers
const PAGE_HEADER_BG_COLOR = '#2B2E37';

// Scrollbar
const SCROLL_TOP_BG_COLOR = DEFAULT_BACKGROUND_COLOR;
const SCROLL_TOP_ARROW_COLOR = DARK_GRAY;
const SCROLL_TOP_BORDER_COLOR = rgba(BLACK, 0.18);

// Checkbox
const CHECKBOX_BG_COLOR = TRANSPARENT;
const CHECKBOX_BORDER_COLOR = '#CCC';
const CHECKBOX_CHECK_COLOR = '#666';

// modal
const MODAL_OVERLAY_COLOR = rgba(0, 0, 0, 0.6);
const MODAL_SHADOW_COLOR = rgba(BLACK, 0.2);
const MODAL_BG_COLOR = '#FAFAFA';
const DELETION_BOX_BG_COLOR = '#f0f0f0';
const DELETION_BOX_TEXT_COLOR = '#e5e5e5';
const LANGUAGE_DIVIDER_COLOR = '#d4d4d4';
const FLAT_LANGUAGE_COLOR = '#0074d4';

// fill button
const FILL_BUTTON_COLOR_HOVER = darken(0.1, PRIMARY_COLOR);
const FILL_BUTTON_COLOR_ACTIVE = darken(0.2, PRIMARY_COLOR);
const FILL_BUTTON_COLOR_DISABLED = rgba(PRIMARY_COLOR, 0.4);
const FILL_BUTTON_COLOR_INVERTED = WHITE;
const FILL_BUTTON_INACTIVE_BG_COLOR = '#979797';
const FILL_BUTTON_INACTIVE_HOVER_BG_COLOR = darken(0.1, FILL_BUTTON_INACTIVE_BG_COLOR);
const FILL_BUTTON_INACTIVE_ACTIVE_BG_COLOR = darken(0.2, FILL_BUTTON_INACTIVE_BG_COLOR);
const FILL_BUTTON_ORANGE = '#FF9F00';
const FILL_BUTTON_ORANGE_HOVER = darken(0.1, FILL_BUTTON_ORANGE);
const FILL_BUTTON_ORANGE_ACTIVE = darken(0.2, FILL_BUTTON_ORANGE);

// flat button
const FLAT_BUTTON_COLOR_HOVER = PRIMARY_COLOR_80;
const FLAT_BUTTON_COLOR_ACTIVE = '#418AC1';
const FLAT_BUTTON_COLOR_GRAY = '#888888';
const FLAT_BUTTON_COLOR_GRAY_HOVER = darken(0.1, FLAT_BUTTON_COLOR_GRAY);
const FLAT_BUTTON_COLOR_GRAY_ACTIVE = darken(0.2, FLAT_BUTTON_COLOR_GRAY);

// toast
const TOAST_BACKGROUND_COLOR = rgba(BLACK, 0.9);
const TOAST_BUTTON_COLOR = '#4297ff';
const TOAST_BUTTON_HOVER_COLOR = darken(0.1, TOAST_BUTTON_COLOR);
const TOAST_BUTTON_ACTIVE_COLOR = darken(0.2, TOAST_BUTTON_COLOR);

// other properties
const FONT_WEIGHT_NORMAL = 'normal';
const TEXT_DECORATION_NONE = 'none';
const EMAIL_LINK_COLOR = '#1EAD98';
const CONFIRM_FOOTER_BG_COLOR = '#E9E9E9';
const CONFIRM_ERROR_TEXT_COLOR = '#EE2B2A';

// Calendar
const CALENDAR_BORDER_COLOR = '#979797'
const CALENDAR_TEXT_COLOR = '#6D6D6D'
const CALENDAR_BODY_COLOR = '#777777'
const CALENDAR_DATE_COLOR = BLACK
const CALENDAR_BIG_TEXT_COLOR = '#1a8fff'

// Select Box
const SELECT_BOX_BACKGROUND_COLOR = WHITE;
const SELECT_BOX_BORDER_COLOR = '#CCCCCC';
const SELECT_BOX_TEXT_COLOR = '#222222';
const SELECT_BOX_TEXT_COLOR_PLACEHOLDER = '#888888';
const SELECT_BOX_ARROW_ICON = '#999999';
const SELECT_BOX_HOVER_BACKGROUND_COLOR = WHITE;
const SELECT_BOX_OPTIONS_BACKGROUND_COLOR = WHITE;

// Table
const TABLE_DATA_BORDER = '#979797';
const TABLE_HEADER_BORDER = '#979797';

// Form
const FORM_BG_COLOR = WHITE;
const FORM_TITLE_COLOR = 'rgba(40, 40, 40, 0.967533)';

// Input
const INPUT_TEXT_COLOR = '#20222A';
const INPUT_BG_COLOR = WHITE;
const INPUT_ICON_BG_COLOR = '#EEE';
const INPUT_ICON_FILL = '#999999';
const INPUT_COLOR_ERROR = '#F44336';
const INPUT_BODER_COLOR = '#E5E5E5';
const INPUT_BODER_COLOR_2 = '#CECECE';
const INPUT_CARET_COLOR = PRIMARY_COLOR;
const INPUT_PLACEHOLDER_COLOR = '#8D8D8D';
const INPUT_BOX_SHADOW_COLOR = '#ECECEC';

// App Entry Page
const APP_ENTRY_PAGE_BG_COLOR = '#F3F3F3';
const APP_ENTRY_TITLE_TEXT_COLOR = 'rgba(32, 34, 42, 0.967533)';
const APP_ENTRY_GRAY_TEXT_COLOR = '#7A7A7A';

// Loading
const LOADING_FULL_PAGE_GIF = fullPageLoading;
const LOADING_CARD_GIF = cardLoading;

// Text Color
const TEXT_COLOR = '#6D6D6D';

// Profile - Default
const DEFAULT_PROFILE_BACKGROUND_COLOR = '#D8D8D8';
const DEFAULT_PROFILE_BORDER_COLOR = '#979797';

// Events Card
const EVENTS_CARD_DESC = '#565555';
const EVENTS_CARD_TITLE = WHITE;
const EVENTS_CREATE_EVENT_LABEL = '#414A53';
const EVENTS_CARD_BANNER_COLOR_HOLIDAY= '#FFC107';
const EVENTS_CARD_BANNER_COLOR_VIP = '#8D63DB';
const EVENTS_IMG_BG_HOVER_COLOR = 'rgba(82, 92, 102, 0.6)';
const EVENTS_CARD_BANNER_TITLE = '#FFFFFF';
const EVENTS_GENERAL_TEXT_COLOR = '#222222';

// Create Event Area
const CREATE_EVENT_AREA_BORDER_COLOR = '2px dashed #525C66';
const CREATE_EVENT_AREA_BGCOLOR = 'rgba(82, 92, 102, 0.15)';

// Document Upload Modal
const DD_UPLOAD_AREA_BGCOLOR = '#EEFBFF';
const DD_UPLOAD_AREA_TEXT_COLOR = '#979797';
const DD_UPLOAD_AREA_BORDER = '1px solid #8FE5FF';
const DOC_UPLOAD_MODAL_TITLE_TEXT_COLOR = 'rgba(32, 34, 42, 0.9675)' 


// Event Details
const ED_DETAILS_CONTAINER_BG_COLOR = WHITE;
const ED_ES_DETAILS_CONTAINER_TEXT_COLOR = 'rgba(32, 34, 42, 0.967533)';
const ED_IMAGE_CONTAINER_BG_COLOR = '#EEFBFF';

// Event Sessions
const ES_COUNTER_TEXT_COLOR = '#222222';

//Suggestions
const SUGGESTIONS_TEXT_COLOR = '#222222';
const SUGGESTIONS_SUB_TEXT_COLOR = '#2B2B2B';
const SUGGESTIONS_BTN_COLOR = '#19B9A9';
const SUGGESTIONS_REPLY_BG_COLOR = '#F4F4F4';

//Leaderboards
const LEADERBOARD_CARD_TOP3_BG_COLOR = '#F4F4F4';
const LEADERBOARD_CARD_BORDER_COLOR = '#cccccc';
const LEADERBOARD_CARD_TEXT_COLOR = '#222222';
const LEADERBOARD_MOREMENU_ITEM_LINE_COLOR = '#E7E7E7';

//BCP
const BCP_TITLE_TEXT_COLOR = '#222222';
const BCP_LINK_TEXT_COLOR = '#19B9A9';
const BCP_CHAT_BTN_COLOR = '#FF9F00';
const BCP_NORESPONSE_ICON_COLOR = '#888888';

// HC
const HC_CYAN = '#00DFE6';
const HC_YELLOW = '#FFF01F';
const HC_DISABLED_COLOR = '#505050';
const HC_BACKGROUND_COLOR = BLACK;
const HC_CYAN_ACTIVE = rgba(HC_CYAN, 0.8);
const HC_CYAN_DISABLED = rgba(HC_CYAN, 0.61);
const HC_CYAN_BG = '#00B2B8';
const HC_CYAN_BG_ACTIVE = rgba(HC_CYAN_BG, 0.8);
const HC_HEADER_BG_COLOR = '#000000';
const HC_FORM_BG_COLOR = BLACK;

const themes = {
  default: {
    appEntryPageBgColor: APP_ENTRY_PAGE_BG_COLOR,
    appEntryTitleTextColor: APP_ENTRY_TITLE_TEXT_COLOR,
    appEntryGrayTextColor: APP_ENTRY_GRAY_TEXT_COLOR,
    bcpLinkTextColor: BCP_LINK_TEXT_COLOR,
    bcpTitleTextColor: BCP_TITLE_TEXT_COLOR,
    bcpChatBtnColor: BCP_CHAT_BTN_COLOR,
    bcpNoResponseIconColor: BCP_NORESPONSE_ICON_COLOR,
    calendarBig : CALENDAR_BIG_TEXT_COLOR,
    calendarBgColor: WHITE,
    calendarBorderColor: CALENDAR_BORDER_COLOR,
    calendarBodyColor: CALENDAR_BODY_COLOR,
    calendarDateColor: CALENDAR_DATE_COLOR,
    calendarTextColor: CALENDAR_TEXT_COLOR,
    checkBoxBgColor: CHECKBOX_BG_COLOR,
    checkBoxBorderColor: CHECKBOX_BORDER_COLOR,
    checkBoxCheckColor: CHECKBOX_CHECK_COLOR,
    confirmBGColor: CONFIRM_FOOTER_BG_COLOR,
    confirmErrorTextColor: CONFIRM_ERROR_TEXT_COLOR,
    createEventAreaBorderColor: CREATE_EVENT_AREA_BORDER_COLOR,
    createEventAreaBGColor: CREATE_EVENT_AREA_BGCOLOR,
    emailLinkColor: EMAIL_LINK_COLOR,
    ddUploadAreaBorder : DD_UPLOAD_AREA_BORDER,
    ddUploadAreaBGColor: DD_UPLOAD_AREA_BGCOLOR,
    ddUploadAreaTextColor: DD_UPLOAD_AREA_TEXT_COLOR,
    docUploadModalTextColor : DOC_UPLOAD_MODAL_TITLE_TEXT_COLOR,
    defaultProfileBackgroundColor: DEFAULT_PROFILE_BACKGROUND_COLOR,
    defaultProfileBorderColor: DEFAULT_PROFILE_BORDER_COLOR,
    edDetailsContainerBGColor: ED_DETAILS_CONTAINER_BG_COLOR,
    edDetailsContainerTextColor: ED_ES_DETAILS_CONTAINER_TEXT_COLOR,
    edImageContainerBGColor: ED_IMAGE_CONTAINER_BG_COLOR,
    esCounterTextColor: ES_COUNTER_TEXT_COLOR,
    errorTextColor: RED,
    eventCardBannerColorHoliday: EVENTS_CARD_BANNER_COLOR_HOLIDAY,
    eventCardBannerColorVIP: EVENTS_CARD_BANNER_COLOR_VIP,
    eventsCardBannerTitle: EVENTS_CARD_BANNER_TITLE,
    eventCardDesc: EVENTS_CARD_DESC,
    eventCardTitle: EVENTS_CARD_TITLE,
    eventsCreateEventLabel: EVENTS_CREATE_EVENT_LABEL, 
    eventImgBGHoverColor: EVENTS_IMG_BG_HOVER_COLOR,
    eventsGeneralTextColor: EVENTS_GENERAL_TEXT_COLOR,
    fillBtnActiveBgColor: FILL_BUTTON_COLOR_ACTIVE,
    fillBtnBgColor: PRIMARY_COLOR,
    fillBtnBgInvertedColor: FILL_BUTTON_COLOR_INVERTED,
    fillBtnDisabledBgColor: FILL_BUTTON_COLOR_DISABLED,
    fillBtnHoverBgColor: FILL_BUTTON_COLOR_HOVER,
    fillBtnTextColor: WHITE,
    fillBtnTextColorInverted: PRIMARY_COLOR,
    fillBtnInactiveBgColor: FILL_BUTTON_INACTIVE_BG_COLOR,
    fillBtnHoverInactiveBgColor: FILL_BUTTON_INACTIVE_HOVER_BG_COLOR,
    fillBtnActiveInactiveBgColor: FILL_BUTTON_INACTIVE_ACTIVE_BG_COLOR,
    fillBtnOrange: FILL_BUTTON_ORANGE,
    fillBtnOrangeHover: FILL_BUTTON_ORANGE_HOVER,
    fillBtnOrangeActive: FILL_BUTTON_ORANGE_ACTIVE,
    flatBtnBgColor: TRANSPARENT,
    flatBtnTextColor: PRIMARY_COLOR,
    flatBtnGrayTextColor: FLAT_BUTTON_COLOR_GRAY,
    flatBtnGrayHoverTextColor: FLAT_BUTTON_COLOR_GRAY_HOVER,
    flatBtnGrayActiveTextColor: FLAT_BUTTON_COLOR_GRAY_ACTIVE,
    flatBtnHoverBgColor: TRANSPARENT,
    flatBtnActiveBgColor: TRANSPARENT,
    flatBtnActiveTextColor: FLAT_BUTTON_COLOR_ACTIVE,
    flatBtnDisabledTextColor: PRIMARY_COLOR_20,
    flatBtnHoverTextColor: FLAT_BUTTON_COLOR_HOVER,
    formBgColor: FORM_BG_COLOR,
    formTitleColor: FORM_TITLE_COLOR,
    headerBgColor: HEADER_BG_COLOR,
    headerColorActive: HEADER_COLOR_ACTIVE,
    headerShadowColor: HEADER_SHADOW_COLOR,
    headerTextColor: HEADER_TEXT_COLOR,
    headerTextColorDisabled: HEADER_TEXT_COLOR_DISABLED,
    homeGreetingColor: TITLE_TEXT_COLOR,
    inputBgColor: INPUT_BG_COLOR,
    inputIconBgColor: INPUT_ICON_BG_COLOR,
    inputIconFill: INPUT_ICON_FILL,
    inputBorderColor: INPUT_BODER_COLOR,
    inputBorderColorType2: INPUT_BODER_COLOR_2,
    inputColorError: INPUT_COLOR_ERROR,
    inputCaretColor: INPUT_CARET_COLOR,
    inputPlaceHolderColor: INPUT_PLACEHOLDER_COLOR,
    inputTextColor: INPUT_TEXT_COLOR,
    inputBoxShadowColor: INPUT_BOX_SHADOW_COLOR,
    leaderboardTop3CardBgColor: LEADERBOARD_CARD_TOP3_BG_COLOR,
    leaderboardCardBorderColor: LEADERBOARD_CARD_BORDER_COLOR,
    leaderboardCardTextColor: LEADERBOARD_CARD_TEXT_COLOR,
    leaderboardMoreMenuItemColor: LEADERBOARD_MOREMENU_ITEM_LINE_COLOR,
    loadingFullPage: LOADING_FULL_PAGE_GIF,
    loadingCard: LOADING_CARD_GIF,
    mainBGColor: DEFAULT_BACKGROUND_COLOR,
    modalOverlayColor: MODAL_OVERLAY_COLOR,
    modalShadowColor: MODAL_SHADOW_COLOR,
    modalBgColor: MODAL_BG_COLOR,
    pageHeaderBgColor: PAGE_HEADER_BG_COLOR,
    primaryColor: PRIMARY_COLOR,
    selectBoxBgColor: SELECT_BOX_BACKGROUND_COLOR,
    selectBoxBorderColor: SELECT_BOX_BORDER_COLOR,
    selectBoxTextColor: SELECT_BOX_TEXT_COLOR,
    selectBoxTextColorPlaceholder: SELECT_BOX_TEXT_COLOR_PLACEHOLDER,
    selectBoxArrowIcon: SELECT_BOX_ARROW_ICON,
    selectBoxHoverBgColor: SELECT_BOX_HOVER_BACKGROUND_COLOR,
    selectBoxOptionsBgColor: SELECT_BOX_OPTIONS_BACKGROUND_COLOR,
    selectBoxSelectedTextColor: PRIMARY_COLOR,
    selectBoxHoverTextColor: PRIMARY_COLOR,
    subMenuBgColor: HEADER_BG_COLOR,
    tableDataBorder: TABLE_DATA_BORDER,
    tableHeaderBorder: TABLE_HEADER_BORDER,
    textColor: TEXT_COLOR,
    toastBgColor: TOAST_BACKGROUND_COLOR,
    toastColor: WHITE,
    toastButtonColor: TOAST_BUTTON_COLOR,
    toastButtonActiveColor: TOAST_BUTTON_ACTIVE_COLOR,
    toastButtonHoverColor: TOAST_BUTTON_HOVER_COLOR,
    suggestionTextColor: SUGGESTIONS_TEXT_COLOR,
    suggestionsSubTextColor: SUGGESTIONS_SUB_TEXT_COLOR,
    suggestionsBtnColor: SUGGESTIONS_BTN_COLOR,
    suggestionsReplyBgColor: SUGGESTIONS_REPLY_BG_COLOR
  },
  highContrast: {
    appEntryPageBgColor: APP_ENTRY_PAGE_BG_COLOR,
    appEntryTitleTextColor: APP_ENTRY_TITLE_TEXT_COLOR,
    appEntryGrayTextColor: APP_ENTRY_GRAY_TEXT_COLOR,
    bcpLinkTextColor: BCP_LINK_TEXT_COLOR,
    bcpTitleTextColor: BCP_TITLE_TEXT_COLOR,
    bcpChatBtnColor: BCP_CHAT_BTN_COLOR,
    bcpNoResponseIconColor: BCP_NORESPONSE_ICON_COLOR,
    calendarBigTextColor: CALENDAR_BIG_TEXT_COLOR,
    calendarBgColor: BLACK,
    calendarBorderColor: CALENDAR_BORDER_COLOR,
    calendarBodyColor: HC_YELLOW,
    calendarDateColor: HC_YELLOW,
    calendarTextColor: HC_YELLOW,
    checkBoxBgColor: CHECKBOX_BG_COLOR,
    checkBoxBorderColor: CHECKBOX_BORDER_COLOR,
    checkBoxCheckColor: CHECKBOX_CHECK_COLOR,
    confirmBGColor: CONFIRM_FOOTER_BG_COLOR,
    confirmErrorTextColor: CONFIRM_ERROR_TEXT_COLOR,
    emailLinkColor: HC_YELLOW,
    eventCardTitle: EVENTS_CARD_TITLE,
    defaultProfileBackgroundColor: DEFAULT_PROFILE_BACKGROUND_COLOR,
    defaultProfileBorderColor: DEFAULT_PROFILE_BORDER_COLOR,
    errorTextColor: RED,
    fillBtnActiveBgColor: HC_CYAN_ACTIVE,
    fillBtnBgColor: HC_YELLOW,
    fillBtnBgInvertedColor: BLACK,
    fillBtnDisabledBgColor: HC_DISABLED_COLOR,
    fillBtnHoverBgColor: HC_CYAN_BG,
    fillBtnTextColor: BLACK,
    fillBtnTextColorInverted: HC_YELLOW,
    fillBtnInactiveBgColor: FILL_BUTTON_INACTIVE_BG_COLOR,
    fillBtnHoverInactiveBgColor: FILL_BUTTON_INACTIVE_HOVER_BG_COLOR,
    fillBtnActiveInactiveBgColor: FILL_BUTTON_INACTIVE_ACTIVE_BG_COLOR,
    fillBtnOrange: FILL_BUTTON_ORANGE,
    fillBtnOrangeHover: FILL_BUTTON_ORANGE_HOVER,
    fillBtnOrangeActive: FILL_BUTTON_ORANGE_ACTIVE,
    flatBtnBgColor: TRANSPARENT,
    flatBtnTextColor: PRIMARY_COLOR,
    flatBtnGrayTextColor: FLAT_BUTTON_COLOR_GRAY,
    flatBtnGrayHoverTextColor: FLAT_BUTTON_COLOR_GRAY_HOVER,
    flatBtnGrayActiveTextColor: FLAT_BUTTON_COLOR_GRAY_ACTIVE,
    flatBtnHoverBgColor: HC_CYAN_BG,
    flatBtnActiveBgColor: HC_CYAN_BG_ACTIVE,
    flatBtnActiveTextColor: BLACK,
    flatBtnDisabledTextColor: HC_CYAN_DISABLED,
    flatBtnHoverTextColor: BLACK,
    formBgColor: HC_FORM_BG_COLOR,
    formTitleColor: FORM_TITLE_COLOR,
    headerBgColor: HC_HEADER_BG_COLOR,
    headerColorActive: HC_CYAN,
    headerShadowColor: TRANSPARENT,
    headerTextColor: HC_YELLOW,
    headerTextColoDisabled: HC_YELLOW,
    homeGreetingColor: HC_YELLOW,
    inputBgColor: INPUT_BG_COLOR,
    inputIconBgColor: INPUT_ICON_BG_COLOR,
    inputIconFill: INPUT_ICON_FILL,
    inputBorderColor: INPUT_BODER_COLOR,
    inputBorderColorType2: INPUT_BODER_COLOR_2,
    inputColorError: INPUT_COLOR_ERROR,
    inputCaretColor: INPUT_CARET_COLOR,
    inputPlaceHolderColor: INPUT_PLACEHOLDER_COLOR,
    inputTextColor: INPUT_TEXT_COLOR,
    inputBoxShadowColor: INPUT_BOX_SHADOW_COLOR,
    leaderboardTop3CardBgColor: LEADERBOARD_CARD_TOP3_BG_COLOR,
    leaderboardCardBorderColor: LEADERBOARD_CARD_BORDER_COLOR,
    leaderboardCardTextColor: LEADERBOARD_CARD_TEXT_COLOR,
    leaderboardMoreMenuItemColor: LEADERBOARD_MOREMENU_ITEM_LINE_COLOR,
    loadingFullPage: LOADING_FULL_PAGE_GIF,
    loadingCard: LOADING_CARD_GIF,
    mainBGColor: BLACK,
    modalOverlayColor: MODAL_OVERLAY_COLOR,
    modalShadowColor: MODAL_SHADOW_COLOR,
    modalBgColor: BLACK,
    pageHeaderBgColor: PAGE_HEADER_BG_COLOR,
    primaryColor: PRIMARY_COLOR,
    selectBoxBgColor: SELECT_BOX_BACKGROUND_COLOR,
    selectBoxBorderColor: SELECT_BOX_BORDER_COLOR,
    selectBoxTextColor: SELECT_BOX_TEXT_COLOR,
    selectBoxTextColorPlaceholder: SELECT_BOX_TEXT_COLOR_PLACEHOLDER,
    selectBoxArrowIcon: SELECT_BOX_ARROW_ICON,
    selectBoxHoverBgColor: SELECT_BOX_HOVER_BACKGROUND_COLOR,
    selectBoxOptionsBgColor: SELECT_BOX_OPTIONS_BACKGROUND_COLOR,
    selectBoxSelectedTextColor: PRIMARY_COLOR,
    selectBoxHoverTextColor: PRIMARY_COLOR,
    subMenuBgColor: HC_HEADER_BG_COLOR,
    tableDataBorder: TABLE_DATA_BORDER,
    tableHeaderBorder: TABLE_HEADER_BORDER,
    textColor: TEXT_COLOR,
    toastBgColor: TOAST_BACKGROUND_COLOR,
    toastColor: WHITE,
    toastButtonColor: TOAST_BUTTON_COLOR,
    toastButtonActiveColor: TOAST_BUTTON_ACTIVE_COLOR,
    toastButtonHoverColor: TOAST_BUTTON_HOVER_COLOR,
    suggestionTextColor: SUGGESTIONS_TEXT_COLOR,
    suggestionsSubTextColor: SUGGESTIONS_SUB_TEXT_COLOR,
    suggestionsBtnColor: SUGGESTIONS_BTN_COLOR,
    suggestionsReplyBgColor: SUGGESTIONS_REPLY_BG_COLOR
  }
};

export default themes;
