import cookie from 'js-cookie';
import axios, { request } from 'axios';
import config from '../constants/serverConfig';
import { JWT_TOKEN } from '../constants/storage';
import { errorHandler } from './apiErrorHandler';
import isEmpty from './isEmpty';

export const axiosConfig = {
  baseURL: config.SERVER_BASE_URL,
  headers: {
    // 'Cache-Control': 'no-cache',
    // 'x-sc-useragent': ';;WebPortal=0.1;Windows NT 6.1; WOW64;',
    'Content-Type': 'application/json;',
  }
};

const api = (options = {}) => {

  let jwtToken = {};
  if(!isEmpty(cookie.get(JWT_TOKEN))){
    jwtToken = {
      'Authorization': `Bearer ${cookie.get(JWT_TOKEN)}`
    }
  }
  

  const finalOptions = {
    ...axiosConfig,
    ...options,
    headers: {
      ...jwtToken,
      ...axiosConfig.headers,
      ...options.headers
    }
  };
  console.log('headers', finalOptions)
  return request({ ...finalOptions });
};

axios.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => response,
  e => {
    if (axios.isCancel(e)){
      return Promise.reject({isCancel : true});
    }
    console.log('======> error response: ', e);
    errorHandler(e);
    return Promise.reject(e);
  }
);

export default api;
