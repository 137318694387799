export const PENDING = '_PENDING';
export const SUCCESS = '_SUCCESS';
export const ERROR = '_ERROR';

export const OPEN = '_OPEN';
export const CLOSE = '_CLOSE';

export const ENTER = '_ENTER';
export const EXIT = '_EXIT';

export const SHOW = '_SHOW';
export const HIDE = '_HIDE';
