const config = {
  SERVER_LOCAL_BASE_URL: 'http://localhost:8080',
  // SERVER_BASE_URL: 'https://sepcoapp-stage.samsungph.net/api/v1', // dev
  // SERVER_BASE_URL: 'https://sepcoapi.samsungph.net/api/v1', // uat
  // SERVER_BASE_URL: 'https://sepcoapp-qa.samsungph.net/api/v1', // qa
  SERVER_BASE_URL: 'https://api-sepconnect.samsung321.com/api/v1', // prod
  // SERVER_BASE_URL: 'https://otv4xezzn1.execute-api.ap-southeast-1.amazonaws.com/Stage/api/v1',
  APP_BASE_URL_NO_PARAMS: `${window.location.origin}/#`,
};

export default config;
