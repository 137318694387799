import update from 'immutability-helper';
import {
  OPEN_MODAL,
  CLOSE_MODAL,
  RESET_MODAL,
  TOGGLE_SHOW_REQUEST_TOP_BORDER,
  TOGGLE_SHOW_REQUEST_BOTTOM_BORDER,
  RESET_SHOW_REQUEST_TOP_BORDER,
  RESET_SHOW_REQUEST_BOTTOM_BORDER
} from './index';

const initialState = {
  isOpen: false,
  props: {},
  isTopBorderVisible: false,
  isBottomBorderVisible: false
};

const modal = (state = initialState, action = {}) => {
  switch (action.type) {
  case OPEN_MODAL:
    return update(state, {
      isOpen: { $set: true }
    });
  case CLOSE_MODAL:
    return update(state, {
      isOpen: { $set: false }
    });
  case RESET_MODAL:
    return initialState;
  case TOGGLE_SHOW_REQUEST_TOP_BORDER:
    return {
      ...state,
      isTopBorderVisible: !state.isTopBorderVisible
    };
  case RESET_SHOW_REQUEST_TOP_BORDER:
    return {
      ...state,
      isTopBorderVisible: action.payload
    };
  case TOGGLE_SHOW_REQUEST_BOTTOM_BORDER:
    return {
      ...state,
      isBottomBorderVisible: !state.isBottomBorderVisible
    };
  case RESET_SHOW_REQUEST_BOTTOM_BORDER:
    return {
      ...state,
      isBottomBorderVisible: action.payload
    };

  default:
    return state;
  }
};

export default modal;
