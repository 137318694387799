// import Reducers
import home from './home';

// export Constants
export const GET_ANNOUNCEMENT_LIST = '[HOME] GET_ANNOUNCEMENT_LIST';
export const FETCH_MORE_ANNOUNCEMENT = '[HOME] FETCH_MORE_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT = '[HOME] DELETE_ANNOUNCEMENT';
export const GET_CALENDAR_EVENTS_LIST = '[HOME] GET_CALENDAR_EVENTS_LIST';
export const GET_DEFAULT_BANNERS = '[HOME] GET_DEFAULT_BANNERS';
export const DELETE_DEFAULT_BANNERS = '[HOME] DELETE_DEFAULT_BANNERS';
export const UPLOAD_DEFAULT_BANNERS = '[HOME] UPLOAD_DEFAULT_BANNERS';

// export Actions
export {
  actionFetchAnnouncementList,
  actionDeleteAnnouncement,
  actionFetchCalendarEventsList,
  actionFetchMoreAnnouncements,
  actionFetchBannersFeed,
  actionDeleteDefaultFeed,
  actionUploadDefaultFeed
} from './homeActions';

// export Reducers
export default home;
