import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const propTypes = {
  width:  PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number
  ]),
  borderRadius: PropTypes.number
}

const defaultProps = {
  width: '100%',
  borderRadius: 10
};

const Card = styled('div')(({ theme, width, borderRadius }) => ({
  position: 'relative',
  backgroundColor: theme.formBgColor,
  overflow: 'hidden',
  width,
  height: 'auto',
  boxShadow: '6px 12px 19px rgba(183, 183, 183, 0.5)',
  borderRadius
}))

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;


export default Card;