import appDocument from './appDocuments';
import appEntry from './appEntry';
import appEvents from './appEvents';
import appHome from './appHome';
import appUsers from './appUsers';
import appRequest from './appRequest';
import appSuggestion from './appSuggestions'
import appLeaderboard from './appLeaderboard'
import appBCP from './appBCP';
import appVoes from './appVoes';

import dialog from './common/dialog';
import knobs from './common/knobs';
import modal from './common/modal';
import selection from './common/selection';
import toast from './common/toast';
import user from './common/user';

const reducers = {
  appDocument,
  appEntry,
  appEvents,
  appHome,
  appUsers,
  appRequest,
  appSuggestion,
  appLeaderboard,
  appBCP,
  appVoes,
  user,
  dialog,
  knobs,
  modal,
  selection,
  toast
};

export default reducers;