import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import button from '../../styles/common/button';
const propTypes = {
  fontSize: PropTypes.number,
  lineHeight: PropTypes.string,
  bold: PropTypes.bool,
  textTransform: PropTypes.string,
  hasUnderline: PropTypes.bool,
  italic: PropTypes.bool,
  noDisabledColor: PropTypes.bool
};

const defaultProps = {
  fontSize: 14,
  lineHeight: '18px',
  bold: false,
  textTransform: 'initial',
  hasUnderline: false,
  italic: false,
  noDisabledColor: false
};

const FlatButton = styled('button')(
  {
    // fontWeight: 'bold',
    
    border: 0
  },
  ({
    theme,
    fontSize,
    lineHeight,
    bold,
    italic,
    textTransform,
    hasUnderline,
    isGray,
    noDisabledColor
  }) => ({
    lineHeight,
    textTransform,
    fontSize,
    fontStyle: italic ? 'italic' : 'normal',
    backgroundColor: theme.flatBtnBgColor,
    color: isGray ? theme.flatBtnGrayTextColor : theme.flatBtnTextColor,
    fontWeight: bold ? 'bold' : 'normal',
    textDecoration: hasUnderline ? 'underline' : 'none',

    '&:hover': {
      backgroundColor: theme.flatBtnHoverBgColor,
      color: isGray ? theme.flatBtnGrayHoverTextColor : theme.flatBtnHoverTextColor
    },

    '&:active': {
      backgroundColor: theme.flatBtnActiveBgColor,
      color: isGray ? theme.flatBtnGrayActiveTextColor : theme.flatBtnActiveTextColor
    },

    '&:disabled': {
      color: noDisabledColor ? theme.flatBtnTextColor : theme.flatBtnDisabledTextColor,
      pointerEvents: 'none'
    },

    '&:focus': {
      outline: 'none'
    },

    '>a' :{
      color: 'inherit !important'
    }
  }),
  button
);

export default FlatButton;
