import cookie from 'js-cookie';
import {
  JWT_TOKEN,
  USER_PROFILE,
  DOC_REQ_CARDS
} from '../constants/storage';
import {
  KNOX_ID,
  VALIDITY,
  HASHED
} from '../constants/appEntry';


export const removeAllStorage = () => {
  cookie.remove(JWT_TOKEN);
  cookie.remove(KNOX_ID);
  cookie.remove(VALIDITY);
  cookie.remove(HASHED);
  cookie.remove(DOC_REQ_CARDS);
  localStorage.removeItem(USER_PROFILE); // clear the profile
};
