import { CancelToken } from 'axios';

import config from '../../constants/serverConfig';
import api from '../../utils/api';

import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';
import {
  GET_DOCUMENT_LIST,
  UPLOAD_DOCUMENT,
  GET_MORE_DOCUMENT_LIST,
  DELETE_DOCUMENT,
  EDIT_DOCUMENT,
  CANCEL_FETCH_DOCUMENTS,
} from './index';

import isEmpty from '../../utils/isEmpty';
import { edit_options } from '../../constants/documentTypes';

let source = CancelToken.source();

export const actionUploadDocument = (Document, title = '', category = 'hp' ) => async dispatch => {

  try {
    dispatch({ type: UPLOAD_DOCUMENT + PENDING });

    let formData = new FormData();
    formData.append('pdfFile', Document);
    formData.append('category', category);
    formData.append('title', title);

    const { data } = await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/geninfos`,
      cancelToken: source.token,
      headers: {
        'Content-type': 'multipart/form-data'
      },
      data: formData
    });

    dispatch({
      type: UPLOAD_DOCUMENT + SUCCESS,
      payload: data.message
    });

  } catch (e) {
    dispatch({ type: UPLOAD_DOCUMENT + ERROR });
  }
}

export const actionEditDocument = (id = null, Document = null, title = '', category = 'hp' ) => async dispatch => {
  try {
    dispatch({ type: EDIT_DOCUMENT + PENDING });

    let formData = new FormData();
    if(!isEmpty(Document)) formData.append('pdfFile', Document);
    formData.append('category', category);
    formData.append('title', title);

    const { data } = await api({
      method: 'PUT',
      url: `${config.SERVER_BASE_URL}/geninfos/${id}`,
      cancelToken: source.token,
      headers: {
        'Content-type': 'multipart/form-data'
      },
      data: formData
    });

    const newData = {
      id: id,
      title: title,
      category: edit_options[category]
    }

    dispatch({
      type: EDIT_DOCUMENT + SUCCESS,
      payload: newData
    });

  } catch (e) {
    dispatch({ type: EDIT_DOCUMENT + ERROR, payload: e.message });
  }
}

export const actionFetchDocumentList = (fieldName = '', sort = -1, page = 1, title = '', rows = 20, category = '') => async dispatch => {
  try {

    dispatch({ type: GET_DOCUMENT_LIST + PENDING });
    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/geninfos?sort=${fieldName}&sortMethod=${sort}&page=${page}&title=${encodeURIComponent(title)}&rows=${rows}&category=${category}`,
      cancelToken: source.token
    });

    const { _metadata, generalInfos } = data.data;

    let total = 0;

    if(!isEmpty(_metadata)){
      total = _metadata.totalCount;
    }
    
    dispatch({ type: GET_DOCUMENT_LIST + SUCCESS, payload: { data: generalInfos, total } });
    

  } catch (e) {
    
    if(!e.isCancel){
      dispatch({ type: GET_DOCUMENT_LIST + ERROR, e });
    }
    
  }
};

export const actionFetchMoreDocumentList = (fieldName = '', sort = -1, page = 1, title = '', rows = 20, category = '') => async dispatch => {
  try {

    dispatch({ type: GET_MORE_DOCUMENT_LIST + PENDING });
    source = CancelToken.source();
    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/geninfos?sort=${fieldName}&sortMethod=${sort}&page=${page}&title=${encodeURIComponent(title)}&rows=${rows}&category=${category}`,
      cancelToken: source.token
    });

    const { generalInfos } = data.data;

  
    if (!isEmpty(generalInfos)){
      dispatch({ type: GET_MORE_DOCUMENT_LIST + SUCCESS, payload: { data: generalInfos } });
    } else {
      dispatch({ type: GET_MORE_DOCUMENT_LIST + ERROR });
    }

  } catch (e) {
    dispatch({ type: GET_MORE_DOCUMENT_LIST + ERROR, e });
  }
};

export const actionDeleteDocument = id => async dispatch => {
  try {
    dispatch({ type: DELETE_DOCUMENT + PENDING });
    source = CancelToken.source();

    const { data } = await api({
      method: 'DELETE',
      url: `${config.SERVER_BASE_URL}/geninfos`,
      data: {
        ids : [id]
      },
      cancelToken: source.token
    });

    dispatch({ type: DELETE_DOCUMENT + SUCCESS, payload: { data: id } });
    
  }catch (e) {
    dispatch({ type: DELETE_DOCUMENT + ERROR, e });
  }
}

export const actionCancelFetchDocuments = () => dispatch => {
  source.cancel();
  source = CancelToken.source();

  dispatch({
    type: CANCEL_FETCH_DOCUMENTS
  });
};

