import { CancelToken } from 'axios';

import config from '../../constants/serverConfig';
import api from '../../utils/api';

import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';
import {
  GET_ANNOUNCEMENT_LIST,
  FETCH_MORE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  GET_CALENDAR_EVENTS_LIST,
  GET_DEFAULT_BANNERS,
  DELETE_DEFAULT_BANNERS,
  UPLOAD_DEFAULT_BANNERS
} from './index';
import isEmpty from '../../utils/isEmpty';
import { async } from 'q';
let source = CancelToken.source();

const releaseTimeStamp = parseInt((Date.now()/1000), 10);

export const actionFetchAnnouncementList = (params={}, count = 20, offset = 0, type = '') => async dispatch => {
  try {

    dispatch({ type: GET_ANNOUNCEMENT_LIST + PENDING });
    source = CancelToken.source();
    const category = params.category ? params.category : '';
    const subCategory = params.subCategory ? params.subCategory : '';
    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/announcements?releaseTimeStamp=${releaseTimeStamp}&category=${category}&subcategory=${subCategory}&rows=${count}`,
      cancelToken: source.token,
      data: {
        type,
        count,
        offset
      }
    });
    
    if(!isEmpty(data.data.announcements)){
      const payload = {
        list : data.data.announcements,
        totalCount: data.data._metadata.totalCount
      }

      dispatch({ type: GET_ANNOUNCEMENT_LIST + SUCCESS, payload: { data: payload } });

    }

  } catch (e) {
    dispatch({ type: GET_ANNOUNCEMENT_LIST + ERROR, e });
  }
};

export const actionFetchMoreAnnouncements = (params={}, page, count=20) => async dispatch => {
  try {
    dispatch({ type: FETCH_MORE_ANNOUNCEMENT + PENDING });
    const category = params.category ? params.category : '';
    const subCategory = params.subCategory ? params.subCategory : '';
    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/announcements?releaseTimeStamp=${releaseTimeStamp}&category=${category}&subcategory=${subCategory}&rows=${count}&page=${page}`,
      cancelToken: source.token
    });
    
    if (!isEmpty(data.data.announcements)) {
      const payload = {
        list: data.data.announcements
      }
      console.log(payload)
      dispatch({ type: FETCH_MORE_ANNOUNCEMENT + SUCCESS, payload: { data: payload } });
    }
  } catch(e) {
    dispatch({ type: FETCH_MORE_ANNOUNCEMENT + ERROR, e });
    console.log(e)
  }
}

export const actionFetchCalendarEventsList = (month, year, count = 20, offset = 0) => async dispatch => {
  try{
    dispatch({ type: GET_CALENDAR_EVENTS_LIST + PENDING});
    source = CancelToken.source();

    if (!month || !year) {
      const currentDate = new Date();
      month = currentDate.getMonth();
      year = currentDate.getFullYear();
    }

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/events/calendar?year=${year}&month=${month}`,
      cancelToken: source.token,
      data: {
        count, offset
      }
    })
    
    if (!isEmpty(data.data.events)) {
      const payload = data.data.events
      console.log(payload)
      dispatch({ type: GET_CALENDAR_EVENTS_LIST + SUCCESS, payload: { data: payload } });

    } else {
      dispatch({ type: GET_CALENDAR_EVENTS_LIST + SUCCESS, payload: { data: [] } });

    }

  } catch (e) {
    dispatch({ type: GET_CALENDAR_EVENTS_LIST + ERROR, e});
  }
}

export const actionDeleteAnnouncement = (id, count = 20, offset = 0) => async dispatch => {
  try{
    // dispatch({ type: DELETE_ANNOUNCEMENT + PENDING});)

    dispatch({ type: DELETE_ANNOUNCEMENT + SUCCESS, payload: { id }})

  } catch (e) {
    // dispatch({ type: DELETE_ANNOUNCEMENT + ERROR, e});
  }
}

export const actionFetchBannersFeed = () => async dispatch => {
  try{
    dispatch({ type: GET_DEFAULT_BANNERS + PENDING});
    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/banners/feed`,
      cancelToken: source.token
    });
    
    if (!isEmpty(data.data)) {
      const payload = data.data.slice(0, 3);
      console.log(payload)
      dispatch({ type: GET_DEFAULT_BANNERS + SUCCESS, payload: { data: payload } });

    } else {
      dispatch({ type: GET_DEFAULT_BANNERS + SUCCESS, payload: { data: [] } });

    }

  } catch (e) {
    dispatch({ type: GET_DEFAULT_BANNERS + ERROR, e});
  }
}

export const actionDeleteDefaultFeed = key => async dispatch => {
  try{
    dispatch({ type: DELETE_DEFAULT_BANNERS + PENDING});
    source = CancelToken.source();

    const { data } = await api({
      method: 'DELETE',
      url: `${config.SERVER_BASE_URL}/banners/feed`,
      cancelToken: source.token,
      data: {
        filesToDelete: [key]
      }
    });
    

    dispatch({ type: DELETE_DEFAULT_BANNERS + SUCCESS, payload: { data: key } });


  } catch (e) {
    dispatch({ type: DELETE_DEFAULT_BANNERS + ERROR, e});
  }
}

export const actionUploadDefaultFeed = file => async dispatch => {
  try{
    dispatch({ type: UPLOAD_DEFAULT_BANNERS + PENDING});
    source = CancelToken.source();

    let formData = new FormData();
    formData.append('files', file);

    await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/banners/feed/upload`,
      cancelToken: source.token,
      headers: {
        'Content-type': 'multipart/form-data'
      },
      data: formData
    });
    
    dispatch(actionFetchBannersFeed());
    dispatch({ type: UPLOAD_DEFAULT_BANNERS + SUCCESS });


  } catch (e) {
    dispatch({ type: UPLOAD_DEFAULT_BANNERS + ERROR, e});
  }
}