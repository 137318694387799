import update from 'immutability-helper';
import {
  FETCH_LEADERBOARD,
  FETCH_MORE_LEADERBOARD,
  RESET_LEADERBOARD_BREAKDOWN,
  FETCH_LEADERBOARD_BREAKDOWN,
  CLAIM_LEADERBOARD,
  FETCH_EMPLOYEE_TOTAL_POINTS,
  UPLOAD_REWARDS_BANNER
} from './index';
import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

const initialState = {
  leaderboardList: [],
  pointsBreakDown: [],
  employeePoints: 0,

  isLeaderBoardFetchPending: false,
  isLeaderBoardFetchError: false,
  isLeaderBoardFetchMorePending: false,
  isLeaderBoardFetchMoreError: false,

  isPointsBreakdownFetchPending: false,
  isPointsBreakdownFetchError: false,
  isClaimPending: false,
  isClaimSuccess: false,
  isClaimError: false,

  isEmployeeTotalPointsPending: false,
  isEmployeeTotalPointsError: false,

  isUploadPending: false,
  isUploadError: false,
}

const leaderboard = (state = initialState, action = {}) => {
  switch(action.type) {
  case FETCH_LEADERBOARD + PENDING:
    return {
      ...state,
      isLeaderBoardFetchPending: true,
      isLeaderBoardFetchError: false
    }
  case FETCH_LEADERBOARD + SUCCESS:
    return {
      ...state,
      isLeaderBoardFetchPending: false,
      isLeaderBoardFetchError: false,
      leaderboardList: action.payload.leaderboardList
    }
  case FETCH_LEADERBOARD + ERROR:
    return {
      ...state,
      isLeaderBoardFetchPending: false,
      isLeaderBoardFetchError: true
    }
  case FETCH_MORE_LEADERBOARD + PENDING:
    return {
      ...state,
      isLeaderBoardFetchMorePending: true,
      isLeaderBoardFetchMoreError: false
    }
  case FETCH_MORE_LEADERBOARD + SUCCESS:
    if(action.payload.isFirst){
      return {
        ...state,
        isLeaderBoardFetchMorePending: false,
        isLeaderBoardFetchMoreError: false,
        leaderboardList: action.payload.leaderboardList
      }
    }

    return update(
      state,
      {
        leaderboardList: { $push: action.payload.leaderboardList },
        isLeaderBoardFetchMorePending: { $set: false },
        isLeaderBoardFetchMoreError: { $set: false }
      }
    );
  case FETCH_MORE_LEADERBOARD + ERROR:
    return {
      ...state,
      isLeaderBoardFetchMorePending: false,
      isLeaderBoardFetchMoreError: true
    }
  case FETCH_LEADERBOARD_BREAKDOWN + PENDING:
    return {
      ...state,
      isPointsBreakdownFetchPending: true,
      isPointsBreakdownFetchError: false
    }
  case FETCH_LEADERBOARD_BREAKDOWN + SUCCESS:
    return {
      ...state,
      isPointsBreakdownFetchPending: false,
      isPointsBreakdownFetchError: false,
      pointsBreakDown: action.payload.list
    }
  case FETCH_LEADERBOARD_BREAKDOWN + ERROR:
    return {
      ...state,
      isPointsBreakdownFetchPending: false,
      isPointsBreakdownFetchError: true
    }
  case RESET_LEADERBOARD_BREAKDOWN:
    return {
      ...state,
      pointsBreakDown: initialState.pointsBreakDown,
      isPointsBreakdownFetchPending: false,
      isPointsBreakdownFetchError: false
    }
  case CLAIM_LEADERBOARD + PENDING:
    return {
      ...state,
      isClaimPending: true,
      isClaimSuccess: false,
      isClaimError: false
    }
  case CLAIM_LEADERBOARD + SUCCESS:
    return {
      ...state,
      isClaimPending: false,
      isClaimSuccess: true,
      isClaimError: false
    }
  case CLAIM_LEADERBOARD + ERROR:
    return {
      ...state,
      isClaimPending: false,
      isClaimSuccess: false,
      isClaimError: true
    }
  case FETCH_EMPLOYEE_TOTAL_POINTS + PENDING:
    return {
      ...state,
      isEmployeeTotalPointsPending: true,
      isEmployeeTotalPointsError: false
    }
  case FETCH_EMPLOYEE_TOTAL_POINTS + SUCCESS:
    return {
      ...state,
      isEmployeeTotalPointsPending: false,
      isEmployeeTotalPointsError: false,
      employeePoints: action.payload.data
    }
  case FETCH_EMPLOYEE_TOTAL_POINTS + ERROR:
    return {
      ...state,
      isEmployeeTotalPointsPending: false,
      isEmployeeTotalPointsError: true
    }
  case UPLOAD_REWARDS_BANNER + PENDING:
    return {
      ...state,
      isUploadPending: true,
      isUploadError: false,
    }
  case UPLOAD_REWARDS_BANNER + SUCCESS:
    return {
      ...state,
      isUploadPending: false,
      isUploadError: false,
    }
  case UPLOAD_REWARDS_BANNER + ERROR:
    return {
      ...state,
      isUploadPending: false,
      isUploadError: true,
    }
  default:
    return state;
  }
}

export default leaderboard;
