import update from 'immutability-helper';
import { uniqBy } from 'lodash';
import {
  GET_ANNOUNCEMENT_LIST,
  FETCH_MORE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  GET_CALENDAR_EVENTS_LIST,
  GET_DEFAULT_BANNERS,
  DELETE_DEFAULT_BANNERS,
  UPLOAD_DEFAULT_BANNERS
} from './index';

import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

const initialState = {
  list: [],
  listTotalCount: 0,
  isFetchListPending: false,
  isFetchListError: false,
  calendarEvents: [],
  defaultBanners: [],
  isFetchCalendarEventsPending: false,
  isFetchCalendarEventsError: false,
  isFetchMoreListPending: false,
  isFetchMoreListError: false,
  isFetchDefaultBannersPending: false,
  isFetchDefaultBannersError: false,
};

const home = (state = initialState, action = {}) => {
  switch (action.type) {
  case GET_ANNOUNCEMENT_LIST + PENDING:
    return {
      ...state,
      isFetchListPending: true
    };
  case GET_ANNOUNCEMENT_LIST + SUCCESS:
    return {
      ...state,
      isFetchListPending: false,
      list: action.payload.data.list,
      listTotalCount: action.payload.data.totalCount
    };
  case GET_ANNOUNCEMENT_LIST + ERROR:
    return {
      ...state,
      isFetchListError: true
    };
  case FETCH_MORE_ANNOUNCEMENT + PENDING:
    return {
      ...state,
      isFetchMoreListPending: true
    };
  case FETCH_MORE_ANNOUNCEMENT + SUCCESS:
    return update(
      state,
      {
        list: { $push: action.payload.data.list },
        isFetchMoreListPending: { $set: false }
      }
    )
  case FETCH_MORE_ANNOUNCEMENT + ERROR:
    return {
      ...state,
      isFetchMoreListError: true
    };
  case DELETE_ANNOUNCEMENT + PENDING:
    //Soon
    return {
      ...state,
    };
  case DELETE_ANNOUNCEMENT + SUCCESS:
    return {
      ...state,
      list: state.list.filter(function( item ) { return item.id !== action.payload.id;})
    };
  case DELETE_ANNOUNCEMENT + ERROR:
    //Soon
    return {
      ...state,
    };
  case GET_CALENDAR_EVENTS_LIST + PENDING:
    return {
      ...state,
      isFetchCalendarEventsPending: true
    }
  case GET_CALENDAR_EVENTS_LIST + SUCCESS: 
    let events = [...state.calendarEvents, ...action.payload.data]
    events = uniqBy(events, 'id')
    return {
      ...state,
      isFetchCalendarEventsPending: false,
      calendarEvents: events    
    }
  case GET_CALENDAR_EVENTS_LIST + ERROR: 
    return {
      ...state,
      isFetchCalendarEventsError: true
    }
  case GET_DEFAULT_BANNERS + PENDING: {
    return {
      ...state,
      isFetchDefaultBannersPending: true,
      isFetchDefaultBannersError: false
    }
  }
  case GET_DEFAULT_BANNERS + SUCCESS: {
    return {
      ...state,
      defaultBanners: action.payload.data,
      isFetchDefaultBannersPending: false,
      isFetchDefaultBannersError: false
    }
  }
  case GET_DEFAULT_BANNERS + ERROR: {
    return {
      ...state,
      isFetchDefaultBannersPending: false,
      isFetchDefaultBannersError: true
    }
  }
  case DELETE_DEFAULT_BANNERS + SUCCESS: {
    let newState = state.defaultBanners;
    const i = newState.findIndex(banner => banner === action.payload.data);

    if (i >= 0) {
      newState = [
        ...newState.slice(0, i),
        ...newState.slice(i + 1)
      ];
    }

    return update(
      state, {
        defaultBanners: {
          $set: newState
        }
      }
    );
  }
  default:
    return state;
  }
};

export default home;
