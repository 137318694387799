// import Reducers
import suggestion from './suggestion';

// export Constants
export const GET_SUGGESTIONS_LIST = '[SUGGESTION] GET_SUGGESTIONS_LIST';
export const GET_MORE_SUGGESTIONS_LIST = '[SUGGESTION] GET_MORE_SUGGESTIONS_LIST';
export const CANCEL_FETCH_SUGGESTIONS = '[SUGGESTION] CANCEL_FETCH_SUGGESTIONS';
export const REPLY_SUGGESTION = '[SUGGESTION] REPLY_SUGGESTION';
export const SET_FILTER_START_DATE = '[SUGGESTION] SET_FILTER_START_DATE';
export const SET_FILTER_END_DATE = '[SUGGESTION] SET_FILTER_END_DATE';
export const RESET_FILTERS = '[SUGGESTION] RESET_FILTERS';
export const SET_FILTER_SEARCH = '[SUGGESTION] SET_FILTER_SEARCH';
// export Actions
export {
  actionFetchSuggestionsList,
  actionFetchMoreSuggestionsList,
  actionSendSuggestionReply,
  actionCancelFetchSuggestions,
  actionExportCSV,
  actionSetFilterStartDate,
  actionSetFilterEndDate,
  actionResetFilters,
  actionSetFilterSearch
} from './suggestionActions';

// export Reducers
export default suggestion;