import update from 'immutability-helper';

import {
  GET_DOCUMENT_LIST,
  UPLOAD_DOCUMENT,
  GET_MORE_DOCUMENT_LIST,
  DELETE_DOCUMENT,
  EDIT_DOCUMENT
} from './index';

import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

const initialState = {
  list: [],
  totalList: 0,

  isFetchListPending: false,
  isFetchListError: false,
  isFetchMoreListPending: false,
  isFetchMoreListError: false,
  isUploadDocumentPending: false,
  isUploadDocumentError: false,
  isUploadDocumentSuccess: false,
  isEditDocumentPending: false,
  isEditDocumentError: false,
  isEditDocumentSuccess: false,
  uploadResult: '',
  editErrorMessage: ''
};

const document = (state = initialState, action = {}) => {
  switch (action.type) {
  case GET_DOCUMENT_LIST + PENDING:
    return {
      ...state,
      isFetchListPending: true,
      isFetchListError: false,
    };
  case GET_DOCUMENT_LIST + SUCCESS:
    return {
      ...state,
      isFetchListPending: false,
      isFetchListError: false,
      list: action.payload.data,
      totalList: action.payload.total
    };
  case GET_DOCUMENT_LIST + ERROR:
    return {
      ...state,
      isFetchListError: true,
      isFetchListPending: false,
    };
  case GET_MORE_DOCUMENT_LIST + PENDING:
    return {
      ...state,
      isFetchMoreListPending: true,
      isFetchMoreListError: false,
    };
  case GET_MORE_DOCUMENT_LIST + SUCCESS:
    return update(
      state,
      {
        list: { $push: action.payload.data },
        isFetchMoreListPending: { $set: false },
        isFetchMoreListError: { $set: false }
      }
    );
  case GET_MORE_DOCUMENT_LIST + ERROR:
    return {
      ...state,
      isFetchMoreListPending: false,
      isFetchMoreListError: true,
    };
  case UPLOAD_DOCUMENT + PENDING:
    return {
      ...state,
      isUploadDocumentPending: true,
      isUploadDocumentError: false,
      isUploadDocumentSuccess: false
    };
  case UPLOAD_DOCUMENT + SUCCESS:
    return {
      ...state,
      isUploadDocumentPending: false,
      isUploadDocumentError: false,
      isUploadDocumentSuccess: true,
      uploadResult: action.payload
    };
  case UPLOAD_DOCUMENT + ERROR:
    return {
      ...state,
      isUploadDocumentPending: false,
      isUploadDocumentError: true,
      isUploadDocumentSuccess: false,
      uploadResult: action.payload
    };

  case EDIT_DOCUMENT + PENDING:
    return {
      ...state,
      isEditDocumentPending: true,
      isEditDocumentError: false,
      isEditDocumentSuccess: false,
    };
  case EDIT_DOCUMENT + ERROR:
    return {
      ...state,
      isEditDocumentPending: false,
      isEditDocumentError: true,
      isEditDocumentSuccess: false,
      editErrorMessage: action.payload
    };
  case EDIT_DOCUMENT + SUCCESS:
    let updatedList = state.list;
    const x = updatedList.findIndex(document => document.id === action.payload.id);

    if (x >= 0) {
      updatedList[x].title = action.payload.title
      updatedList[x].category = action.payload.category
    }

    return update(
      state, {
        list: {
          $set: updatedList
        },
        isEditDocumentPending: { $set: false },
        isEditDocumentError: { $set: false },
        isEditDocumentSuccess: { $set: true },
      }
    )

  case DELETE_DOCUMENT + SUCCESS: 
    let newState = state.list;
    const i = newState.findIndex(document => document.id === action.payload.data);

    if (i >= 0) {
      newState = [
        ...newState.slice(0, i),
        ...newState.slice(i + 1)
      ];
    }

    return update(
      state, {
        list: {
          $set: newState
        }
      }
    );

  default:
    return state;

  }


};




export default document;
