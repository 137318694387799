import user from './user';

export const TOGGLE_USER_DRAWER = '[USER] TOGGLE_USER_DRAWER';
export const UPDATE_USER = '[USER] UPDATE_USER';
export const LOGOUT_USER = '[USER] LOGOUT_USER';

export {
  actionToggleUserDrawer,
  actionUpdateUser,
  actionLogout
} from './userActions';

export default user;
