import bcp from './bcp';

export const SET_ACTIVE_ALERT = '[BCP] SET_ACTIVE_ALERT';
export const RESET_ACTIVE_ALERT = '[BCP] RESET_ACTIVE_ALERT';
export const GET_BCP_LIST = '[BCP] GET BCP_LIST';
export const GET_EMPLOYEE_LIST = '[BCP] GET_EMPLOYEE_LIST';
export const POST_BCP_ALERT = '[BCP] POST_BCP_ALERT';
export const END_BCP_ALERT = '[BCP] END_BCP_ALERT';
export const RESEND_BCP_ALERT = '[BCP] RESEND_BCP_ALERT';
export const EXPORT_EVENT_CSV = '[BCP] EXPORT_EVENT_CSV';

export { 
  actionSetActiveAlert,
  actionResetActiveAlert,
  actionGetBcpList,
  actionPostBcpAlert,
  actionGetEmployeeList,
  actionEndBcpAlert,
  actionResendBcpAlert,
  actionExportCSV
} from './bcpActions';

export default bcp;