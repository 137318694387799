import React, { useState, useEffect , Fragment} from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import CardForm from '../components/CardForm';
import * as Yup from 'yup';
import Link from 'redux-first-router-link'

import { Formik, Form, Field } from 'formik';

import Input from '../components/Input';
import Text from '../components/Text';
import FillButton from '../components/FillButton';
import SvgIcon from '../components/SvgIcon';
import FlatButton from '../components/FlatButton';

import passwordIcon from '../../assets/icons/password.svg';
import lockLogo from '../../assets/icons/lockLogo.svg';

// actions
import { actionResetPassword } from '../../reduxModules/appEntry'

const Card = styled(CardForm)({
  height: 595,
  width: 484,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  boxShadow: 'none',
  borderRadius: 26,
})

const ResetPasswordCardContents = styled('div')({
  padding: '60px 62px'
})

const HeaderContainer = styled('div')({
  padding: '0 62px 41px 62px',
  textAlign: 'center'
});


const HeaderTitle = styled(Text)(({ theme }) => ({
  fontSize: 24,
  lineHeight: '29px',
  color: theme.appEntryTitleTextColor,
  marginBottom: 13,
  display: 'block'
}));

const HeaderDescription = styled('p')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '17px',
  color: theme.appEntryTitleTextColor,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
}));

const SubmitButton = styled(FillButton)({
  width: '100%',
  height: 40,
  borderRadius: 20,
  fontSize: 16,
});

const MessageContainer = styled('div')({
  textAlign: 'center',
  margin: '0 0 16px 0'

});

const SuccessText = styled(Text)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  color: '#222222'
}));

const ErrorText = styled(Text)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  color: theme.errorTextColor,
}));

const FieldsContainer = styled('div')({
  '>*:not(:last-child)': {
    marginBottom: 15,
    width: '360px'
  }
});

const SubmitButtonHolder = styled('div')({
  paddingTop: '45px'
})

const BackToSignIn = styled(FlatButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '14',
  lineHeight: '17px',
  width: '100%',
  paddingTop: 38,
  color: '##19B9A9'
}));

const ResetPassword = ({
  isResetPasswordSuccess,
  isResetPasswordError,
  resetPassword,
  actionResetPassword,
  resetPasswordErrorMessage
}) => {

  const schema = Yup.object().shape({
    new_password: Yup.string().trim().required('Knox ID is Required'),
    confirm_password: Yup.string().trim().required('Knox ID is Required')
  });

  let [mismatchPassword, setMismatchPassword] = useState(false);

  const handleSubmitResetPassword = (e) => {
    if (e.new_password !== e.confirm_password) {
      setMismatchPassword(true);
    
    } else {
      setMismatchPassword(false);
      actionResetPassword(e)
      

    }
  }


  return (
    <Fragment>
      <Card>
        <ResetPasswordCardContents>
          <HeaderContainer>
            <SvgIcon size={140} symbol={lockLogo} />
          </HeaderContainer>
          <HeaderTitle bold>Reset my password</HeaderTitle>

          {
            isResetPasswordError || mismatchPassword ?
              
              <MessageContainer>

                { mismatchPassword ?

                  <ErrorText> Your passwords didn’t match, please try again. </ErrorText>
                  : 
                  resetPasswordErrorMessage === 'Your password should have at least an uppercase and lowercase character, one number, a special character, and greater than or equal to 8 characters.' ?
                    <ErrorText>Your password must be at least 8 characters with at least 1 uppercase, 1 lowercase, 1 number and 1 special character.</ErrorText> : 
                    
                    
                    resetPasswordErrorMessage === 'Your new password cannot be the same as the old one.' ?
                      <ErrorText>Your password shouldn’t be the same as the old one,
please try again.</ErrorText>
                      :
                      <ErrorText>{resetPasswordErrorMessage}</ErrorText>
                }
              </MessageContainer>
              
            
              :


              isResetPasswordSuccess
                ? null 
                :
                <HeaderDescription>
            Please choose a strong password and don’t use it for other accounts.
                </HeaderDescription>
          }

          {
            isResetPasswordSuccess
              ? <MessageContainer>
                <SuccessText>You have successfully reset your password!</SuccessText>
              </MessageContainer> : null
          }
         
          {isResetPasswordSuccess
            ? null 
            :
            <Formik
              initialValues={{
                new_password: '',
                confirm_password: ''
              }}
              validationSchema={schema}
              onSubmit={e => {
                handleSubmitResetPassword(e)
              }}
            >
              <Form>

                <FieldsContainer>
                  <Field
                    name={`new_password`}
                    placeholder="New Password"
                    component={Input}
                    height={40}
                    styleType={2}
                    hasIcon icon={passwordIcon}
                    type="password"
                  />

                  <Field
                    name={`confirm_password`}
                    placeholder="Confirm Password"
                    component={Input}
                    height={40}
                    styleType={2}
                    hasIcon icon={passwordIcon}
                    type="password"
                  />


                  <SubmitButtonHolder>
                    <SubmitButton type="submit">RESET PASSWORD</SubmitButton>
                  </SubmitButtonHolder>
                </FieldsContainer>
              </Form>
            </Formik>
          }

        </ResetPasswordCardContents>
      </Card>


      <BackToSignIn hasUnderline bold><Link to='/login'>Back to sign-in</Link></BackToSignIn>
    </Fragment>
  )
};


export default connect(state => ({
  isResetPasswordSuccess: state.appEntry.isResetPasswordSuccess,
  isResetPasswordError: state.appEntry.isResetPasswordError,
  resetPassword: state.appEntry.resetPassword,
  resetPasswordErrorMessage: state.appEntry.resetPasswordErrorMessage
}), {
  actionResetPassword
})(ResetPassword);