import { CancelToken } from 'axios';
import moment from 'moment';
import { saveAs } from 'file-saver'; 

import config from '../../constants/serverConfig';
import api from '../../utils/api';

import {
  GET_SUGGESTIONS_LIST,
  GET_MORE_SUGGESTIONS_LIST,
  CANCEL_FETCH_SUGGESTIONS,
  REPLY_SUGGESTION,
  SET_FILTER_START_DATE,
  SET_FILTER_END_DATE,
  RESET_FILTERS,
  SET_FILTER_SEARCH
} from './index'

import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

import isEmpty from '../../utils/isEmpty';

let source = CancelToken.source();

export const actionSendSuggestionReply = ( id = 1, reply = '') => async dispatch => {
  try {
    dispatch({ type: REPLY_SUGGESTION + PENDING });

    const { data } = await api({
      method: 'PUT',
      url: `${config.SERVER_BASE_URL}/suggestions/reply`,
      cancelToken: source.token,
      data: {
        _id: id,
        reply
      }
    });

    dispatch({
      type: REPLY_SUGGESTION + SUCCESS,
      payload: data.message
    });

  } catch (e) {
    dispatch({ type: REPLY_SUGGESTION + ERROR });
  }
}

export const actionFetchSuggestionsList = (page = 1, rows = 20) => async (dispatch, getState) => {
  try {

    dispatch({ type: GET_SUGGESTIONS_LIST + PENDING });

    const { appSuggestion: { filters: { startDate, endDate, search } }} = getState();

    const fromMonth = moment(startDate).format('MM');
    const fromYear = moment(startDate).format('YYYY');
    const toMonth = moment(endDate).format('MM');
    const toYear = moment(endDate).format('YYYY');

    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/suggestions?page=${page}&rows=${rows}&fromMonth=${fromMonth}&fromYear=${fromYear}&toMonth=${toMonth}&toYear=${toYear}&search=${encodeURIComponent(search)}`,
      cancelToken: source.token
    });

    const { _metadata, suggestions } = data.data;

    let total = 0;

    if(!isEmpty(_metadata)){
      total = _metadata.totalCount;
    }
    
    dispatch({ type: GET_SUGGESTIONS_LIST + SUCCESS, payload: { data: suggestions, total } });
    

  } catch (e) {
    
    if(!e.isCancel){
      dispatch({ type: GET_SUGGESTIONS_LIST + ERROR, e });
    }
    
  }
};


export const actionFetchMoreSuggestionsList = (page = 1, rows = 20) => async (dispatch, getState) => {
  try {

    dispatch({ type: GET_MORE_SUGGESTIONS_LIST + PENDING });

    const { appSuggestion: { filters: { startDate, endDate, search } }} = getState();

    const fromMonth = moment(startDate).format('MM');
    const fromYear = moment(startDate).format('YYYY');
    const toMonth = moment(endDate).format('MM');
    const toYear = moment(endDate).format('YYYY');

    source = CancelToken.source();

    const { data } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/suggestions?page=${page}&rows=${rows}&fromMonth=${fromMonth}&fromYear=${fromYear}&toMonth=${toMonth}&toYear=${toYear}&search=${encodeURIComponent(search)}`,
      cancelToken: source.token
    });

    const { suggestions } = data.data;

    dispatch({ type: GET_MORE_SUGGESTIONS_LIST + SUCCESS, payload: { data: suggestions } });

  } catch (e) {
    
    if(!e.isCancel){
      dispatch({ type: GET_MORE_SUGGESTIONS_LIST + ERROR, e });
    }
    
  }
};

export const actionExportCSV = () => async (dispatch, getState) => {

  const { appSuggestion: { filters: { startDate, endDate, search } }} = getState();

  const fromMonth = moment(startDate).format('MM');
  const fromYear = moment(startDate).format('YYYY');
  const toMonth = moment(endDate).format('MM');
  const toYear = moment(endDate).format('YYYY');
  
  const { data } = await api({
    method: 'GET',
    url: `${config.SERVER_BASE_URL}/suggestions/export?fromMonth=${fromMonth}&fromYear=${fromYear}&toMonth=${toMonth}&toYear=${toYear}&search=${encodeURIComponent(search)}`,
    cancelToken: source.token
  });
  const  csvFile  = data.data;  

  if (!isEmpty(csvFile)) {
    //dispatch({ type: EXPORT_ATTENDEE_LIST + SUCCESS, payload: { data: csvFile} });
    saveAs(csvFile, csvFile);
  }
}

export const actionSetFilterStartDate = date => ({
  type: SET_FILTER_START_DATE,
  payload: date
})

export const actionSetFilterEndDate = date => ({
  type: SET_FILTER_END_DATE,
  payload: date
})

export const actionResetFilters = () => ({
  type: RESET_FILTERS
})

export const actionSetFilterSearch = keyword => ({
  type: SET_FILTER_SEARCH,
  payload: keyword
})


export const actionCancelFetchSuggestions = () => dispatch => {
  source.cancel();
  source = CancelToken.source();

  dispatch({
    type: CANCEL_FETCH_SUGGESTIONS
  });
};
