import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import CardForm from '../components/CardForm';
import * as Yup from 'yup';

import { Formik, Form, Field } from 'formik';


import Input from '../components/Input';
import Text from '../components/Text';
import FillButton from '../components/FillButton';
import SvgIcon from '../components/SvgIcon';

import passwordIcon from '../../assets/icons/password.svg';
import lockLogo from '../../assets/icons/lockLogo.svg';

// actions
import { actionChangePassword } from '../../reduxModules/appUsers';
import Card from '../components/Card';

const ChangePasswordCard = styled(CardForm)({
  boxShadow: 'none',
  borderRadius: 26,
  width: 484,
  height: 650,
  margin: '60px auto',
});

const CPContentsContainer = styled('div')({
  position: 'relative',
  padding: '60px 62px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  height: '100%',
  width: '100%'
})

const CPHeaderContainer = styled('div')({
  position: 'relative',
  paddingBottom: '36px',
});

const CPHeaderTitle = styled(Text)(({ theme }) => ({
  fontSize: 24,
  lineHeight: '29px',
  fontWeight: 'bold',
  color: theme.appEntryTitleTextColor,
  margin: '0 0 13px 0',
  display: 'block',
  paddingBottom: 12,
  textAlign: 'center'
}));

const CPHeaderMessage = styled(Text)({
  position: 'relative',
  fontSize: 14,
  lineHeight: '17px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  paddingBottom: 24,
  wordWrap: 'break-word',
  width: 225
})

const ChangePasswordSubmitButton = styled(FillButton)({
  width: 360,
  height: 41,
  borderRadius: 20, 
  fontSize: 16,
  margin: '45px 62px 0 62px'
});

const MessageContainer = styled('div')({
  textAlign: 'center',
  margin: '0 0 16px 0',
  width: 360
});

const SuccessText = styled(Text)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '14px',
  color: theme.primaryColor,
}));

const ErrorText = styled(Text)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  color: theme.errorTextColor,
}));

const FieldsContainer = styled('div')({
  '>*:not(:last-child)': {
    marginBottom: 15,
    marginLeft: 62,
    marginRight: 62,
    width: '360px'
  }
});
const ChangePassword = ({
  profile,

  isChangePasswordPending,
  isChangePasswordError,
  isChangePasswordSuccess,
  changePassword,
  actionChangePassword,
  changePasswordErrorMessage
}) => {

  const schema = Yup.object().shape({
    old_password: Yup.string().required('Current password is required.'),
    new_password: Yup.string().required('New password is required.'),
    confirm_password: Yup.string().required('Confirm password is required.')
  })

  let [mismatchPassword, setMismatchPassword] = useState(false);

  const handleSubmitChangePassword = (e) => {
    if (e.new_password !== e.confirm_password) {
      setMismatchPassword(true);
    } else {
      setMismatchPassword(false);
      actionChangePassword(e)

    }
  }

 
  return (
 
    <ChangePasswordCard >
      <CPContentsContainer>
    
        <CPHeaderContainer>
          <SvgIcon size={140} symbol={lockLogo} />
        </CPHeaderContainer>

        <CPHeaderTitle>Change my password</CPHeaderTitle>
       
        {
          isChangePasswordError || mismatchPassword ?
            <MessageContainer>


              { mismatchPassword ?

                <ErrorText> Your passwords didn’t match, please try again. </ErrorText> 
                : 
                
                changePasswordErrorMessage === 'Your password should have at least an uppercase and lowercase character, one number, a special character, and greater than or equal to 8 characters.'? 
                  <ErrorText>Your password must be at least 8 characters with at least 1 uppercase, 1 lowercase, 1 number and 1 special character. </ErrorText> :



                  changePasswordErrorMessage === 'Your new password cannot be the same as the old one.' ?
                    <ErrorText>Your password shouldn’t be the same as the old one,
please try again.</ErrorText>:

       
                    <ErrorText>{changePasswordErrorMessage === 'Sorry, the current password you entered is incorrect.' ?
                      <ErrorText>Your current password is incorrect, please try again.</ErrorText>
                  
                      : <ErrorText>{changePasswordErrorMessage} </ErrorText>
                  
                    }</ErrorText>
              }
            </MessageContainer>
            
            :


            <CPHeaderMessage>
          Please choose a strong password and 
don’t use it for other accounts.
            </CPHeaderMessage>
        }


        {
          isChangePasswordSuccess
            ? <MessageContainer>
              <SuccessText>You have successfully changed your password!</SuccessText>
            </MessageContainer> : null
        }

        {
          isChangePasswordPending
            ? <MessageContainer>
              <ErrorText>Pending</ErrorText>
            </MessageContainer> : null
        }

        <Formik
          initialValues={{
            knox_id: profile.knoxId,
            old_password: '',
            new_password: '',
            confirm_password: ''
          }}
          validationSchema={schema}
          onSubmit={e => {
            handleSubmitChangePassword(e);
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >

          <Form>
            <FieldsContainer>
           
              <Field
                name={`old_password`}
                placeholder="Current password"
                component={Input}
                height={40}
                styleType={2}
                hasIcon icon={passwordIcon}
                type="password"
              />

              <Field
                name={`new_password`}
                placeholder="New password"
                component={Input}
                height={40}
                styleType={2}
                hasIcon icon={passwordIcon}
                type="password"
              />

              <Field
                name={`confirm_password`}
                placeholder="Confirm password"
                component={Input}
                height={40}
                styleType={2}
                hasIcon icon={passwordIcon}
                type="password"
              />
              
              <ChangePasswordSubmitButton type="submit">CHANGE PASSWORD</ChangePasswordSubmitButton>
            </FieldsContainer>
          </Form>
        </Formik> 
      </CPContentsContainer>
    </ChangePasswordCard>
  

  )
};


export default connect(state => ({

  // isChangePasswordError: state.appProfile.isChangePasswordError,
  // isChangePasswordSuccess: state.appProfile.isChangePasswordSuccess,
  // changePassword: state.appProfile.changePassword

  profile: state.user.profile,
  isChangePasswordError: state.appUsers.isChangePasswordError,
  isChangePasswordSuccess: state.appUsers.isChangePasswordSuccess,
  changePassword: state.appUsers.changePassword,
  changePasswordErrorMessage: state.appUsers.changePasswordErrorMessage
}), { actionChangePassword })(ChangePassword);