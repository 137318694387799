// import Reducers
import events from './events';

// export Constants
export const SET_ACTIVE_EVENT = '[EVENTS] SET_ACTIVE_EVENT';
export const RESET_ACTIVE_EVENT = '[EVENTS] RESET_ACTIVE_EVENT';
export const SET_ACTIVE_EVENT_SESSION = '[EVENTS] SET_ACTIVE_EVENT_SESSION';
export const RESET_ACTIVE_EVENT_SESSION = '[EVENTS] RESET_ACTIVE_EVENT_SESSION';
export const GET_EVENTS_LIST = '[EVENTS] GET_EVENTS_LIST';
export const GET_MORE_EVENTS_LIST = '[EVENTS] GET_MORE_EVENTS_LIST';
export const GET_EVENT_DETAILS = '[EVENTS] GET_EVENT_DETAILS';
export const OPEN_NEW_EVENT_MODAL = '[EVENTS] OPEN_NEW_EVENT_MODAL';
export const OPEN_UPDATE_EVENT_MODAL = '[EVENTS] OPEN_UPDATE_EVENT_MODAL';
export const CLOSE_NEW_EVENT_MODAL = '[EVENTS] CLOSE_NEW_EVENT_MODAL';
export const POST_EVENT = '[EVENTS] POST_EVENT';
export const PUT_EVENT = '[EVENTS] PUT_EVENT';
export const DELETE_EVENT = '[EVENTS] DELETE_EVENT';
export const SEARCH_TEAM_EMPLOYEE = '[EVENTS] SEARCH_TEAM_EMPLOYEE';
export const CANCEL_FETCH_EVENTS = '[EVENTS] CANCEL_FETCH_EVENTS';
export const FETCH_ATTENDEES = '[EVENTS] FETCH_ATTENDEES';
export const FETCH_ATTENDED_LIST = '[EVENTS] FETCH_ATTENDED_LIST';
export const FETCH_MORE_ATTENDED_LIST = '[EVENTS] FETCH_MORE_ATTENDED_LIST';
export const FETCH_MORE_ATTENDEES = '[EVENTS] FETCH_MORE_ATTENDEES';
export const DELETE_SEARCH_EMPLOYEE = '[EVENTS] DELETE_SEARCH_EMPLOYEE';
export const DELETE_SEARCH_TEAM = '[EVENTS] DELETE_SEARCH_TEAM';
export const SET_EVENT_GRID_VIEW = '[EVENTS] SET EVENT GRID VIEW';
export const EXPORT_ATTENDEE_LIST = '[EVENTS] EXPORT ATTENDEE LIST';
export const EXPORT_ATTENDED_LIST = '[EVENTS] EXPORT ATTENDED LIST';
export const FETCH_COMMITMENTS_BY_TEAM_PER_SESSION = '[EVENTS] FETCH COMMITMENTS BY TEAM_PER_SESSION'
export const FETCH_DEFAULT_BANNER = '[EVENTS] FETCH_DEFAULT_BANNER'
export const IS_FEATURE = '[EVENTS] IS_FEATURE';
export const EXPORT_EVENT_CSV = '[EVENTS] EXPORT_EVENT_CSV';
export const VERIFY_EVENT = '[EVENTS] VERIFY_EVENT';
export const RESET_ATTENDEE_LIST = '[EVENTS] RESET_ATTENDEE_LIST';


// export Actions
export {
  actionSetActiveEvent,
  actionResetActiveEvent,
  actionSetActiveEventSession,
  actionResetActiveEventSession,
  actionFetchEvents,
  actionFetchMoreEvents,
  actionFetchEventDetails,
  actionOpenNewEventModal,
  actionCloseNewEventModal,
  actionSubmitNewEvent,
  actionSubmitUpdateEvent,
  actionDeleteEventCard,
  actionFetchTeamAndEmployee,
  actionCancelFetchEvents,
  actionCancelFetchTeamAndEmployee,
  actionFetchAttendees,
  actionFetchAttendedList,
  actionFetchMoreAttendedList,
  actionFetchMoreAttendees,
  actionDeleteEmployeeSearch,
  actionDeleteTeamSearch,
  actionSetEventGridView,
  actionExportAttendeeListCSV,
  actionExportAttendedListCSV,
  actionGetDefaultBanner,
  actionSetIsFeature,
  actionFetchCommitmentsByTeamPerSession,
  actionExportCSV,
  actionVerifyEvent,
  actionResetVerify,
  actionResetAttendeeList
} from './eventsActions';

// export Reducers
export default events;
