// import Reducers
import voes from "./voes";

// export Constants
export const GET_VOES_LIST = "[VOES] GET_VOES_LIST";
export const GET_MORE_VOES_LIST = "[VOES] GET_MORE_VOES_LIST";
export const UPDATE_READ_UNREAD = "[VOES] UPDATE_READ_UNREAD";
export const GET_VOE_DELEGATE = '[VOES] GET_VOE_DELEGATE';
export const ASSIGN_VOE_DELEGATE = '[VOES] ASSIGN_VOE_DELEGATE';

// export Actions
export { actionFetchVoes, actionMarkReadUnread, actionFetchMoreVoes, actionFetchVoeDelegate, actionPostVoeDelegate } from "./voesActions";

// export Reducers
export default voes;
