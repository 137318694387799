import { CancelToken } from 'axios';
import cookie from 'js-cookie';
import { redirect } from 'redux-first-router';

import config from '../../constants/serverConfig';
import api from '../../utils/api';
import isEmpty from '../../utils/isEmpty';
import { simpleCrypto } from '../../utils/cryptoHelper';
import { removeAllStorage } from '../../utils/clearStorage';

import { LOGIN, SIGN_UP, FORGOT_PASSWORD, RESET_PASSWORD } from './index';
import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';
import { JWT_TOKEN, USER_PROFILE } from '../../constants/storage';
import { ROUTE_CONFIRM_EMAIL, ROUTE_CHANGE_PASS_SUCCESS, ROUTE_LOGIN } from '../../constants/routes';
import { KNOX_ID, VALIDITY, HASHED } from '../../constants/appEntry';


let source = CancelToken.source();

 
export const actionLogin = form => async dispatch => {

  console.log(form)

  try {
    
    dispatch({ type: LOGIN + PENDING });

    const { data, headers } = await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/sign-in/web`,
      cancelToken: source.token,
      data: form
    });

    if(!isEmpty(headers['x-jwt-token'])){
      cookie.set(JWT_TOKEN, headers['x-jwt-token'])

      if(!isEmpty(data.data)){
        const encToken = simpleCrypto.encrypt(data.data); // Encrypt token
        localStorage.setItem(USER_PROFILE, encToken);
      }

      if (data.data.isGA) {
        window.location.href = '#/request';
      } else if (data.data.isMD) {
        window.location.href = '#/voe';
      } else {
        window.location.href = '#/';
      }

      dispatch({ type: LOGIN + SUCCESS, payload: data.data });
    } else {
      dispatch({ type: LOGIN + ERROR });
    }


  } catch (e) {
    console.log(e.response)
    dispatch({ type: LOGIN + ERROR, payload: e.response.data.message});
  }
}

export const actionSignUp = props => async dispatch => {
  try {
    // delete props.password;
    dispatch({ type: SIGN_UP + PENDING })
    source = CancelToken.source();
    
    const { code } = await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/create-account`,
      cancelToken: source.token,
      data: {
        ...props,
        type: "1",
        team: 'HR'
      }
    });
    
    if (code !== 20000) {
      dispatch({ 
        type: SIGN_UP + SUCCESS,
        payload: { email: `${props.knox_id}@samsung.com` }
      })
  
      dispatch({ type: ROUTE_CONFIRM_EMAIL })
    }

  } catch (e) {
    dispatch({ type: SIGN_UP + ERROR , payload: {
      message: e.response.data.message
    }})
  }
}

export const actionForgotPassword = props => async dispatch => {
  try {

    dispatch({ type: FORGOT_PASSWORD + PENDING })

    const { data } = await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/forgot-password`,
      cancelToken: source.token,
      data: {
        ...props,
        url: `${config.APP_BASE_URL_NO_PARAMS}/resetpassword`
      }
    });
    
    dispatch({ type: FORGOT_PASSWORD + SUCCESS, payload: data });
  } catch (e) {
    dispatch({ type: FORGOT_PASSWORD + ERROR })
  }
}

export const actionResetPassword = form => async dispatch => {
  try {
    const knoxId = cookie.get(KNOX_ID);
    dispatch({ type: RESET_PASSWORD + PENDING })

    const { data } = await api({
      method: 'PUT',
      url: `${config.SERVER_BASE_URL}/forgot/change-password`,
      data: {
        new_password: form.new_password.trim(),
        knox_id: knoxId
      }
    });

    dispatch({ type: RESET_PASSWORD + SUCCESS, payload: data });
    dispatch({ type: ROUTE_CHANGE_PASS_SUCCESS })
    
  } catch (e) {
    dispatch({ type: RESET_PASSWORD + ERROR, payload: e.response.data.message })
  }
}


export const actionCheckifValid = async dispatch => {
  const knoxId = cookie.get(KNOX_ID);
  const validity = cookie.get(VALIDITY);
  const hashed = cookie.get(HASHED);

  if (isEmpty(knoxId) || isEmpty(validity) || isEmpty(hashed)) {
    dispatch(redirect({ type: ROUTE_LOGIN }));
  } else {

    try {
      await api({
        method: 'GET',
        url: `${config.SERVER_BASE_URL}/password-url-verification?knoxId=${knoxId}&validity=${validity}&hashed=${hashed}`
      });
    }catch (e) {
      dispatch(redirect({ type: ROUTE_LOGIN }));
      removeAllStorage()
    }
  }
}