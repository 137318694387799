import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import SvgIcon from './SvgIcon';

import IconButton from '../components/IconButton';

import invisible from '../../assets/icons/invisible.svg';
import visible from '../../assets/icons/visible.svg';

const propTypes = {
  field: PropTypes.object,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  maxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.string,
  hasIcon: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      viewBox: PropTypes.string
    }),
    PropTypes.string
  ]),
  hasError: PropTypes.bool,
  flexGrow: PropTypes.number,
  flexBasis: PropTypes.string,
  styleType: PropTypes.number, // 1 or 2
  inputRef: PropTypes.any,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  lineHeight: PropTypes.number,
  bold: PropTypes.bool,
};

const defaultProps = {
  form: {touched: {}, errors: {}},
  field: {},
  width: '100%',
  maxWidth: 'none',
  height: 40,
  type: 'text',
  hasIcon: false,
  icon: '',
  hide: true,
  hasError: false,
  flexGrow: 0,
  flexBasis: 'auto',
  styleType: 1,
  inputRef: null,
  fontSize: 18,
  lineHeight: 22,
  bold: false,
};

const InputContainer = styled('div')(({ theme, hasError, width, height, margin, styleType, maxWidth, flexGrow, flexBasis, disabled}) => ({
  width,
  borderRadius: (styleType === 1) ? 5 : 20,
  boxShadow: (styleType === 1) ? `inset 0px 1px 0px ${theme.inputBoxShadowColor}` : '',
  border: `1px solid ${hasError ? theme.inputColorError : ((styleType === 1) ? theme.inputBorderColor : theme.inputBorderColorType2)}`,
  color: hasError ? theme.inputColorError : theme.inputTextColor,
  backgroundColor: theme.inputBgColor,
  display: 'flex',
  height,
  maxWidth,
  flexGrow,
  flexBasis,
  position: 'relative',
  margin
}));

const InputField = styled('input')(({
  theme,
  styleType,
  hasIcon,
  fontSize,
  lineHeight,
  bold,
  disabled,
}) => ({
  position: 'relative',
  fontSize: (styleType === 1) ? 14 : fontSize,
  lineHeight: (styleType === 1) ? '16px' : `${lineHeight}px`,
  fontWeight: bold ? 700 : 400,
  width: '100%',
  padding: (styleType === 1) ? '15px 13px 14px' : (hasIcon ? '7px 10px' : '7px 20px'),
  backgroundColor: disabled ? '#CCCCCC' : 'transparent',
  caretColor: theme.inputCaretColor,
  border: 0,
  color: 'inherit',
  borderRadius: (styleType === 1) ? 5 : hasIcon ? '0 20px 20px 0' : '20px',

  '&:focus': {
    outline: 'none'
  },

  '::placeholder': {
    color: theme.inputPlaceHolderColor
  }
}));

const IconContainer = styled('div')(({ theme, hasError, styleType }) => ({
  backgroundColor: (styleType === 1) ? theme.inputIconBgColor : '',
  borderRadius: '4px 0 0 4px',
  borderRight: (styleType === 1) ? `1px solid ${hasError ? theme.inputColorError : theme.inputBorderColor}` : '',
  fill: hasError ? theme.inputColorError : theme.inputIconFill,
  width: (styleType === 1) ? 47 : 38,
  height: 'auto',
  position: 'relative',
  flexShrink: 0,
  display: 'flex',
  justifyContent: styleType === 1 ? 'center' : 'flex-end',
  alignItems: 'center'
}));

const EyeContainer = styled('div')(({ theme, hasError, styleType }) => ({
  backgroundColor: 'transparent',
  borderRadius: '0 20px 20px 0',
  borderRight: (styleType === 1) ? `1px solid ${hasError ? theme.inputColorError : theme.inputBorderColor}` : '',
  fill: hasError ? theme.inputColorError : theme.inputIconFill,
  alignItems: 'center',
  position: 'absolute',
  right: 20,
  top: 9
}));

const Input = ({
  field,
  form: { touched, errors },
  type,
  fontSize,
  lineHeight,
  bold,
  hasIcon,
  icon,
  eyeCon,
  hasError,
  width,
  maxWidth,
  height,
  styleType,
  flexGrow,
  flexBasis,
  inputRef,
  margin,
  ...others

}) => {
  const [hide, setHide] = useState(true);
  return(
  
    <InputContainer styleType={styleType} margin={margin} height={height} hasError={(touched[field.name] && errors[field.name]) || hasError} width={width} maxWidth={maxWidth} flexGrow={flexGrow} flexBasis={flexBasis}>
      {
        hasIcon ?
          <IconContainer styleType={styleType} hasError={(touched[field.name] && errors[field.name]) || hasError} >
            <SvgIcon symbol={icon} size={styleType === 1 ? 18 : 14} />
          </IconContainer>
          : null
      } 
      <InputField ref={inputRef} hasIcon={hasIcon} styleType={styleType} type={(type === 'password' && !hide) ? 'text' : type} fontSize={fontSize} lineHeight={lineHeight} bold={bold} {...field} {...others}/>
      {
        type === 'password' ?
          <EyeContainer styleType={styleType} hasError={(touched[field.name] && errors[field.name]) || hasError} >
            <IconButton onClick={() => setHide(!hide)}><SvgIcon symbol={hide ? invisible : visible} size={styleType === 1 ? 18 : 20} /></IconButton>
          </EyeContainer>
          :
          null
      }
    </InputContainer>
  )
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
