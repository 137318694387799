import SimpleCrypto from 'simple-crypto-js';

export const simpleCrypto = new SimpleCrypto('SRPH_SEPCO_APP');

export const decodeStorage = (storageName, isBoolean = false) => {
  if (localStorage.getItem(storageName)) {
    const encObj = localStorage.getItem(storageName);
    return simpleCrypto.decrypt(encObj, true);
  }

  if (isBoolean) {
    return false;
  }

  return {};
};
