import requests from './requests';

export const FETCH_DOCUMENT_REQUESTS = '[REQUESTS] FETCH_DOCUMENT_REQUESTS'; 
export const UPDATE_DOCUMENT_STATUS = '[REQUESTS] UPDATE_DOCUMENT_STATUS';
export const SET_REQUEST_ASSIGNEE = '[REQUESTS] SET_REQUEST_ASSIGNEE';
export const FETCH_CANCELLED_REQUESTS = '[REQUESTS] FETCH_CANCELLED_REQUESTS';
export const SET_FILTER_START_DATE = '[REQUESTS] SET_FILTER_START_DATE';
export const SET_FILTER_END_DATE = '[REQUESTS] SET_FILTER_END_DATE';
export const SET_FILTER_REF_NUM = '[REQUESTS] SET_FILTER_REF_NUM';
export const RESET_FILTERS = '[REQUESTS] RESET_FILTERS';
export const EXPORT_DOCUMENT_REQUEST = '[REQUESTS] EXPORT_DOCUMENT_REQUESTS';
export const CANCEL_FETCH_REQUEST = '[REQUESTS] CANCEL_FETCH_REQUESTS';

export {
  actionFetchDocumentRequests,
  actionUpdateStatus,
  actionAssignRequest,
  actionSetFilterStartDate,
  actionSetFilterEndDate,
  actionResetFilters,
  actionFetchCancelledRequests,
  actionSetFilterRefNum,
  actionExportDocumentRequest,
  actionCancelDocumentRequests
} from './requestActions'

export default requests;