import entry from './entry';

export const LOGIN = '[ENTRY] LOGIN';
export const SIGN_UP = '[ENTRY] SIGN_UP';
export const FORGOT_PASSWORD = '[ENTRY] FORGOT_PASSWORD'
export const RESET_PASSWORD = '[ENTRY] RESET_PASSWORD'

export { 
  actionLogin,
  actionSignUp,
  actionForgotPassword,
  actionResetPassword,
  actionCheckifValid
} from './entryActions';

export default entry;