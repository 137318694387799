import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import button from '../../styles/common/button';

const propTypes = {
  hasHover: PropTypes.bool
};

const defaultProps = {
  hasHover: true,
};

const IconButton = styled('div')(
  ({ theme, hasHover, disabled }) => ({
    backgroundColor: theme.iconBtnBgColor,
    fill: disabled ? `${theme.iconBtnDisabledIconColor} !important` : theme.iconBtnIconColor,
    color: disabled ? `${theme.iconBtnDisabledIconColor} !important` : theme.iconBtnIconColor,
    border: 0,
    pointerEvents: disabled ? 'none' : 'auto',
    cursor: 'pointer',

    '&:hover': {
      fill: hasHover ? theme.iconBtnHoverIconColor : theme.iconBtnIconColor,
      color: hasHover ? theme.iconBtnHoverIconColor : theme.iconBtnIconColor
    },

    '&:active': {
      fill: hasHover ? theme.iconBtnActiveIconColor : theme.iconBtnIconColor,
      color: hasHover ? theme.iconBtnActiveIconColor : theme.iconBtnIconColor
    }
  }),
  button
);

IconButton.propTypes = propTypes;
IconButton.defaultProps = defaultProps;


export default IconButton;
