import React from 'react';
import styled from '@emotion/styled';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';

// Forms
import CardForm from '../components/CardForm';
import FillButton from '../components/FillButton';
import Input from '../components/Input';

// assets
import userIcon from '../../assets/icons/user.svg';
import empIcon from '../../assets/icons/empno.svg';
import emailIcon from '../../assets/icons/email.svg';
import passwordIcon from '../../assets/icons/password.svg';

// actions
import { actionSignUp } from '../../reduxModules/appEntry/entryActions';

const InputContainer = styled('div')({
  margin: '0px 38px 26px 48px'
});

const Card = styled(CardForm)({
  height: 627,
  width: 516
})

const Title = styled('p')(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: 24,
  lineHeight: '28px',
  textAlign: 'center',
  color: theme.formTitleColor,
  margin: '79px 149px 63px 149px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative'
}));

const ButtonContainer = styled('div')({
  margin: '45px 38px 52px 0px',
  display: 'flex',
  justifyContent: 'flex-end'
});
const ErrorContainer = styled('span')(({ theme, isSubmitted }) => ({
  position: 'absolute',
  color: theme.inputColorError,
  fontSize: 12,
  lineHeight: '14px',
  fontWeight: 300,
  width: '100%',
  left: isSubmitted ? 0 : 'auto'
}))

const schema = Yup.object().shape({
  first_name: Yup.string().required('Firstname is Required'),
  last_name: Yup.string().required('Lastname is Required'),
  employee_id: Yup.number().required('Employee No. is Required').typeError('Employee No. should be a number'),
  knox_id: Yup.string().required('Knox ID is Required'),
  // password: Yup.string().required('Password is Required'),
})

const SubmitError = styled('span')({});


const SignUp = ({ actionSignUp, isSigningUpError, isSigningUpErrorMsg }) => (
  <Card>
    <Title>
      <span>Create your account</span>
      {isSigningUpError ? <SubmitError><ErrorContainer isSubmitted>{isSigningUpErrorMsg}</ErrorContainer></SubmitError> : ""}
    </Title>
      
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        employee_id: '',
        knox_id:'',
        // password: ''
      }}
      validationSchema={schema}
      onSubmit={actionSignUp}
    >
      <Form>
        {/* First name */}
        <InputContainer>
          <Field name="first_name" placeholder="First Name" component={Input} hasIcon icon={userIcon}/>
          <ErrorContainer>
            <ErrorMessage name="first_name"/>  
          </ErrorContainer> 
        </InputContainer>

        {/* Last name */}
        <InputContainer>
          <Field name="last_name" placeholder="Last Name" component={Input} hasIcon icon={userIcon}/>
          <ErrorContainer>
            <ErrorMessage name="last_name"/>  
          </ErrorContainer>
        </InputContainer>

        {/* Employee No. */}
        <InputContainer> 
          <Field name="employee_id" placeholder="Employee No." component={Input} hasIcon icon={empIcon}/>
          <ErrorContainer>
            <ErrorMessage name="employee_id"/>  
          </ErrorContainer>
        </InputContainer>
      
        {/* First Knox ID */}
        <InputContainer>
          <Field name="knox_id" placeholder="Knox ID" component={Input} hasIcon icon={emailIcon}/>
          <ErrorContainer>
            <ErrorMessage name="knox_id"/>  
          </ErrorContainer>
        </InputContainer>
        
        {/* Password */}
        {/* <InputContainer>
          <Field name="password" placeholder="Password" type="password" component={Input} hasIcon icon={passwordIcon}/>
          <ErrorContainer>
            <ErrorMessage name="password"/>  
          </ErrorContainer>
        </InputContainer> */}

        <ButtonContainer>
          <FillButton type="submit">Create</FillButton>
        </ButtonContainer>
      </Form>
    </Formik>

  </Card>
);

  
export default connect(state => ({
  isSigningUpError: state.appEntry.isSigningUpError,
  isSigningUpErrorMsg: state.appEntry.isSigningUpErrorMsg
}), {actionSignUp})(SignUp);