import React, { useState, Fragment } from 'react';
import styled from '@emotion/styled';
import Link from 'redux-first-router-link'
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';

import { actionLogin } from '../../reduxModules/appEntry';
import * as Yup from 'yup';

//Components
import Text from '../components/Text';
import Input from '../components/Input';
import FillButton from '../components/FillButton';
import FlatButton from '../components/FlatButton';
import IconButton from '../components/IconButton';
import CardForm from '../components/CardForm';
import SvgIcon from '../components/SvgIcon';
import Checkbox from '../components/Checkbox';
import Spacer from '../components/Spacer';
import Card from '../components/Card';

import { USERNAME, IS_REMEMBER } from '../../constants/storage';

//Assets
import emailIcon from '../../assets/icons/email.svg';
import passwordIcon from '../../assets/icons/password.svg';
import SEPCOnnectLogo from '../../assets/icons/SEPCOnnectLogo.svg';
import invisible from '../../assets/icons/invisible.svg';
import visible from '../../assets/icons/visible.svg';


const AppIconHolder = styled('div')({
  display: 'flex',
  justifyContent: 'center'
})


const FormContainer = styled(CardForm)({
  height: 533,
  width: 484,
  padding: '60px 62px',
  borderRadius: 26,
  boxShadow: 'None'
});

const HeaderContainer = styled('div')(({ isLoginError }) => ({
  marginBottom: isLoginError ? 60 : 100,
  textAlign: 'center',
  position: 'relative'
}));

const AdminTitleHolder = styled('div')(({ isLoginError })=>({
  paddingRight: isLoginError ? 30 : 57,
  position: 'absolute',
  top: 67,
  right: 7
}))

const FieldsContainer = styled('div')({
  '>*' : {
    marginBottom: 15,

  }
});

const FieldContainer = styled('div')({
  '>*' : {
    marginBottom: 15,
    
  },
  display: 'flex',
  flexDirection: 'row'
});

const RememberMeContainer = styled('div')({
  display: 'flex',
  marginBottom: 60
});

const RememberMeText = styled(Text)({
  color: '#888888',
  fontSize: 12,
  lineHeight: '26px'
});

const ForgotPasswordContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: 38,
});

const ErrorMessageContainer = styled('div')({
  marginBottom: 16
});

const ErrorMessage = styled(Text)(({ theme }) => ({
  color: theme.errorTextColor,
  fontSize: 14,
  lineHeight: '17px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center'
}));

const SignInButton = styled(FillButton)({
  width: '100%',
  height: 40,
  borderRadius: 20,
  fontSize: 16,
  padding: '7px 20px',
})

const AdminPortalText = styled(Text)({
  fontSize: 12,
  lineHeight: '14px',
  display: 'flex',
  justifyContent: 'flex-end',
  color: '#888888',
})

const EyeContainer = styled(IconButton)(({ theme, hasError}) => ({
  borderRadius: '0 20px 20px 0',
  borderRight: '1px solid #CCC',
  borderTop: '1px solid #CCC',
  borderBottom: '1px solid #CCC',
  fill: theme.inputIconFill,
  width: 38,
  height: 'auto',
  position: 'relative',
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const Login = ({
  isLoginError,
  actionLogin,
  err_message,
  Error_Message
}) => {

  const [isRememberID, setIsRememberID] = useState(localStorage.getItem(IS_REMEMBER) ? localStorage.getItem(IS_REMEMBER) : false);
  const [userName, setUserName] = useState(localStorage.getItem(USERNAME) ? localStorage.getItem(USERNAME) : '');

  const handleLoginSubmit = e => {
    rememberID(e.knoxId);
    actionLogin(e);
    return false;

  }

  const schema = Yup.object().shape({
    knoxId: Yup.string()
      .required('Required'),
    password: Yup.string()
      .required('Required'),
  });

  const toggleRemember = () => setIsRememberID(!isRememberID);

  const rememberID = userID => {
    if (isRememberID) {
      localStorage.setItem(USERNAME, userID);
      localStorage.setItem(IS_REMEMBER, true);
    } else {
      localStorage.removeItem(USERNAME);
      localStorage.removeItem(IS_REMEMBER);
    }
  };
  
  return (
    <Fragment>
      <FormContainer>
        <HeaderContainer isLoginError={isLoginError}>
          <AppIconHolder>
            <SvgIcon symbol={SEPCOnnectLogo} size={90} style={{ width: 244, height: 90 }} />
          </AppIconHolder>
          <AdminTitleHolder>
            <AdminPortalText>ADMIN PORTAL</AdminPortalText>
          </AdminTitleHolder>
        </HeaderContainer>

        {
          isLoginError ? 
            <ErrorMessageContainer>
              <ErrorMessage>
                {Error_Message === 'Invalid request parameter value(s)' ? 
                  "We couldn’t log you in with the information \nyou’ve entered. Please check your details and try again."  :
                
                
                  Error_Message === 'Account forbidden' ? 
                
                    "We couldn’t log you in with the information \nyou’ve entered. Please check your details and try again."
                
                    : null }</ErrorMessage>
            </ErrorMessageContainer>
            : null 
        }

        <Formik
          initialValues={{
            knoxId: userName,
            password: ''
          }}
          validationSchema={schema}
          onSubmit={handleLoginSubmit}
          validateOnBlur={false}
          validateOnChange={false}
        >
          <Form>
            <FieldsContainer>
              <Field
                name="knoxId"
                placeholder="Knox ID"
                component={Input}
                hasIcon
                icon={emailIcon}
                height={40}
                styleType={2}
                autoComplete="nope"
              />
              <Field
                type={"password"}
                name="password"
                placeholder="Password"
                component={Input}
                hasIcon
                icon={passwordIcon}
                height={40}
                styleType={2}
                autoComplete="nope"
              />
            </FieldsContainer>
            
            <RememberMeContainer>
              <Checkbox style={{border:'1px solid #CCCCCC' ,boxSizing:'border-box' ,borderRadius:'4px' }} onClick={toggleRemember} checked={!!isRememberID} />
              <Spacer spacing={9} />
              <RememberMeText>Remember me</RememberMeText>
            </RememberMeContainer>

            <SignInButton>SIGN IN</SignInButton>
        
          </Form>
        </Formik>
      </FormContainer>

      <ForgotPasswordContainer>
        <FlatButton bold hasUnderline><Link to='/forgotpassword'>Forgot your password?</Link></FlatButton>
      </ForgotPasswordContainer>
    </Fragment>
  )
};

export default connect(state => ({
  isLoginError: state.appEntry.isLoginError,
  Error_Message: state.appEntry.errorLoginMessage
}), {
  actionLogin
})(Login);