import { CancelToken } from 'axios';
import config from '../../constants/serverConfig';
import api from '../../utils/api';

import { PENDING, SUCCESS, ERROR } from "../../constants/statusTypes";

import { GET_VOES_LIST, UPDATE_READ_UNREAD, GET_MORE_VOES_LIST, GET_VOE_DELEGATE, POST_VOE_DELEGATE, ASSIGN_VOE_DELEGATE } from "./index";
import isEmpty from '../../utils/isEmpty';

let source = CancelToken.source();

export const actionFetchVoes =
  (page, rows, search = "", status = 0) =>
    async (dispatch) => {
      try {
        let readStatus;

        if (status === 0) {
          readStatus = "";
        } else if (status === 1) {
          readStatus = "&isRead=true";
        } else if (status === 2) {
          readStatus = "&isRead=false";
        }

        dispatch({ type: GET_VOES_LIST + PENDING });
        source = CancelToken.source();

        const { data } = await api({
          method: 'GET',
          url: `${config.SERVER_BASE_URL}/voes?page=${page}&rows=${rows}&search=${encodeURIComponent(search)}&savingMode=SAVE_AND_SUBMIT${readStatus}`,
          cancelToken: source.token
        })

        const { _metadata, voes } = data.data;
      
        const total = _metadata.totalCount;  

        dispatch({
          type: GET_VOES_LIST + SUCCESS,
          payload: { data: voes, total },
        });
      } catch (e) {
        if (!e.isCancel) {
          dispatch({ type: GET_VOES_LIST + ERROR, e });
        }
      }
    };

export const actionMarkReadUnread =
  ( id, isRead, status, voes, totalCount) =>
    async (dispatch) => {
      try {

        await api({
          method: 'PUT',
          url: `${config.SERVER_BASE_URL}/voes/${id}/update`,
          cancelToken: source.token,
          data:
          {
            "isRead": !isRead
          }
        })

        if (status !== 0) {
          dispatch(actionFetchVoes(1, 10, "", status));
        }

        const mapped = voes.map(item => {
          return item.id === id ? { ...item, isRead: !isRead } : { ...item};
        });

        dispatch({
          type: UPDATE_READ_UNREAD + SUCCESS,
          payload: { data: mapped, totalCount },
        });
      } catch (e) {
        dispatch({ type: UPDATE_READ_UNREAD + ERROR, e });
      }
    };

export const actionFetchMoreVoes =
    (page, rows, search = "", status = 0) =>
      async (dispatch) => {
        try {
          let readStatus;

          if (status === 0) {
            readStatus = "";
          } else if (status === 1) {
            readStatus = "&isRead=true";
          } else if (status === 2) {
            readStatus = "&isRead=false";
          }

          dispatch({ type: GET_MORE_VOES_LIST + PENDING });
          source = CancelToken.source();
  
          const { data } = await api({
            method: 'GET',
            url: `${config.SERVER_BASE_URL}/voes?page=${page}&rows=${rows}&search=${encodeURIComponent(search)}&savingMode=SAVE_AND_SUBMIT${readStatus}`,
            cancelToken: source.token
          })

          const { voes } = data.data;

          if (!isEmpty(voes)){
            dispatch({ type:  GET_MORE_VOES_LIST + SUCCESS, payload: { data: voes } });
          } else {
            dispatch({ type:  GET_MORE_VOES_LIST + ERROR });
          }

        } catch (e) {
          dispatch({ type:  GET_MORE_VOES_LIST + ERROR, e });
        }
      };

export const actionFetchVoeDelegate = () => async dispatch => {
  try {
    dispatch({ type: GET_VOE_DELEGATE + PENDING });
    source = CancelToken.source();

    const { data: { data } } = await api({
      method: 'GET',
      url: `${config.SERVER_BASE_URL}/employees/voe`,
      cancelToken: source.token
    })

    dispatch({ type: GET_VOE_DELEGATE + SUCCESS, payload: data })

  } catch (e) {
    dispatch({ type: GET_VOE_DELEGATE + ERROR })
  }
}

export const actionPostVoeDelegate = form => async dispatch => {
  try {
    dispatch({ type: ASSIGN_VOE_DELEGATE + PENDING });
    const { data } = await api({
      method: 'POST',
      url: `${config.SERVER_BASE_URL}/voes/delegate`,
      cancelToken: source.token,
      data: {
        "voeId": form.voeId,
        "delegateId": form.delegateId,
        "delegateNote": form.message
      }
    });
    
    dispatch({ 
      type: ASSIGN_VOE_DELEGATE + SUCCESS, 
      payload: data.message,
    });
    
  } catch (e) {
    dispatch({ 
      type: ASSIGN_VOE_DELEGATE + ERROR , 
      payload: e.response.data.message});
    throw new Error(e.response.data.message);
  }

}