import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import mq from '../../styles/mediaQueries';

const propTypes = {
  spacing: PropTypes.number.isRequired,
  spacingMedium: PropTypes.number,
  spacingSmall: PropTypes.number
};

const defaultProps = {
  spacingMedium: null,
  spacingSmall: null
};

const Spacer = styled('div')(
  {
    pointerEvents: 'none'
  },
  ({
    spacing, spacingMedium, spacingSmall
  }) => (
    mq({
      width: [
        spacingSmall === 0 ? 0 : spacingSmall || spacing,
        spacingMedium === 0 ? 0 : spacingMedium || spacing,
        spacing
      ],
      flexShrink: 0
    })
  )

);

Spacer.propTypes = propTypes;
Spacer.defaultProps = defaultProps;

export default Spacer;
