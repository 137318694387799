import config from '../constants/serverConfig';
import { removeAllStorage } from './clearStorage';

export const errorHandler = e => {
 
  console.log('=================>Check routes: ');
  if (e.response) {
    if (e.response.status === 401) {
      if (e.response.data.code === 40100) {
        // token expired
        removeAllStorage();
        window.location = '#/login'
      } else {
        window.location.href = '#/error400';
      }
    } else if (e.response.status === 403) {
      window.location.href = '#/error403';
    } else if (e.response.status === 404) {
      window.location.href = '#/error404';
    } else if (e.response.status === 500) {
      window.location.href = '#/error500';
    } else if (e.response.status === 502) {
      window.location.href = '#/error502';
    } else if (e.response.status === 503) {
      window.location.href = '#/error503';
    }
  }
  
};
