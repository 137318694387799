import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import SvgIcon from './SvgIcon';

const propTypes = {
  role: PropTypes.string,
  tabIndex: PropTypes.number,
  size: PropTypes.number,
  position: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

const defaultProps = {
  role: 'menuitemcheckbox',
  tabIndex: 0,
  size: 22,
  position: 'absolute',
  checked: false,
  disabled: false
};

const CheckboxContainer = styled('input')(({
  theme, distance, position, checked, disabled, size
}) => ({
  '-webkit-appearance' : 'none',
  backgroundColor: theme.checkBoxBgColor,
  border: `2px solid ${theme.checkBoxBorderColor}`,
  padding: 9,
  borderRadius: 3,
  display: 'inline-block',
  position: 'relative',

  '&:checked' : {
    color: theme.primaryColor,

    '&::after':{
      content: "'\\2714'",
      fontSize: 18,
      position: 'absolute',
      top: -4,
      left: 1,
      color: '#000' //theme.primaryColor
    }
  }
}));

const Checkbox = ({
  role, tabIndex, size, position, checked, disabled, name, ...others
}) => (
  <CheckboxContainer
    type="checkbox"
    role={role}
    tabIndex={tabIndex}
    position={position}
    disabled={disabled}
    size={size}
    checked={checked}
    {...others}
    readOnly
  />
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
