import dialog from './dialog';

export const OPEN_DIALOG = '[dialog] OPEN_DIALOG';
export const CLOSE_DIALOG = '[dialog] CLOSE_DIALOG';
export const RESET_DIALOG_PROPS = '[dialog] RESET_DIALOG_PROPS';
export const SET_REGISTER_EMAIL_CHECK = '[DOWNLOAD] SET_REGISTER_EMAIL_CHECK';

export {
  actionOpenDialog,
  actionCloseDialog,
  actionHandleRegisterEmailCheckbox
} from './dialogActions';

export default dialog;
