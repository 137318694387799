import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

import {
  GET_BCP_LIST,
  POST_BCP_ALERT,
  GET_EMPLOYEE_LIST,
  END_BCP_ALERT,
  RESEND_BCP_ALERT,
  EXPORT_EVENT_CSV,
  RESET_ACTIVE_ALERT,
  SET_ACTIVE_ALERT
} from './index';

const initialState = {
  activeAlert: null,
  isBcpListPending: false,
  isBcpListError: false,
  isPostAlertPending: false,
  isPostAlertError: false,
  isPostAlertSuccess: false,
  bcpList: [],
  employeeList: [],
  isEmployeeSuccess: false,
  isEmployeeListPending: false,
  isEmployeeListError: false,
  isEndBcpAlertPending: false,
  isEndBcpAlertError: false,
  isEndBcpAlertSuccess:false,
  isResendBcpAlertError: false,
  isResendBcpAlertPending: false,
  isResendBcpAlertSuccess: false,
  isExportCsvPending: false,
  isExportCsvSuccess: false,
  isExportCsvError: false
}

const bcp = (state = initialState, action = {}) => {
  switch(action.type) {
  case SET_ACTIVE_ALERT:
    return {
      ...state,
      activeAlert: action.payload
    }
  case RESET_ACTIVE_ALERT:
    return {
      ...state,
      activeAlert: initialState.activeAlert,
      employeeList: initialState.employeeList,
    }
  case GET_BCP_LIST + PENDING:
    return {
      ...state,
      isBcpListPending: true,
      isBcpListError: false
    }
  case GET_BCP_LIST + SUCCESS:
    return {
      ...state,
      isBcpListPending: false,
      isBcpListError: false,
      bcpList: action.payload
    }
  case GET_BCP_LIST + ERROR:
    return {
      ...state,
      isBcpListPending: false,
      isBcpListError: true
    }
  case POST_BCP_ALERT + PENDING:
    return {
      ...state,
      isPostAlertPending: true,
      isPostAlertError: false,
      isPostAlertSuccess: false
    }
  case POST_BCP_ALERT + SUCCESS:
    return {
      ...state,
      isPostAlertPending: false,
      isPostAlertError: false,
      isPostAlertSuccess: false
    }
  case POST_BCP_ALERT + ERROR:
    return {
      ...state,
      isPostAlertPending: false,
      isPostAlertError: true,
      isPostAlertSuccess: false
    }
  case GET_EMPLOYEE_LIST + PENDING:
    return {
      ...state,
      isEmployeeListPending: true,
      isEmployeeListError: false,
      isEmployeeSuccess: false
    }
  case GET_EMPLOYEE_LIST + SUCCESS:
    return {
      ...state,
      isEmployeeListPending: false,
      isEmployeeListError: false,
      isEmployeeSuccess: true,
      employeeList: action.payload
    }
  case GET_EMPLOYEE_LIST + ERROR:
    return {
      ...state,
      isEmployeeListPending: false,
      isEmployeeListError: true,
      isEmployeeSuccess: false,
      employeeList: initialState.employeeList
    }
  case END_BCP_ALERT + PENDING:
    return {
      ...state,
      isEndBcpAlertPending: true,
      isEndBcpAlertError: false,
      isEndBcpAlertSuccess: false
    }
  case END_BCP_ALERT + SUCCESS:
    return {
      ...state,
      isEndBcpAlertPending: false,
      isEndBcpAlertError: false,
      isEndBcpAlertSuccess: true
    }
  case END_BCP_ALERT + ERROR:
    return {
      ...state,
      isEndBcpAlertPending: false,
      isEndBcpAlertError: true,
      isEndBcpAlertSuccess: false
    }
  case RESEND_BCP_ALERT + PENDING:
    return {
      ...state,
      isResendBcpAlertError: false,
      isResendBcpAlertPending: true,
      isResendBcpAlertSuccess: false
    }
  case RESEND_BCP_ALERT + SUCCESS:
    return {
      ...state,
      isResendBcpAlertError: false,
      isResendBcpAlertPending: false,
      isResendBcpAlertSuccess: true
    }
  case RESEND_BCP_ALERT + ERROR:
    return {
      ...state,
      isResendBcpAlertError: true,
      isResendBcpAlertPending: false,
      isResendBcpAlertSuccess: false
    }
  case EXPORT_EVENT_CSV + PENDING:
    return{
      ...state,
      isExportCsvPending: true,
      isExportCsvSuccess: false,
      isExportCsvError: false
    }
  case EXPORT_EVENT_CSV + SUCCESS:
    return{
      ...state,
      isExportCsvPending: false,
      isExportCsvSuccess: true,
      isExportCsvError: false
    }
  case EXPORT_EVENT_CSV + ERROR:
    return{
      ...state,
      isExportCsvPending: false,
      isExportCsvSuccess: false,
      isExportCsvError: true
    }
  default:
    return state;
  }
}

export default bcp;
