import update from 'immutability-helper';
import { SHOW_TOAST, HIDE_TOAST, RESET_TOAST } from './index';

const initialState = {
  isShown: false,
  props: {
    message: '',
    buttonText: '',
    onClick: () => {}
  }
};

const toast = (state = initialState, action = {}) => {
  switch (action.type) {
  case SHOW_TOAST:
    if (typeof action.payload.props === 'string' || action.payload.props instanceof String) {
      return update(state, {
        isShown: { $set: true },
        props: { message: { $set: action.payload.props } }
      });
    }
    return update(state, {
      isShown: { $set: true },
      props: { $set: action.payload.props }
    });
  case HIDE_TOAST:
    return update(state, {
      isShown: { $set: false }
    });
  case RESET_TOAST:
    return initialState;
  default:
    return state;
  }
};

export default toast;
