import cookie from 'js-cookie';
import { checkSSO } from './utils/authSession';
import { KNOX_ID, VALIDITY, HASHED } from './constants/appEntry';

export default {
  onBeforeChange: async (dispatch, getState, { action }) => {
    checkSSO(dispatch, getState, action)
    const urlString = window.location.toString();
    const knoxId = urlString.match(/knox_id=([^&]*)/);
    const validity = urlString.match(/validity=([^&]*)/);
    const hashed = urlString.match(/hashed=([^&]*)/);
    
    if (knoxId) {
      cookie.set(KNOX_ID, knoxId[1])
    }
    if (validity) {
      cookie.set(VALIDITY, validity[1])
    }
    if (hashed) {
      cookie.set(HASHED, hashed[1])
    }
  },
  onAfterChange: async () => {
   
  }
};
