import React, {
  Fragment,
  lazy,
  Suspense,
  useEffect
} from 'react';
import { NOT_FOUND } from 'redux-first-router';
import { connect } from 'react-redux';
import cookie from 'js-cookie';
  
import {
  ROUTE_LOGIN,
  ROUTE_SIGNUP,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_CHANGE_PASS_SUCCESS,
  ROUTE_HOME,
  ROUTE_EVENTS,
  ROUTE_ADD_EVENTS,
  ROUTE_UPDATE_EVENTS,
  ROUTE_PROFILE,
  ROUTE_USERS,
  ROUTE_DOCUMENTS,
  ROUTE_SUGGESTIONS,
  ROUTE_BCP,
  ROUTE_LEADERBOARD,
  ROUTE_REQUEST,
  ROUTE_CONFIRM_EMAIL,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_VOE,
  ROUTE_FEEDBACKS,
  ROUTE_ERROR_400,
  ROUTE_ERROR_403,
  ROUTE_ERROR_404,
  ROUTE_ERROR_500,
  ROUTE_ERROR_502,
  ROUTE_ERROR_503
} from '../../constants/routes';
  
import {
  error400,
  error403,
  error404,
  error500,
  error502,
  error503
} from '../../constants/pageErrorTypes';


import { Timeout, checkTimeout, updateDuration } from '../../utils/authSession';
import { interval } from '../../utils/interval';
import { session } from '../../constants/session';
import { ACTIVE_ACTION, SSO_TOKEN } from '../../constants/storage';

import LoginPage from '../appEntry/Login';
import SignUpPage from '../appEntry/Signup';
import ForgotPasswordPage from '../appEntry/ForgotPassword';
import ResetPassowrdPage from '../appEntry/ResetPassword';
import ChangePassSuccessPage from '../appEntry/ChangePassSuccess';
import ConfirmEmail from '../appEntry/ConfirmEmail';
import ChangePassword from '../appChangePassword/ChangePassword';

// Dynamic imports components
const Header = lazy(() => import('../layouts/Header'));
const AppRoutes = lazy(() => import('../layouts/AppRoutes'));
const ErrorPage = lazy(() => import('../components/ErrorPage'));
const AppEntry = lazy(() => import('../appEntry'));
const DialogRoot = lazy(() => import('./DialogRoot'));
const ToastRoot = lazy(() => import('./ToastRoot'));

  
const commonLayouts = () => (
  <Fragment>
    <Suspense fallback={<div />}>
      <Header />
    </Suspense>
    <Suspense fallback={<div />}>
      <AppRoutes />
    </Suspense>
    <Suspense fallback={<div />}>
      <DialogRoot />
    </Suspense>
    <Suspense fallback={<div />}>
      <ToastRoot />
    </Suspense>
  </Fragment>
);

const appEntryLayouts = (Page) => (
  <Suspense fallback={<div />}>
    <AppEntry>
      <Page />
    </AppEntry>
  </Suspense>
);
  
// Note: wrap lazy components in suspense
const getErrorPage = errorType => (
  <Suspense fallback={<div />}>
    <ErrorPage errorType={errorType} />
  </Suspense>
);

  
const AppRoot = ({ location }) => {
  useEffect(() => {
    const userInteraction = () => {
      switch (location) {
      case NOT_FOUND:
      case ROUTE_ERROR_400:
      case ROUTE_ERROR_403:
      case ROUTE_ERROR_500:
      case ROUTE_ERROR_502:
      case ROUTE_ERROR_503:
        break;
      default:
        // if (cookie.get(SSO_TOKEN)) {
        //   updateDuration();
        // }
        break
      }
    };
    const dtNextChecking = new Date().getTime() + session.duration;
    cookie.set(ACTIVE_ACTION, dtNextChecking);
  
    window.addEventListener('mousedown', userInteraction);
    window.addEventListener('keydown', userInteraction);
  
    return () => {
      window.removeEventListener('mousedown', userInteraction);
      window.removeEventListener('keydown', userInteraction);
    };
  }, [location]);
  
  const parentScrollBar = document.getElementById('parent-scroll-view');
  if (parentScrollBar && parentScrollBar.scrollTop !== 0) {
    parentScrollBar.scrollTop = 0;
  }
  switch (location) {
  case ROUTE_ERROR_400:
    return getErrorPage(error400);
  case NOT_FOUND:
  case ROUTE_ERROR_404:
    return getErrorPage(error404);
  case ROUTE_ERROR_403:
    return getErrorPage(error403);
  case ROUTE_ERROR_500:
    return getErrorPage(error500);
  case ROUTE_ERROR_502:
    return getErrorPage(error502);
  case ROUTE_ERROR_503:
    return getErrorPage(error503);
  case ROUTE_LOGIN:
    return appEntryLayouts(LoginPage);
  case ROUTE_SIGNUP:
    return appEntryLayouts(SignUpPage);
  case ROUTE_FORGOT_PASSWORD:
    return appEntryLayouts(ForgotPasswordPage);
  case ROUTE_RESET_PASSWORD:
    return appEntryLayouts(ResetPassowrdPage);
  case ROUTE_CHANGE_PASS_SUCCESS:
    return appEntryLayouts(ChangePassSuccessPage);
  case ROUTE_CONFIRM_EMAIL:
    return appEntryLayouts(ConfirmEmail);

  case ROUTE_HOME:
  case ROUTE_VOE:
  case ROUTE_EVENTS:
  case ROUTE_ADD_EVENTS:
  case ROUTE_UPDATE_EVENTS:
  case ROUTE_USERS:
  case ROUTE_DOCUMENTS:
  case ROUTE_SUGGESTIONS:
  case ROUTE_BCP:
  case ROUTE_LEADERBOARD:
  case ROUTE_REQUEST:
  case ROUTE_FEEDBACKS:
  case ROUTE_PROFILE:
  case ROUTE_CHANGE_PASSWORD:

    //if (cookie.get(SSO_TOKEN)) {
    // Start the session when there's an access token
    // console.log('initialize timeout..');
    // global.Timeout.initialize(
    //   'TimeoutAction',
    //   () => checkTimeout(),
    //   session.duration, true
    // );
    // interval(checkTimeout);
    //}
  
    return commonLayouts();
  default:
    return null;
  }
};
  
export default connect(state => ({
  location: state.location.type
}))(AppRoot);
  
