import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import React from 'react';
import mq from '../../styles/mediaQueries';


const propTypes = {
  bold: PropTypes.bool,
  clickable: PropTypes.bool,
  fontSize: PropTypes.number,
  noWrap: PropTypes.bool
};

const defaultProps = {
  bold: false,
  clickable: false,
  fontSize: 14,
  noWrap: false
};

const Text = styled('span')(({
  theme, clickable, bold, fontSize, noWrap, lineHeight
}) => ({
  color: clickable ? theme.clickableTextColor : theme.textColor,
  fontWeight: bold ? '700' : '500',
  fontSize,
  lineHeight: lineHeight ? `${lineHeight}px` : '',
  whiteSpace: noWrap ? 'nowrap' : 'normal',

  ':hover': {
    cursor: clickable ? 'pointer' : 'default',
    backgroundColor: clickable ? theme.headerDrawerHoverBgColor : 'transparent',
    color: clickable ? theme.clickableTextHoverColor : ''
  },
  ':active': {
    cursor: clickable ? 'pointer' : 'default',
    backgroundColor: clickable ? theme.headerDrawerActiveBgColor : 'transparent',
    color: clickable ? theme.flatBtnActiveTextColor : ''
  }
}));

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '4px 4px 4px 0'
});

const LineText = styled('span')(({
  theme, fontSize, lineHeight, isInitial
}) => mq({
  lineHeight,
  textTransform: isInitial ? 'initial' : 'capitalize',
  color: theme.grayTextColor,
  fontSize,
  flex: 'none',
  display: 'block',
  fontWeight: 'bold',
  width: '100%'
}));

const Line = styled('div')(({
  theme, fatLineHeight
}) => (mq({
  width: '100%',
  height: [1, fatLineHeight ? 2 : 1, fatLineHeight ? 2 : 1],
  background: theme.lineColor
})));

const TextHeader = ({
  fontSize, lineHeight, text, fatLineHeight, isInitial
}) => (
  <Container>
    <LineText lineHeight={lineHeight} fontSize={fontSize} isInitial={isInitial}>{text}</LineText>
    <Line fatLineHeight={fatLineHeight} />
  </Container>
);

Text.Header = TextHeader;
Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
