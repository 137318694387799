import update from 'immutability-helper';
import {
  RESET_KNOBS,
  CHANGE_BOTTOM_DRAWER_DISPLAY,
  CHANGE_DRAWER_ACTIVITY
} from './index';

const initialState = {
  bottomDrawer: '',
  drawerActivity: ''
};

const Knobs = (state = initialState, action = {}) => {
  switch (action.type) {
  case RESET_KNOBS:
    return initialState;
  case CHANGE_BOTTOM_DRAWER_DISPLAY:
    return update(state, { bottomDrawer: { $set: action.payload.props } });
  case CHANGE_DRAWER_ACTIVITY:
    return update(state, { drawerActivity: { $set: action.payload.props } });
  default:
    return state;
  }
};

export default Knobs;
