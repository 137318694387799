import { CancelToken } from 'axios';
import { PENDING, SUCCESS, ERROR } from '../../constants/statusTypes';

import { saveAs } from 'file-saver'; 
import isEmpty from '../../utils/isEmpty';

import {
  GET_BCP_LIST,
  POST_BCP_ALERT,
  GET_EMPLOYEE_LIST,
  END_BCP_ALERT,
  RESEND_BCP_ALERT,
  EXPORT_EVENT_CSV,
  RESET_ACTIVE_ALERT,
  SET_ACTIVE_ALERT
} from './index';

import config from '../../constants/serverConfig';
import api from '../../utils/api';

let source = CancelToken.source();

export const actionSetActiveAlert = (data, date) => ({
  type: SET_ACTIVE_ALERT,
  payload: {
    data,
    date
  }
})

export const actionResetActiveAlert = () => ({
  type: RESET_ACTIVE_ALERT
})

export const actionGetBcpList = () => async dispatch => {
  try {
    dispatch({type: GET_BCP_LIST + PENDING})

    const {data} = await api({
      url: `${config.SERVER_BASE_URL}/bcp`,
      method: 'GET',
      cancelToken: source.token
    })

    dispatch({
      type: GET_BCP_LIST + SUCCESS,
      payload: data.data.bcp
    })
  } catch(e) {
    dispatch({ type: GET_BCP_LIST + ERROR })
  }
}

export const actionGetEmployeeList = (alertId, filter) => async dispatch => {
  try {
    dispatch({type: GET_EMPLOYEE_LIST + PENDING });

    const { data } = await api({
      url: `${config.SERVER_BASE_URL}/bcp/employees?bcpId=${alertId}&filter=${filter}`,
      method: 'GET',
      cancelToken: source.token
    });

    dispatch({
      type: GET_EMPLOYEE_LIST + SUCCESS,
      payload: data.data
    })
  } catch(e) {
    dispatch({type: GET_EMPLOYEE_LIST + ERROR})
  }
}

export const actionPostBcpAlert = alertData => async dispatch => {
  try {
    dispatch({type: POST_BCP_ALERT + PENDING})

    const { data } = await api({
      url: `${config.SERVER_BASE_URL}/bcp`,
      method: 'POST',
      data: alertData
    })

    dispatch({type: POST_BCP_ALERT + SUCCESS})
  } catch {
    dispatch({ type: POST_BCP_ALERT + ERROR })
  }
}

export const actionEndBcpAlert = id => async dispatch => {
  try {
    dispatch({type: END_BCP_ALERT + PENDING})

    const { data } = await api({
      url: `${config.SERVER_BASE_URL}/bcp/end/${id}`,
      method: 'PUT'
    })

    dispatch({type: END_BCP_ALERT + SUCCESS})
  } catch(e) {
    dispatch({type: END_BCP_ALERT + ERROR})
  }
}

export const actionResendBcpAlert = id => async dispatch => {
  try {
    dispatch({type: RESEND_BCP_ALERT + PENDING})

    const { data } = await api({
      url: `${config.SERVER_BASE_URL}/bcp/notif/${id}`,
      method: 'POST'
    })

    dispatch({type: RESEND_BCP_ALERT + SUCCESS})
  } catch(e) {
    dispatch({type: RESEND_BCP_ALERT + ERROR})
  }
}

export const actionExportCSV = id => async dispatch => {
  try {
    dispatch({type: EXPORT_EVENT_CSV + PENDING})

    const { data } = await api({
      url: `${config.SERVER_BASE_URL}/bcp/response/export/${id}`,
      method: 'GET',
      cancelToken: source.token
    })

    const csvFile = data.data;
    
    
    if (!isEmpty(csvFile)) {
      const parts = csvFile.split('/');
      const filename = parts[parts.length - 1];
      saveAs(csvFile, filename);
    }

    dispatch({type: EXPORT_EVENT_CSV + SUCCESS})
  } catch(e) {
    dispatch({type: EXPORT_EVENT_CSV + ERROR})
  }
}