import React, {Fragment} from 'react';
import styled from '@emotion/styled';
import Link from 'redux-first-router-link'
import Card from '../components/Card';


import lockLogo from '../../assets/icons/lockLogo.svg';

import SvgIcon from '../components/SvgIcon';
import FlatButton from '../components/FlatButton';
import Text from '../components/Text';

const CPSuccessCard = styled(Card)({
  height: 595,
  width: 484,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'none',
  borderRadius: 26,
})

const HeaderTitle = styled(Text)(({ theme }) => ({
  fontSize: 24,
  lineHeight: '29px',
  color: theme.appEntryTitleTextColor,
  wordWrap: 'break-word',
  textAlign: 'center',
  fontWeight: 'bold'
}));

const CheckContainer = styled('div')({
  padding: '60px 172px 41px 172px',
  display: 'flex'
});

const BackToSignIn = styled(FlatButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '14',
  lineHeight: '17px',
  width: '100%',
  paddingTop: 38,
  color: '##19B9A9'
}));

const MessageContainer = styled('div')({
  textAlign: 'center',
  margin: '0 0 16px 0',
  paddingTop: 12
})

const SuccessText = styled(Text)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  color: '#222222'
}));

const ChangePassSuccess = () => {

  return (
    <Fragment>
      <CPSuccessCard>
    
        <CheckContainer>
          <SvgIcon size={140} symbol={lockLogo} />
        </CheckContainer>
        <HeaderTitle >Reset my password</HeaderTitle>


        <MessageContainer>
          <SuccessText>You have successfully reset your password!</SuccessText>
        </MessageContainer>
       
      
      </CPSuccessCard>
      <BackToSignIn bold hasUnderline><Link to='/login'>Back to sign-in</Link></BackToSignIn>
    </Fragment>
  )
}

export default ChangePassSuccess;